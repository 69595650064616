import { IAppState } from 'models/IAppState'
import { IAuthState } from 'models/IAuthState'

const selectorAppState = (state: IAppState): IAuthState => state.app

const selectorLoggedUserData = (state: IAppState) => selectorAppState(state).data?.user

/**
 * Return true or false when login in process
 * @param state IAppState
 */
export const selectorLoginInProcess = (state: IAppState) => selectorAppState(state).isLoading

export const selectorLoggedInID = (state: IAppState) => selectorLoggedUserData(state)?.uid

export const selectorLoggedInEmail = (state: IAppState) => selectorLoggedUserData(state)?.email

export const selectorLoggedInDisplayName = (state: IAppState) => selectorLoggedUserData(state)?.displayName

export const selectorUserLoggedIn = (state: IAppState) => selectorAppState(state).loggedIn

export const selectorUserRole = (state: IAppState) => selectorAppState(state).role
