import React from 'react'

const ArchiveSvgIcon = React.memo(({ strokeWidth = 1 }: { strokeWidth?: number }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth={strokeWidth}
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <polyline points='21 8 21 21 3 21 3 8' />
    <rect x='1' y='3' width='22' height='5' />
    <line x1='10' y1='12' x2='14' y2='12' />
  </svg>
))

const ViewSvgIcon = React.memo(() => (
  <>
    <path d='M0 0h24v24H0z' fill='none' />
    <path d='M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z' />
  </>
))

const LinkedInIcon = React.memo(() => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
    <path
      d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 16h-2v-6h2v6zm-1-6.891c-.607 0-1.1-.496-1.1-1.109 0-.612.492-1.109 1.1-1.109s1.1.497 1.1 1.109c0 .613-.493 1.109-1.1 1.109zm8 6.891h-1.998v-2.861c0-1.881-2.002-1.722-2.002 0v2.861h-2v-6h2v1.093c.872-1.616 4-1.736 4 1.548v3.359z'
      fill='#1b9bdc'
    />
  </svg>
))

const GoogleIcon = React.memo(() => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' height='48px' width='48px'>
    <defs>
      <filter x='-.8%' y='-3.6%' width='101.6%' height='107.2%' filterUnits='objectBoundingBox' id='a'>
        <feOffset dy='4' in='SourceAlpha' result='shadowOffsetOuter1' />
        <feGaussianBlur stdDeviation='3' in='shadowOffsetOuter1' result='shadowBlurOuter1' />
        <feColorMatrix
          values='0 0 0 0 0.879359149 0 0 0 0 0.879359149 0 0 0 0 0.879359149 0 0 0 0.5 0'
          in='shadowBlurOuter1'
          result='shadowMatrixOuter1'
        />
        <feMerge>
          <feMergeNode in='shadowMatrixOuter1' />
          <feMergeNode in='SourceGraphic' />
        </feMerge>
      </filter>
    </defs>
    <g filter='url(#a)' transform='translate(-2144 -260)' fill='none' fillRule='evenodd'>
      <path
        d='M2168 278.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48c-1.56-1.45-3.59-2.34-6.02-2.34-3.52 0-6.56 2.02-8.04 4.96l2.91 2.26c.73-2.17 2.75-3.74 5.13-3.74z'
        fill='#EA4335'
      />
      <path
        d='M2176.64 284.2c0-.74-.06-1.28-.19-1.84H2168v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z'
        fill='#4285F4'
      />
      <path
        d='M2162.88 285.78a5.54 5.54 0 01-.3-1.78c0-.62.11-1.22.29-1.78l-2.91-2.26a9.008 9.008 0 00-.96 4.04c0 1.45.35 2.82.96 4.04l2.92-2.26z'
        fill='#FBBC05'
      />
      <path
        d='M2168 293c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74l-2.91 2.26c1.48 2.94 4.51 4.96 8.03 4.96z'
        fill='#34A853'
      />
      <path d='M2159 275h18v18h-18z' />
    </g>
  </svg>
))

export { ArchiveSvgIcon, ViewSvgIcon, LinkedInIcon, GoogleIcon }
