import {
  ACTION_APP_LOGIN_INIT,
  ACTION_APP_LOGOUT,
  ACTION_APP_LOGIN_FAIL,
  ACTION_APP_LOGIN_SUCCESS,
} from 'store/actions/app.action'
import { IAuthState } from 'models/IAuthState'

const localStorageState = JSON.parse(localStorage.getItem('app') || '{}')
const initialState: IAuthState = {
  loggedIn: false,
  role: undefined,
  homeUrl: '/',
  data: undefined,
  isLoading: false,
}

const appReducer = (
  state: IAuthState = { ...initialState, ...localStorageState },
  action: { type: string; payload: any },
): IAuthState => {
  switch (action.type) {
    case ACTION_APP_LOGIN_INIT: {
      const { role } = action.payload
      const homeUrl = role === 'admin' ? '/admin' : '/'
      return {
        ...state,
        role,
        homeUrl,
        isLoading: true,
      }
    }
    case ACTION_APP_LOGIN_SUCCESS: {
      const data = { ...action.payload }
      const updatedState = {
        ...state,
        data,
        loggedIn: true,
        isLoading: false,
      }
      localStorage.setItem('app', JSON.stringify(updatedState))
      return { ...updatedState }
    }
    case ACTION_APP_LOGIN_FAIL:
    case ACTION_APP_LOGOUT: {
      // localStorage.removeItem('app')
      localStorage.clear()
      return { ...initialState, isLoading: false }
    }

    default: {
      return { ...state }
    }
  }
}

export { appReducer }
