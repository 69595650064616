import React, { useState, useEffect } from 'react'
import Select, { components } from 'react-select'
import SortIcon from '@material-ui/icons/Sort'
import { useWindowResize } from 'hooks/useWindowResize'
import { useDispatch } from 'react-redux'
import { sortBy } from 'store/slices/menuSlice'

const influencers = [
  { value: 'time-latest', label: 'Time (Latest to Earliest)' },
  { value: 'time-earliest', label: 'Time (Earliest to Latest)' },
  { value: 'name-A-Z', label: 'Name (A to Z)' },
  { value: 'name-Z-A', label: 'Name (Z to A)' },
]

const influencersMobile = [
  { value: 'time-latest', label: <i style={{ fontSize: 20 }} className='fas fa-sort-amount-down'></i> },
  { value: 'time-earliest', label: <i style={{ fontSize: 20 }} className='fas fa-sort-amount-up'></i> },
  { value: 'name-A-Z', label: <i style={{ fontSize: 20 }} className='fas fa-sort-alpha-down'></i> },
  { value: 'name-Z-A', label: <i style={{ fontSize: 20 }} className='fas fa-sort-alpha-up'></i> },
]

export default function SelectOrderBy() {
  const dispatch = useDispatch()

  const windowWidth = useWindowResize()
  const [influencer, setInfluencer] = useState([])
  const [valueSelect, setValueSelect] = useState('')

  const [widthContainer, setWidthContainer] = useState(255)

  const handleInfluencerName = ({ value }) => {
    setValueSelect(value)

    if (windowWidth >= 680) {
      if (value === 'time-earliest' || value === 'time-latest') {
        setWidthContainer(281)
      } else {
        setWidthContainer(240)
      }
    }

    dispatch(sortBy(value))
  }

  useEffect(() => {
    if (windowWidth >= 680) {
      if (valueSelect === 'time-earliest' || valueSelect === 'time-latest') {
        setWidthContainer(281)
      } else {
        setWidthContainer(255)
      }
      setInfluencer(influencers)
    } else {
      setWidthContainer(45)
      setInfluencer(influencersMobile)
    }
  }, [windowWidth])

  const ValueContainer = ({ children, ...props }) => {
    return (
      windowWidth >= 680 &&
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          {!!children && (
            <>
              <span style={{ color: 'grey' }}>Sort By </span>
              {!props.hasValue && (
                <>
                  <span style={{ marginLeft: 10 }}>Submitted Time</span>
                  <SortIcon style={{ fontSize: 20, marginLeft: 2 }} />
                </>
              )}
            </>
          )}
          <span style={{ marginLeft: 4 }}>
            {valueSelect && influencers.find((influencer) => influencer.value === valueSelect)['label']}
          </span>

          {React.Children.map(children, (child) => {
            return child.props.id === 'clickableInput' ? child : null
          })}
        </components.ValueContainer>
      )
    )
  }

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {windowWidth >= 680 ? (
            <i className='fa fa-angle-down' aria-hidden='true' />
          ) : (
            <i className='fas fa-list' style={{ padding: '0px 5px', color: 'rgba(0, 0, 0, 0.87)' }} />
          )}
        </components.DropdownIndicator>
      )
    )
  }

  const styles = {
    valueContainer: (base) => ({ ...base, paddingLeft: 9 }),
    control: (base) => ({
      ...base,
      borderRadius: 10,
      border: windowWidth >= 680 ? '1px solid #ccc' : '0px solid #ccc',
      cursor: 'pointer',
    }),
  }

  return (
    <div
      style={{
        width: widthContainer,
        zIndex: 9000,
      }}
    >
      <Select
        inputId='clickableInput'
        blurInputOnSelect={true}
        placeholder=''
        options={influencer}
        isMulti={false}
        onChange={handleInfluencerName}
        isSearchable={false}
        components={{
          DropdownIndicator,
          ValueContainer,
          IndicatorSeparator: (props) => (
            <div style={{ border: windowWidth >= 680 ? '1px solid #ddd' : '0px solid #ddd', height: '100%' }}></div>
          ),
        }}
        classNamePrefix='vyrill'
        styles={styles}
      />
    </div>
  )
}
