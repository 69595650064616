import { ImpressionInterface } from 'models/IAppState'

export const sortTypes: any = {
  'name-A-Z': (a: ImpressionInterface, b: ImpressionInterface) => {
    var titleA = a.title.toUpperCase() // ignore upper and lowercase
    var titleB = b.title.toUpperCase() // ignore upper and lowercase
    if (titleA < titleB) {
      return -1
    }
    if (titleA > titleB) {
      return 1
    }
    return 0
  },
  'name-Z-A': (a: ImpressionInterface, b: ImpressionInterface) => {
    var titleA = a.title.toUpperCase() // ignore upper and lowercase
    var titleB = b.title.toUpperCase() // ignore upper and lowercase

    if (titleA < titleB) {
      return 1
    }
    if (titleA > titleB) {
      return -1
    }
    return 0
  },
  'time-latest': (a: ImpressionInterface, b: ImpressionInterface) => {
    var dateA = a.createdDate._seconds
    var dateB = b.createdDate._seconds

    if (dateA < dateB) {
      return 1
    }
    if (dateA > dateB) {
      return -1
    }
    return 0
  },
  'time-earliest': (a: ImpressionInterface, b: ImpressionInterface) => {
    var dateA = a.createdDate._seconds
    var dateB = b.createdDate._seconds

    if (dateA < dateB) {
      return -1
    }
    if (dateA > dateB) {
      return 1
    }
    return 0
  },
  ['']: {
    fn: () => {
      return 0
    },
  },
}
