import { INotification } from 'models/IAppState'
import { APP_SHOW_NOTIFICATION, APP_HIDE_NOTIFICATION } from 'store/actions/notification.action'

const initialState: INotification = {
  duration: 1500,
  isOpen: false,
  message: '',
  severity: 'success',
}

const notificationReducer = (
  state: INotification = initialState,
  action: { type: string; payload: INotification },
): INotification => {
  switch (action.type) {
    case APP_SHOW_NOTIFICATION: {
      const duration = action.payload.duration || state.duration
      return {
        ...state,
        ...action.payload,
        isOpen: true,
        duration,
      }
    }

    case APP_HIDE_NOTIFICATION: {
      return {
        ...state,
        message: '',
        isOpen: false,
        duration: 1500,
      }
    }

    default:
      return { ...state }
  }
}

export { notificationReducer }
