import styled from 'styled-components'
import Box from '@material-ui/core/Box'

import Button from '@material-ui/core/Button'
import CheckCircleOutlineIcon from './CheckCircleOutlineIcon'

export const StyledCheckCirle = styled(CheckCircleOutlineIcon)`
  width: 120px;
  height: 120px;
  color: linear-gradient(#a6ecoo, #6dd400);
`

export const StyledBoxWrapper = styled(Box)`
   {
    @media (min-width: 768px) {
      margin: 0 auto;
      padding: 2em;
    }
    width: 100%;  
  }
`
export const RecBtnWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
`

export const pth = {
  paddingTop: '100px',
}

export const fontPoppins = {
  fontFamily: 'poppins',
  fontSize: '40px',
  letterSpacing: '1px',
  fontWeight: '800',
  color: '#2e333b',
}
export const fontLato = {
  fontFamily: 'lato',
  fontSize: '19px',
  letterspacing: '0.3px',
  lineheight: '28px',
  textAlign: 'center',
}
export const StyledContainer = styled.div`
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    padding-top: 50px;
  }
  display: flex;
  justify-content: center;
  padding: 20px;
  text-align: center;
`

export const StyledButton = styled(Button)`
  display: block;
  margin-bottom: 10px;
  font-family: "lato";
  font-size: 15px;
  border: 1px solid rgba(63, 81, 181, 0.5);
  height: 40px;
  width: 200px;
  min-width: 250px;
  margin-bottom: 20px;
  & .MuiButton-label {
    text-transform: capitalize;
  }

  &.MuiButton-containedPrimary {
    color: #fff;
    background-color: #2c4aff;
  }
`
export const BoxWrapper = styled(Box)`
   {
    // box-shadow: 0 4px 8px #e2e2e2;
    background: white;
    border-radius: 5px;
    width: 100%;

    @media (min-width: 768px) {
      margin-top: 50px;
    }
  }
`

export const StyledProgressWrapper = styled.div`
  position: relative;

  > div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
`

export const StyledVideoPlayer = styled.div`
  video {
    transform: translate(-50%, -50%) scale(-1, 1);
  }
`

// upuper case
export const StyledButtonContainer = styled.div`
  button {
    margin-bottom: 10px;
    border: 1px solid rgba(63, 81, 181, 0.5);
    width: 300px;
    height: 40px;
    min-width: 250px;
    font-size: 14px;
    text-transform: "capitalize";
  }
`

// to update
export const StyledTipsVideo = styled.div`
  text-align: center;
  button {
    text-align: center;
    font-size: 20px;
  }
`
