import React, { useState, useEffect, useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import { authActions } from 'actions'
import { bindActionCreators } from 'redux'
import { StyledWrapper, StyledOR } from 'shared/AuthStyle/style.js'

import Link from '@material-ui/core/Link'

import { useLocation } from 'react-router-dom'
import LoadingIndicator from 'shared/LoadingIndicator'
import { selectorLoginInProcess } from 'store/selectors'
import { LinkedInIcon, GoogleIcon } from '../../../svg/Icons'

// New imports
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'

import Checkbox from '@material-ui/core/Checkbox'
import { Email, LockOutlined, Visibility, VisibilityOff } from '@material-ui/icons'
import { Button } from '@material-ui/core'

const useStyles = makeStyles(() =>
  createStyles({
    main: {
      fontSize: '13px',
    },
    title: {
      '& h6': {
        fontSize: '1.3em',
        textAlign: 'center',
        margin: 1,
        color: '#5DCCBF',
      },
      '& h4': {
        fontSize: '3em',
        textAlign: 'center',
        font: 'normal normal medium 60px/72px Fira Sans',
        letterSpacing: '0px',
        color: '#000000',
        textTransform: 'capitalize',
        opacity: 1,
        margin: 0,
      },
      '& p': {
        margin: 2,
        fontSize: '1em',
        textAlign: 'center',
        letterSpacing: 0,
        textTransform: 'capitalize',
        opacity: 1,
      },
    },
    fields: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    iconInputField: {
      display: 'flex',
      alignItems: 'center',
      minWidth: 230,
      margin: 10,
      backgroundColor: '#F6F6F6',
      width: '100%',
      height: 50,
      background: '#F6F6F6 0% 0% no-repeat padding-box',
      borderRadius: 8,
      opacity: 1,
      boxShadow: 'none',
    },
    textLink: {
      color: '#5DCCBF !important',
      textDecoration: 'none !important',
      fontSize: '1em',
      cursor: 'pointer',
    },
    input: {
      margin: 0,
      fontSize: '1em',
      flex: 1,
    },
    loginButtonSection: {
      display: 'flex',
      '& .loginButton': {
        display: 'flex',
        fontSize: '1em',
        flex: 1,
        height: 50,
        backgroundColor: '#5DCCBF',
        color: '#ffffff',
        borderRadius: '8px',
        textTransform: 'uppercase',
        fontWeight: 500,
      },
    },
    socialLoginButtons: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& .button': {
        display: 'flex',
        borderRadius: 8,
        border: '1px solid #999',
        fontSize: '0.8em',
        height: 50,
        margin: '10px 5px',
        width: 180,
      },
      '@media(max-width: 480px)': {
        '& .button': { width: '100%' },
      },
    },
    createAccountLInk: {
      fontSize: '1em',
      textAlign: 'center',
      margin: '10px auto',
    },
    forgotPasswordLinkSection: {
      display: 'flex',
      alignItems: 'center',
      '& .keep-logged-section': {
        flex: 1,
        fontSize: '1em !important',
        '& .keep-logged-checkbox': {
          color: '#5DCCBF',
        },
      },

      '& a': {
        color: '#5DCCBF !important',
        textDecoration: 'none !important',
        fontSize: '1em',
        cursor: 'pointer',
      },

      margin: '10px auto',
    },
  }),
)

const SigninEmail = function ({ history, actions }) {
  const classes = useStyles()

  const [email, setEmail] = useState('')

  const [password, setPassword] = useState('')

  const isLoginInProcess = useSelector((state) => selectorLoginInProcess(state))

  // const history = useHistory();
  const location = useLocation()

  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (actions.auth) {
      actions.auth.onAuthStateChanged(async () => {
        if (actions.loggedIn === true) {
          history.push('/')
        }
      })
    }
  }, [actions, history])

  const signInWithEmailAndPasswordHandler = (event, email, password) => {
    event.preventDefault()
    actions.signInWithEmailAndPasswordHandler(email, password, () => {})
  }
  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget
    if (name === 'userEmail') {
      setEmail(value)
    } else if (name === 'userPassword') {
      setPassword(value)
    }
  }

  const goolgeSignIn = () => {
    actions.signInWithGoogle()
  }

  const disableButtons = useMemo(() => email.length === 0 || password.length === 0, [email, password])

  const [checked, setChecked] = React.useState(true)

  const handleChange = (event) => {
    setChecked(event.target.checked)
  }

  return (
    <section className={classes.main}>
      <section className={classes.title}>
        <h6>Primo Applicant</h6>
        <h4>Sign In</h4>
        <p>
          Not an Applicant?{' '}
          <span onClick={() => history.push('/admin')} className={classes.textLink}>
            Click here
          </span>{' '}
          to access the Recruiter platform
        </p>
      </section>
      <section className={classes.fields}>
        <Paper className={classes.iconInputField}>
          <IconButton className={classes.iconButton} aria-label='user icon' disabled>
            <Email fontSize='small' />
          </IconButton>
          <InputBase
            className={classes.input}
            type='email'
            name='userEmail'
            value={email}
            placeholder='Email'
            id='userEmail'
            onChange={(event) => onChangeHandler(event)}
            inputProps={{ 'aria-label': 'username' }}
          />
        </Paper>

        <Paper className={classes.iconInputField}>
          <IconButton className={classes.iconButton} aria-label='password icon' disabled>
            <LockOutlined fontSize='small' />
          </IconButton>
          <InputBase
            className={classes.input}
            inputProps={{ 'aria-label': 'search google maps' }}
            type={showPassword ? 'text' : 'password'}
            name='userPassword'
            value={password}
            placeholder='Password'
            id='userPassword'
            onChange={(event) => onChangeHandler(event)}
          />
          <Divider className={classes.divider} orientation='vertical' />
          <IconButton
            className={classes.iconButton}
            aria-label='directions'
            onClick={() => setShowPassword((state) => !state)}
          >
            {showPassword ? <Visibility fontSize='small' /> : <VisibilityOff fontSize='small' />}
          </IconButton>
        </Paper>
      </section>
      <section className={classes.forgotPasswordLinkSection}>
        <section className='keep-logged-section'>
          <Checkbox
            className='keep-logged-checkbox'
            checked={checked}
            onChange={handleChange}
            size='small'
            name='keepMeLoggedIn'
          />
          Keep me logged in
        </section>
        <section>
          <Link
            className='forgot-password'
            onClick={() => {
              history.push('/forgot-password', { ...location.state })
            }}
          >
            Forgot Password?
          </Link>
        </section>
      </section>
      <section className={classes.loginButtonSection}>
        <LoadingIndicator
          loading={isLoginInProcess}
          children={
            <Button
              className='loginButton'
              disabled={disableButtons}
              onClick={(event) => {
                signInWithEmailAndPasswordHandler(event, email, password)
              }}
            >
              Log in
            </Button>
          }
        />
      </section>

      <StyledWrapper>
        <StyledOR>or</StyledOR>
      </StyledWrapper>
      <section className={classes.socialLoginButtons}>
        <Button onClick={goolgeSignIn} className='button' startIcon={<GoogleIcon />} disabled={isLoginInProcess}>
          Continue with Google
        </Button>
        <Button onClick={goolgeSignIn} startIcon={<LinkedInIcon />} className='button' disabled={isLoginInProcess}>
          Continue with LinkedIn
        </Button>
      </section>

      <section className={classes.createAccountLInk}>
        Don't Have An Account&nbsp;?&nbsp;
        <Link
          className={classes.textLink}
          onClick={() => {
            history.push('/auth/signup', { ...location.state })
          }}
        >
          Create An Account
        </Link>
      </section>
    </section>
  )
}
const mapStateToProps = ({ auth }) => ({
  auth,
  error: auth.error,
})
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(authActions, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(SigninEmail)
