import { combineReducers } from 'redux'
import adminReducer from './admin.reducer'
import { sessionReducer } from './session.reducer'
import { questionsReducer } from './questions-reducer'
import { getInterviewReducer } from './get-interview.reducer'
import { authentication } from './auth.reducer'
import { appReducer } from './app.reducer'
import { notificationReducer } from './notification.reducer'
import userReducer from './user.reducer'

import menuReducer from '../slices/menuSlice'

const rootReducer = combineReducers({
  interviews: getInterviewReducer,
  session: sessionReducer,
  questions: questionsReducer,
  auth: authentication,
  admin: adminReducer,
  app: appReducer,
  notification: notificationReducer,
  user: userReducer,
  menu: menuReducer,
})

export default rootReducer
