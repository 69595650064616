import React from 'react'
import moment from 'moment'
import TermsPdf from '../../../resource/Primo Impressions -Terms and Condition.pdf'
import PolicyPdf from '../../../resource/Primo Impressions - Privacy Policy.pdf'

import { StyledFooter, StyledFooterText, StyledFooterContainer, StyledFooterLink } from './style'

const copyrightYear = moment().format('YYYY')

const Footer = function () {
  return (
    <StyledFooter>
      <StyledFooterContainer>
        <StyledFooterText>@Copyright {copyrightYear} Primo Impression. all rights reserved.</StyledFooterText>
        <StyledFooterText>
          <StyledFooterLink target='_blank' href={TermsPdf}>
            Terms and Condition
          </StyledFooterLink>
          &nbsp;&amp;&nbsp;
          <StyledFooterLink target='_blank' href={PolicyPdf}>
            Privacy and Policy
          </StyledFooterLink>
        </StyledFooterText>
        <StyledFooterText>A Plum Tree Company</StyledFooterText>
      </StyledFooterContainer>
    </StyledFooter>
  )
}
export default Footer
