import firebase from 'firebase/app'
import {
  INTERVIEW_LOAD_ERROR,
  INTERVIEW_LOAD_SUCCESS,
  USER_SUCCESS,
  GET_INTERVIEW,
  INITILIZE,
  GET_SUBMIT_INTERVIEW,
} from '../constants/types'

import 'firebase/database'
/**
 * Endpoint to create interview entry on DB.
 */
export const createInterviewActive = (questions, jobId, candidate, active) => {
  const payload = {
    passive: false,
    question: questions,
    currentStep: 0,
    maxStep: questions.length,
    user: {
      name: candidate.name,
      email: candidate.email,
      jobPosition: candidate.jobPosition,
      companyName: candidate.companyName,
    },
    jobId,
    job: {
      companyName: candidate.companyName,
      position: candidate.jobPosition,
    },
    candidates: [],
    // hRecordID: interviewCode,
    active: true,
    desc: 'testDesc',
    companyId: '99999999',
    createdDate: Date(),
    expireDate: '01/01/2020',
    createdBy: 'Bhambhani',
  }
  return (dispatch) => {
    // Saving data into firebase with userID setting it to hRecordID createInterviewActhe main nodename is interview in database
    // its saving all the info state into database
    firebase
      .database()
      .ref(`interview/${payload.jobId}`)
      .update(payload)
      .then((s) => { })
      .catch((e) => { })
  }
}

/**
 * Endpoint to create interview entry on DB.
 */
export const createInterviewPassive = (questions, jobId, job, recruiter) => {
  const payload = {
    passive: true,
    question: questions,
    currentStep: 0,
    maxStep: questions.length,
    user: {
      name: '',
      email: recruiter.email,
      jobPosition: job.jobPosition,
    },
    jobId,
    job: {
      companyName: job.companyName,
      position: job.jobPosition,
    },
    candidates: [],
    // hRecordID: interviewCode,
    active: false,
    companyId: '99999999',
    desc: 'testDesc',
    createdDate: Date(),
    expireDate: '01/01/2020',
    createdBy: 'Bhambhani',
  }
  return (dispatch) => {
    // Saving data into firebase with userID setting it to hRecordID the main nodename is interview in database
    // its saving all the info state into database
    firebase
      .database()
      .ref(`interview/${payload.jobId}`)
      .update(payload)
      .then((s) => { })
      .catch((e) => { })
  }
}

export const savePassiveUserDetails = (jobId, payload) => (dispatch) => {
  // Saving data into firebase with userID setting it to hRecordID the main nodename is interview in database
  // its saving all the info state into database
  firebase
    .database()
    .ref(`interview/${jobId}/job/candidates`)
    .update(payload)
    .then((s) => { })
    .catch((e) => { })
}

//  Saving data into firebase function
export const saveInterview = (email, Info, interView, userType, candiDateId) => () => {
  // Saving data into firebase with userID setting it to hRecordID the main nodename is interview in database
  // its saving all the info state into database
  const array = interView
  firebase
    .database()
    .ref(`interview/${Info.jobId}/job/candidates/${candiDateId}/interView`)
    .set(array)
    .then((s) => { })
    .catch((e) => { })
}
export const saveImpressions = (user, Info, candiDateId) => () => {
  // Saving data into firebase with userID setting it to hRecordID the main nodename is interview in database
  // its saving all the info state into database

  firebase
    .database()
    .ref(`impressions/${user.uid}/${candiDateId}`)
    .set({
      candidate: candiDateId,
      email: user.email,
      displayName: user.displayName,
      jobPosition: Info.job.position,
      companyName: Info.job.companyName,
    })
    .then((s) => { })
    .catch((e) => { })
}

//  Saving data into firebase function
export const getInterview = (hRecordID) => (dispatch) => {
  // Saving data into firebase with userID setting it to hRecordID the main nodename is interview in database
  // its saving all the info state into database
  firebase
    .database()
    .ref(`interview/${hRecordID}`)
    // .ref(`interview/b69d7d80`)
    .on('value', (val) => {
      dispatch({ type: GET_INTERVIEW, response: val.val() })
    })

}

export const saveUser = (user) => (dispatch) => {
  dispatch({ type: USER_SUCCESS, response: user })
}

export const searchInterview = (interviewCode) => (dispatch) => {
  firebase
    .database()
    // .ref(`interview/b69d7d80`)
    .ref(`interview/${interviewCode}`)
    .on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        dispatch({
          type: INTERVIEW_LOAD_SUCCESS,
          response: snapshot.val(),
        })
      } else {
        dispatch({
          type: INTERVIEW_LOAD_ERROR,
          error: { code: 404 },
        })
      }
    })
}

const month_names = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const filterList = (impressions) => {
  const jobs = []
  const impreesion_list = Object.values(impressions)
  impreesion_list.forEach((impression) => {
    const year = getYear(impression.candidate)
    const day = getDay(impression.candidate)
    const month = month_names[getMonth(impression.candidate)]

    jobs.push({
      jobPosition: impression.jobPosition,
      companyName: impression.companyName,
      year,
      month,
      day,
    })
  })

  return jobs
}
const getYear = (id) => {
  const dt = parseInt(id)
  return new Date(dt).getFullYear()
}
const getMonth = (id) => {
  const dt = parseInt(id)
  return new Date(dt).getMonth()
}
const getDay = (id) => {
  const dt = parseInt(id)
  return new Date(dt).getDate()
}

export const getImpressions = (user) => (dispatch) => {
  firebase
    .database()
    .ref(`impressions/${user.uid}`)
    .orderByChild(user.uid)
    // this will not work because firebase database not support and operator
    // .orderByChild('job/candidates')
    // .startAt('1232')
    .on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        const impressionlist = snapshot.val()
        const submitted = filterList(impressionlist)
        dispatch({
          type: GET_SUBMIT_INTERVIEW,
          response: submitted,
        })
      } else {
        dispatch({
          type: GET_SUBMIT_INTERVIEW,
          response: [],
        })
      }
    })
}

export const initialize = () => (dispatch) => {
  dispatch({
    type: INITILIZE,
    response: false,
  })
}
