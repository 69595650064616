import styled, { css } from 'styled-components'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'

export const StyledBoxWrapper = styled.section``

export const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
  p {
    margin: 20px 0 20px 0;
    color: #2e333b;
    font-size: 14px;
    letter-spacing: 0.19px;
    line-height: 15px;
    text-align: center;
  }
`
export const StyledService = styled.div`
  opacity: 0.8;
  margin: 20px 0 20px 0;
  color: rgba(46, 51, 59, 0.57);
  font-size: 12px;
  letter-spacing: 0.19px;
  line-height: 15px;
  text-align: center;

  a {
    color: #2c4aff;
  }
`
export const textAlignCenter = {
  textAlign: 'center',
}

export const styledImg = {
  height: '38px',
  paddingRight: '30px',
}

export const itemAlignCenter = {
  justifyContent: 'space-around',
}

export const LoginBoxContainer = styled(Box)`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  max-width: 500px;
  min-width: 90%;
`

export const styledImgWrapper = {
  display: 'flex',
  justifyContent: 'center',
  padding: '20px',
}
export const StyledDiv = styled.div`
  @media (min-width: 768px) {
    padding: 50px 0 20px 0;
  }
`

export const AdminCompanyLogosContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height:100px;
  img {
    height: 22px;
    margin: 5px 10px;
  }
  @media (max-width: 768px) {
    img {
      height: 16px;
      padding: 0 10px;
      margin: 15px auto;
    }
  }
`

export const StyledWrapper = styled.div`
  > button {
    margin-bottom: 16px;
  }

  ${(props) =>
    props.alignCenter &&
    css`
      display: flex;
      justify-content: center;
    `}
`

export const StyledSign = styled.div`
  margin: 10px;
  align-items: baseline;
  font-size: 14px;

  a {
    cursor: pointer;
    text-decoration: underline;
    color: #2c4aff;
  }
  &:disabled {
    background-color: #dedede;
  }
`
export const StyledLinkWrapper = styled(Link)`
  color: #2c4aff;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`
export const StyledLineWrapper = {
  display: 'flex',
  justifyContent: 'center',
}

export const StyledVLine = styled.div`
  border-left: 3px solid rgb(238, 238, 238);
  margin: 50% 0;
`

export const StyledMainTitle = styled.h1`
  padding-bottom: 20px;
  font-size: 32px;
  line-height: 45px;
  font-weight: 800;
  letter-spacing: 0.5px;
  text-transform: none;

  @media (max-width: 768px) {
    font-size: 22px;
    line-height: 30px;
  }
`
export const StyledRTitle = styled.p`
  padding-bottom: 15px;
  color: #2e333b;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0.38px;
  line-height: 35px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 17px;
    line-height: 26px;
  }
`
export const StyledRSubTitle = styled.p`
  padding-bottom: 30px;
  color: rgba(46, 51, 59, 0.84);
  font-size: 16px;
  letter-spacing: 0.25px;
  line-height: 20px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 20px;
  }
`
export const StyledFromTitle = styled.p`
  font-size: 15px;
  letter-spacing: 0.22px;
  line-height: 20px;
  text-align: center;
`
export const StyledFooterTitle = styled.p`
  padding: 50px 0 20px 0;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 43px;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 30px;
  }
`
export const StyledSubTitle = styled.p`
  padding-bottom: 20px;
  font-size: 22px;
  letter-spacing: 0.34px;
  line-height: 32px;

  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 20px;
  }
`
export const StyledInput = {
  // padding: "15px",
  // height: "30px",
  // width: "100%",
  // border: "1px solid #E6E6E6",
  // borderRadius: "3px",
  // backgroundColor: "#F4F4F4",
  // color: "rgba(0,0,0,0.5)",
  // fontSize: "14px",
  // letterSpacing: "0.22px",
  // lineHeight: "17px",
  // margin: "5px auto",

  maxWidth: '250px',
}

export const StyledButton = styled(Button)`
  min-width: 150px;
  &.MuiButtonBase-root {
    margin: 5px auto;
    font-weight: bold;
    border-radius: 40px;
    width: 100%;
    height: 40px;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 20px;
    background-color: #5dccbf;
    text-transform: capitalize;
    color: white;
  }
  &.MuiButtonBase-root:hover {
    box-shadow: 0 0 8px 0 #777;
  }
  &:disabled {
    background-color: #dedede;
  }
`

export const StyledSignButton = styled(StyledButton)`
  &.MuiButtonBase-root {
    color: black;
    background-color: #ffffff;
    text-transform: none;
    min-width: 190px;
    max-width: 200px;
    font-size: 0.5em;
  }
  &:disabled {
    background-color: #dedede;
  }
`

export const mainTitle = {
  fontSize: '32px',
  lineHeight: '45px',
  color: '#2e333b',
  fontWeight: '800',
  letterSpacing: '0.5px',
}

export const questSubTitle = {
  fontSize: '23px',
  color: '#2e33b',
  paddingTop: '30px',
  letterSpacing: '0.5px',
  lineHeight: '35px',
}

export const boxWrapperStyle = {
  margin: '0 auto',
  maxWidth: '1048px',
  minWidth: '768px',
}

export const RecBoxWrapper = styled(Box)`
  box-shadow: none;
  padding: 30px;
  margin: auto;
  margin-top: 50px;
  background-color: #fff7ed;
  border-radius: 8px;
`
export const RecBoxTitle = {
  fontSize: '26px',
  letterSpacing: '0.5px',
  fontWeight: '600',
  color: '#2e333b',
}

export const RecBoxSub = {
  paddingTop: '10px',
  lineHeight: '30px',
  color: 'black',
  fontSize: '21px',
  letterSpacing: '0.3px',
}
export const btnColor = {
  background: '#2c4aff',
  width: '50%',
  height: '45px',
  fontSize: '16px',
  textTransform: 'capitalize',
  fontWeight: 'bold',
  lineHeight: '20px',
  letterSpacing: '1px',
}
export const btnBorder = {
  border: '4px solid',
  width: '50%',
  height: '45px',
  fontSize: '16px',
  textTransform: 'capitalize',
  fontWeight: 'bold',
  lineHeight: '20px',
  letterSpacing: '1px',
}
export const VideoTitle = styled.p`
  color: #2e333b;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.5px;
`
export const StyledFButton = styled(Button)`
  background: #2c4aff;
`

export const QuestionBoxWrapper = styled(Box)`
  box-shadow: 0 4px 8px #e2e2e2;
  background: white;
  border-radius: 5px;
  p {
  }
`

export const ProgressBoxWrapper = styled(Box)`
  margin: 0 auto;
  border-radius: 8px;
  padding: 2em 0 2em 0;
  padding-bottom: 1em;
  opacity: 1;
`
export const boxRecordStyle = {
  boxShadow: '0 4px 8px #e2e2e2',
  background: 'white',
  borderRadius: '5px',
}
export const boxStartRecordContainer = {
  maxWidth: '940px',
  margin: '0 auto',
  borderRadius: '8px',
}
export const boxStartRecord = {
  boxShadow: 'none',
  padding: '30px',
  margin: 'auto',
  marginTop: '30px',
  backgroundColor: '#fff7ed',
  borderRadius: '8px',
}
export const questionProgress = {
  paddingTop: '2em',
  paddingBottom: '1em',
  opacity: '1.0',
}

export const StyledButtonWrapper = styled.div`
  padding-top: 32px;
  display: flex;
  width: 100%;
`
export const StyledProgressWrapper = styled.div`
  position: relative;

  > div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
`

export const elementPushBottom = {
  flex: 1,
}

export const StyledOR = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  font-weight: bold;
  color: #777777;

  &:after,
  &:before {
    content: "";
    width: 30%;
    height: 2px;
    background: #dedede;
    margin: 0 15px;
  }
`
