import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { Player } from 'video-react'

export const StyledBtnWrapper = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
`
export const StyledBorder = styled.div`
  border-bottom: 1px solid #e5e5e5;
  & .MuiPaper-elevation1 {
    box-shadow: none;
  }
`
export const StyledRecBtn = styled(Button)`
  border: 4px solid;
  background: white;
`

export const VideoWrapper = styled(Player)`
  border-radius: 10px;
  video{
    border-radius: 10px;
     transform: rotateY(180deg);
    -webkit-transform:rotateY(180deg); /* Safari and Chrome */
    -moz-transform:rotateY(180deg); /
  };
  & .video-react-big-play-button {
    font-size: 4em;
    line-height: 2em;
    height: 2em;
    width: 2em;
    border-radius: 50%;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border:0;
    background-color: #D8D8D8;
    opacity: 0.5;
    
  }
  // & .video-react-big-play-button:hover {
    
  // }
`
export const StyledReRecButton = styled(Button)`
  width: 200px;
  &.MuiButtonBase-root {
    border: 4px solid #5DCCBF;
    font-weight: bold;
    border-radius: 4px;
    width: 220px;
    height: 45px;
    font-family: Lato;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 20px;
    background-color: white;
    text-transform: capitalize;
    color: #5DCCBF;
  }
  &.MuiButtonBase-root:hover {
    background-color: white;
  }
`
export const StyledTypography = styled(Typography)`
  p
  {
    font-size: 21px;
    font-family: 'poppins';
    font-weight: bold;
    line-height: 30px;
    letter-spacing: 0.4px
    color: #2e333b;
  }
  
`
export const StyledQuest = styled.p`
    font-size: 21px;
    font-family: 'poppins';
    font-weight: bold;
    line-height: 30px;
    letter-spacing: 0.4px
    color: #2e333b;
`
// reviewQuestion
export const reviewQuestion = {
  fontSize: '20px',
  fontFamily: 'Poppins Bold',
}

// reviewRerecordBtn
export const reviewRerecordBtn = {
  border: '4px solid',
  background: 'white',
  color: ' #2C4AFF',
  textTransform: 'capitalize',
  fontFamily: 'Lato',
  fontFize: '16px',
  letterSpacing: '1px',
  fontWeight: 'bold',
}
export const boxWrapperStyle = {
  margin: '0 auto',
  maxWidth: '1048px',
  minWidth: '768px',
}
