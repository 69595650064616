import firebase from 'firebase/app'

const config = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
}

/**
 * Firebase instance
 */
let app: firebase.app.App
const firebaseAppInstance = (): any => {
  const a = firebaseInitializaAppInstance()
  return a
}
/**
 * Initialize firebase instance with config,
 * if already then return
 */
const firebaseInitializaAppInstance = (): firebase.app.App => {
  if (app) return app
  app = firebase.initializeApp(config)
  return app
}
export { firebaseInitializaAppInstance, firebaseAppInstance }
