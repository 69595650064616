import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '20ch',
  },
  createButton: {
    fontWeight: 500,
    color: 'black',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
      boxShadow: '0px 1px 7px 1px #151515',
    },
  },
  serverError: {
    color: '#3f3d5',
    margin: 10,
    fontWeight: 500,
  },

  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 10000,
    height: 56,
    width: 56,
    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(10),
      right: theme.spacing(11),
      height: 80,
      width: 80,
    },
    backgroundColor: '#5DCCBF',
    color: 'white',
    '&:hover': {
      backgroundColor: '#1ABBBF',
    },
  },

  addIcon: {
    fontSize: '2rem',
  },

  search: {
    backgroundColor: 'white',
    border: '0px solid #ccc',
    position: 'relative',
    borderRadius: theme.shape.borderRadius * 2,
    marginLeft: 0,
    marginBottom: 20,
    [theme.breakpoints.up(680)]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
      border: '1px solid #ccc',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2, 0, 1.5),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    marginBottom: 0,
    // backgroundColor: 'white',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),

    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '42ch',
      '&:focus': {
        width: '50ch',
      },
    },
    [theme.breakpoints.up(400)]: {
      width: '22ch',
      '&:focus': {
        width: '30ch',
      },
    },
    [theme.breakpoints.up(850)]: {
      width: '42ch',
      '&:focus': {
        width: '50ch',
      },
    },
    [theme.breakpoints.up(1000)]: {
      width: '52ch',
      '&:focus': {
        width: '60ch',
      },
    },
  },
  divBeforeSearch: {
    [theme.breakpoints.down(1250)]: {
      display: 'none',
    },
  },
}))

export default useStyles
