import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import FormGroup from '@material-ui/core/FormGroup/FormGroup'
import Moment from 'react-moment'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { makeStyles } from '@material-ui/core/styles'
import { Player } from 'video-react'

import SampleVideo from '../../resource/Tutorial video.mov'
import { QuestionBoxContainer, QuestionBoxWrapper, StyledActionButton } from '../Home/style'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
})

function createData(name, email, location, status) {
  return {
    name,
    email,
    location,
    status,
    history: [
      { question: 'About Yourself', userId: '11091700', videoUrl: SampleVideo },
      {
        question: 'About Your current Role',
        userId: '11091700',
        videoUrl: SampleVideo,
      },
    ],
  }
}

function Row(props) {
  const { row } = props
  const [open, setOpen] = React.useState(false)
  const classes = useRowStyles()

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          {row.name}
        </TableCell>
        <TableCell align='right'>{row.email}</TableCell>
        <TableCell align='right'>{row.location}</TableCell>
        <TableCell align='right'>{row.status}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={1}>
              {row.history.map((historyRow) => (
                <div key={historyRow.question}>
                  <Typography variant='h3' component='h2'>
                    {historyRow.question}
                  </Typography>
                  <div>{historyRow.questionId}</div>
                  <Player>
                    <source src={historyRow.videoUrl} />
                  </Player>
                </div>
              ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

Row.propTypes = {
  row: PropTypes.shape({
    email: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}

const rows = [
  createData('Sudeep Melepunathil', 'user@gmail.com', 'USA', 'liked'),
  createData('Iraj Kumar', 'user@gmail.com', 'USA', 'liked'),
  createData('Rishab Garg', 'user@gmail.com', 'USA', 'liked'),
  createData('Yolo Jasmin', 'user@gmail.com', 'USA', 'liked'),
]

const BoardPage = () => (
  <div>
    <QuestionBoxContainer xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          <QuestionBoxWrapper>
            <Box>
              <div>
                <Typography variant='h4' component='h1'>
                  Java Developer
                  {/* {auth.user.displayName}! */}
                </Typography>
                <Typography variant='outline' color='textSecondary'>
                  <Moment format='Do MMMM YYYY - HH:mm'>2016-03-12 13:00:00</Moment>
                </Typography>
              </div>
            </Box>
          </QuestionBoxWrapper>
        </Grid>

        <Grid item xs={12} md={4}>
          <QuestionBoxWrapper>
            <FormGroup>
              <StyledActionButton>
                <Button
                  // color="primary"
                  //           onClick={_sendToInterview}
                  fullWidth
                >
                  Edit
                </Button>
                <Button
                  // color="primary"
                  //           onClick={_sendToInterview}
                  fullWidth
                >
                  Share
                </Button>
              </StyledActionButton>
            </FormGroup>
          </QuestionBoxWrapper>
        </Grid>
      </Grid>
    </QuestionBoxContainer>
    <br />
    <Grid container>
      <TableContainer component={Paper}>
        <Table aria-label='collapsible table'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Name</TableCell>
              <TableCell align='right'>Email</TableCell>
              <TableCell align='right'>Location</TableCell>
              <TableCell align='right'>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row key={row.name} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  </div>
)

export default BoardPage
