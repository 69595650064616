import data from '../data/questions.json'

export const initialState = {
  session: {
    isAdmin: true,
    username: '',
    email: '',
    isInterview: false,
    name: '',
    interviewCode: null,
  },
  questions: {
    loadedInterviews: data,
    isError: true,
    activeInterview: null,
  },
  user: null,
  auth: null,
  submit: [],
  interviews: {
    interviews: {
      question: {},
    },
  },
}
