import { useState, useEffect } from 'react'

export const useWindowResize = () => {
  const [size, setSize] = useState(window.innerWidth)

  useEffect(() => {
    const callback = () => setSize(window.innerWidth)
    window.addEventListener('resize', callback)
    return () => {
      window.removeEventListener('resize', callback)
    }
  }, [])

  return size
}
