import React from 'react'

const CheckCircleOutlineIcon = () => (
  <svg
    className='MuiSvgIcon-root'
    focusable='false'
    viewBox='0 0 24 24'
    ariaHidden='true'
    style={{ width: 130, height: 130 }}
  >
    <defs>
      <linearGradient id='grad1' x1='0%' y1='0%' x2='0%' y2='100%'>
        <stop offset='0%' style={{ stopColor: '#6dd400', stopOpacity: 1 }} />
        <stop offset='100%' style={{ stopColor: '#6dd400', stopOpacity: 1 }} />
      </linearGradient>
    </defs>
    <path
      d='M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'
      fill='url(#grad1)'
    />
  </svg>
)

export default CheckCircleOutlineIcon
