import React from 'react'
import { Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  inputCode: {
    backgroundColor: '#F6F6F6',
    width: '100%',
    height: 70,
    borderRadius: 10,
    borderWidth: 0,
    textAlign: 'center',
    fontSize: 26,
    fontFamily: 'Lato',
    color: '#6D7278',
    '&:focus-visible': {
      outline: '0px dashed darkorange',
    },
  },
  startImpressionButton: {
    width: 300,
    height: 65,
    borderRadius: 10,
    backgroundColor: '#5DCCBF',
    marginTop: 20,
    '&:hover': {
      backgroundColor: '#5DCCBF',
    },
  },

  containerStep4: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
  },
}))

export default function Step4({ handleNext }) {
  const classes = useStyles()
  const history = useHistory()

  return (
    <div style={{ height: 'calc(100vh - 240px)' }}>
      <div className={classes.containerStep4}>
        <Typography style={{ border: '1px solid red' }} variant='h2' align='center' style={{ color: '#5DCCBF' }}>
          Hurray!
        </Typography>

        <Typography style={{ border: '1px solid red' }} variant='body2' align='center' style={{ color: '#6D7278' }}>
          Your impression has been submitted successfully! <br />
          Now sit back and relax, you will be recieving updates if you are the right fit!{' '}
        </Typography>

        <Button
          onClick={() => history.push('/home')}
          color='primary'
          variant='contained'
          className={classes.startImpressionButton}
        >
          BACK TO HOME
        </Button>
      </div>
    </div>
  )
}
