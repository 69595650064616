import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import NavigateBack from 'shared/NavigateBack'
import firebase, { storage } from 'firebase/app'

import { IAppState } from 'models/IAppState'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectorGetAdminDisplayName,
  selectorStartImpressionCompanyName,
  selectorUpdateNameInProcess,
  selectorUpdatePasswordInProcess,
  selectorUserEmail,
  selectorUserFirstName,
  selectorUserID,
  selectorUserLastName,
} from 'store/selectors'
import { StyledActionWrapper } from './style'
import { actionShowNotification, userActionnUpdatePassword, userActionUpdateProfile, userActionUpdateProfilePicture } from 'store/actions'
import { IUpdateUserProfileRequest } from 'models/USER'
import { Grow, IconButton } from '@material-ui/core'
import ImageUploading from 'react-images-upload'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import avatar from '../../resource/none-avatar.png'

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  heading: {
    textAlign: 'center',
  },
  textField: {
    width: '25ch',
  },
  formControl: {
    margin: theme.spacing(1),
  },
  passwordMistmatch: {
    color: 'red',
    padding: '10px',
    borderLeft: (props: { textPresent: boolean }) => `2px solid ${props.textPresent ? 'red' : 'transparent'}`,
  },
  passwordMinLength: {
    color: '#dedede',
    padding: '10px',
    borderLeft: (props: { textPresent: boolean }) => `2px solid ${props.textPresent ? 'red' : 'transparent'}`,
  },
}))

const Settings = () => {
  const uid = useSelector((state: IAppState) => selectorUserID(state))
  const email = useSelector((state: IAppState) => selectorUserEmail(state))
  const fname = useSelector((state: IAppState) => selectorUserFirstName(state))
  const lname = useSelector((state: IAppState) => selectorUserLastName(state))
  const inProcess = useSelector((state: IAppState) => selectorUpdateNameInProcess(state))
  const updatePasswordInProcess = useSelector((state: IAppState) => selectorUpdatePasswordInProcess(state))
  const companyName = useSelector((state: IAppState) => selectorStartImpressionCompanyName(state))
  const currentUser = firebase.auth().currentUser
  const [photo, setPhoto] = useState('')
  const [loading, setLoading] = useState(false)
  const [photoURL, setPhotoURL] = useState(avatar)

  const [state, setState] = React.useState<IUpdateUserProfileRequest>({
    uid: uid || '',
    fname,
    lname,
    companyName: companyName || '',
  })
  const [password, setPassword] = useState<{
    currentPassword: string
    newPassword: string
    confirmPassword: string
  }>({
    newPassword: '',
    confirmPassword: '',
    currentPassword: '',
  })

  if (currentUser !== null) {

    const displayName = currentUser.displayName
    const email = currentUser.email
    const usrPhotoURL = currentUser.photoURL
    const emailVerified = currentUser.emailVerified
    const uid = currentUser.uid
  }

  const buttonDisabled = inProcess

  const dispatch = useDispatch()

  const labels = {
    heading: 'Accounts Settings',
    email: 'Email',
    name: 'Name',
    company: 'Company Name',
    update: 'Update',
    password: 'Password',
    buttons: {
      update: 'Update',
    },
  }

  const handleValueChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target
    setState({
      ...state,
      [name]: value,
    })
  }

  const updateProfile = () => {
    try {
      if (!uid) {
        throw new Error('Unable to save changes, please try again later')
      }
      dispatch(
        userActionUpdateProfile(
          { ...state },
          () => {
            dispatch(
              actionShowNotification({
                duration: 2000,
                message: 'Changes saved.',
                severity: 'success',
              }),
            )
          },
          () => {
            dispatch(
              actionShowNotification({
                duration: 2000,
                message: 'Unable to save changes, please try again later.',
                severity: 'warning',
              }),
            )
          },
        ),
      )
    } catch (ex) {
      dispatch(
        actionShowNotification({
          duration: 2000,
          message: ex.message,
          severity: 'warning',
        }),
      )
    }
  }

  const [showPassword, setShowPassword] = useState(false)

  const passwordFieldType = showPassword ? 'text' : 'password'
  const handlePasswordChange = (event: any) => {
    const { name, value } = event.target
    setPassword({
      ...password,
      [name]: value,
    })
  }
  const passwordMismatch = () => password.newPassword !== password.confirmPassword

  const passMinlength = password.newPassword.trim().length < 6
  const disablePasswordMismatch = () =>
    password.newPassword !== password.confirmPassword ||
    password.currentPassword.length === 0 ||
    password.newPassword.length === 0 ||
    password.confirmPassword.length === 0 ||
    passMinlength ||
    updatePasswordInProcess

  const updatePassword = () => {
    if (!email) return
    dispatch(
      userActionnUpdatePassword(
        {
          currentPassword: password.currentPassword,
          newPassword: password.newPassword,
          email,
        },
        (ok: any) => {
          console.log(ok)
          setPassword({
            newPassword: '',
            currentPassword: '',
            confirmPassword: '',
          })
          // Show notification snackbar
          dispatch(
            actionShowNotification({
              duration: 3000,
              message: 'Password updated succesfully',
              severity: 'success',
            }),
          )
        },
        (error: any) => {
          // Show notification snackbar
          dispatch(
            actionShowNotification({
              duration: 3000,
              message: error.message,
              severity: 'warning',
            }),
          )
          console.log(error)
        },
      ),
    )
  }

  // @dev update Profile Picture
  const handleClick = (photo: any) => {
    console.log('clicked', photo)
    if (!photo) return
    dispatch(
      userActionUpdateProfilePicture(
        {
          photo,
          currentUser
        },
        setPhotoURL,
        setLoading,
        (ok: any) => {
          console.log(ok)
          setPassword({
            newPassword: '',
            currentPassword: '',
            confirmPassword: '',
          })
          // Show notification snackbar
          dispatch(
            actionShowNotification({
              duration: 3000,
              message: 'Profile updated succesfully',
              severity: 'success',
            }),
          )
        },
        (error: any) => {
          // Show notification snackbar
          dispatch(
            actionShowNotification({
              duration: 3000,
              message: error.message,
              severity: 'warning',
            }),
          )
          console.log(error)
        },
      ),
    )
  }

  const classes = useStyles({
    textPresent: passwordMismatch(),
  })

  function handleChange(e: any) {
    console.log(e.target.files[0])
    if (e.target.files[0]) {
      setPhoto(e.target.files[0])
    }
  }

  useEffect(() => {
    if (currentUser?.photoURL) {
      setPhotoURL(currentUser.photoURL)
    } else {
      setPhotoURL(avatar)
    }
  }, [currentUser])

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box className={classes.paper} p={3}>
            <Box display='flex'>
              <Box>
                <NavigateBack />
              </Box>
              <Box flexGrow={1}>
                <Typography component='h2' variant='h2' className={classes.heading}>
                  {labels.heading}
                </Typography>
              </Box>
            </Box>
            {/* Profile Picture */}
            <form>
              <Box mt={4}>
                <Typography component='h3' variant='h6'>
                  Profile Picture
                </Typography>
                <div>
                  <FormControl className={classes.formControl}>
                    <div className="fields">
                      <FormControl className={classes.formControl}>
                        <ImageUploading
                          withIcon={false}
                          withPreview={false}
                          singleImage={true}
                          buttonText='Choose Profile Picture'
                          onChange={handleClick}
                          imgExtension={['.jpg', '.jpeg', '.png']}
                          maxFileSize={5242880}
                          fileSizeError=" file size is too big"
                        />
                        <img alt="Avatar" className="avatar" src={photoURL} width="40%" />
                      </FormControl>
                    </div>
                  </FormControl>
                </div>
              </Box>
            </form>
            {/* Email */}
            <Box mt={4}>
              <Typography component='h3' variant='h6'>
                {labels.email}
                <Typography component='p' variant='body1'>
                  {email}
                </Typography>
              </Typography>
            </Box>
            <form>
              <Box mt={4}>
                <Typography component='h3' variant='h6'>
                  {labels.name}
                </Typography>
                <div>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id='fname'
                      name='fname'
                      variant='outlined'
                      label='First Name'
                      size='small'
                      className={classes.textField}
                      value={state.fname}
                      onChange={handleValueChange}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id='lname'
                      variant='outlined'
                      label='Last Name'
                      size='small'
                      name='lname'
                      className={classes.textField}
                      value={state.lname}
                      onChange={handleValueChange}
                    />
                  </FormControl>
                  <br />
                  <FormControl className={classes.formControl}>
                    <Button disabled={buttonDisabled} color='secondary' variant='outlined' onClick={updateProfile}>
                      {labels.update}
                    </Button>
                  </FormControl>
                </div>
              </Box>
              {/* Password */}
              <Box mt={4}>
                <Typography component='h3' variant='h6'>
                  {labels.password}
                </Typography>
                <div>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id='current-password'
                      variant='outlined'
                      label='Current Password'
                      size='small'
                      name='currentPassword'
                      className={classes.textField}
                      value={password.currentPassword}
                      onChange={handlePasswordChange}
                      type={passwordFieldType}
                    />
                  </FormControl>
                  <IconButton onClick={() => setShowPassword(state => !state)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                  <br />
                  <FormControl className={classes.formControl}>
                    <TextField
                      id='new-password'
                      variant='outlined'
                      label='New Password'
                      size='small'
                      name='newPassword'
                      className={classes.textField}
                      value={password.newPassword}
                      onChange={handlePasswordChange}
                      type={passwordFieldType}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id='confirm-new-password'
                      variant='outlined'
                      label='Confirm New Password'
                      size='small'
                      name='confirmPassword'
                      className={classes.textField}
                      value={password.confirmPassword}
                      onChange={handlePasswordChange}
                      type={passwordFieldType}
                    />
                  </FormControl>
                  {passwordMismatch() && (
                    <Grow in={passwordMismatch()}>
                      <Box className={classes.passwordMistmatch}>{passwordMismatch() && 'Password mismatch'}</Box>
                    </Grow>
                  )}
                  {passMinlength && (
                    <Grow in={passMinlength}>
                      <Box className={classes.passwordMinLength}>
                        {passMinlength && 'NOTE: Password should be min 6 characters'}
                      </Box>
                    </Grow>
                  )}
                  <br />
                  <FormControl className={classes.formControl}>
                    <StyledActionWrapper>
                      <Button
                        color='secondary'
                        variant='outlined'
                        onClick={updatePassword}
                        disabled={disablePasswordMismatch()}
                      >
                        {labels.buttons.update}
                      </Button>
                    </StyledActionWrapper>
                  </FormControl>
                </div>
              </Box>
            </form>
          </Box>
          <Box className={classes.paper} p={3}>
            <Typography variant='h6'>
              Note: If you require support, please contact us at{' '}
              <a href='mailto:info@primoimpression.com'>info@primoimpression.com</a>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default Settings
