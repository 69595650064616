import React, { useState } from 'react'
import { Grid, Box, Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import imageStep1 from './imageStep1.svg'
import { useDispatch, useSelector } from 'react-redux'
import { selectorUserImpressionListSortedBy } from 'store/selectors'
import { actionShowNotification, actionStartImpression } from 'store/actions'

const useStyles = makeStyles(theme => ({
  inputCode: {
    backgroundColor: '#F6F6F6',
    width: 450,
    height: 70,
    borderRadius: 10,
    borderWidth: 0,
    textAlign: 'center',
    fontSize: 26,
    fontFamily: 'Lato',
    color: '#6D7278',
    '&:focus-visible': {
      outline: '0px dashed darkorange',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  startImpressionButton: {
    width: 391,
    height: 65,
    borderRadius: 10,
    backgroundColor: '#5DCCBF',
    '&:hover': {
      backgroundColor: '#5DCCBF',
    },
    [theme.breakpoints.down('xs')]: {
      width: '85%',
    },
  },
}))

export default function Step1({ handleNext, codeId }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [code, setCode] = useState(codeId || '')

  const sortByDateFn = list =>
    list.slice(0).sort((a, b) => {
      if (a.createdDate._seconds > b.createdDate._seconds) return -1
      if (a.createdDate._seconds < b.createdDate._seconds) return 1
      return 0
    })

  const userImpressionList = useSelector(state => selectorUserImpressionListSortedBy(state, sortByDateFn))

  const startImpression = () => {
    if (!code) {
      dispatch(
        actionShowNotification({
          message: 'Please type a code',
          severity: 'info',
          duration: 5000,
        }),
      )
      return
    }

    const alreadytUsedCode = userImpressionList.find(impression => impression.jobId === code)

    const messageCodeUsedAlready = 'You have already submitted an impression for that reference number.'

    if (alreadytUsedCode) {
      dispatch(
        actionShowNotification({
          message: messageCodeUsedAlready,
          severity: 'info',
          duration: 5000,
        }),
      )
      return
    }

    dispatch(actionStartImpression(code.toLowerCase(), handleNext))
  }

  return (
    <>
      <Box display='flex' justifyContent='center' mx={1} mt={1} mb={3}>
        <div
          style={{
            background: `url(${imageStep1}) no-repeat center center`,
            width: '100%',
            height: 425,
          }}
        />
      </Box>
      <Grid container>
        <Grid item sm={3}></Grid>
        <Grid item sm={6}>
          <Typography variant='h5' align='center'>
            Hi there! To start your impression, <br /> please enter the reference code you received.
          </Typography>
          <Box mt={3} display='flex' justifyContent='center'>
            <input value={code} onChange={e => setCode(e.target.value)} className={classes.inputCode} />
          </Box>
          <br />
          <Box display='flex' justifyContent='center' mb={4}>
            <Button
              onClick={startImpression}
              color='primary'
              variant='contained'
              className={classes.startImpressionButton}
            >
              START YOUR IMPRESSION
            </Button>
          </Box>
        </Grid>
        <Grid item sm={3}></Grid>
      </Grid>
    </>
  )
}
