const ACTION_TYPE = '[APP]'
const ACTION_APP_LOGIN_INIT = `${ACTION_TYPE} Login request [user/admin]`
const ACTION_APP_LOGIN_SUCCESS = `${ACTION_TYPE} Login success [user/admin]`
const ACTION_APP_LOGIN_FAIL = `${ACTION_TYPE} Login fail [user/admin]`
const ACTION_APP_LOGOUT = `${ACTION_TYPE} Logout [user/admin]`

// This action(s) will help to manage state that will helpful to render components
// based on user logged in.
// For example: if user is logged in we need to render menu based on user and if admin menu will be
// different.
// Current implementation of appication state
// already had separate state to hold information of logged in admin and user.
// So rather than updating the existing implementation which eventually will create mess,
// it's better to create separate state that hold details of user/admin which help to render components/items accordinly as per logged in user.

/**
 * Action fo login initiate.
 * @param role "user" | "admin"
 */
const actionAppLoginInit = (role: 'user' | 'admin') => ({
  type: ACTION_APP_LOGIN_INIT,
  payload: { role },
})
/**
 * Action for successful login
 * @param data? optional
 */
const actionAppLoginSuccess = (data?: any) => ({
  type: ACTION_APP_LOGIN_SUCCESS,
  payload: { ...data },
})
/**
 * Action for login failure
 */
const actionAppLoginFail = () => ({
  type: ACTION_APP_LOGIN_FAIL,
})
/**
 * Action for logout
 */
const actionAppLogout = () => ({
  type: ACTION_APP_LOGOUT,
})

export {
  ACTION_APP_LOGIN_INIT,
  ACTION_APP_LOGIN_SUCCESS,
  ACTION_APP_LOGIN_FAIL,
  ACTION_APP_LOGOUT,
  actionAppLoginInit,
  actionAppLoginSuccess,
  actionAppLoginFail,
  actionAppLogout,
}
