import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import { useHistory } from 'react-router-dom'
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined'
/**
 * ### React component name: NavigateBack
 * Navigate back to previous screen
 */
const NavigateBack = React.memo((props: any) => {
  const history = useHistory()
  const navigateBack = () => {
    history.goBack()
  }
  return (
    <IconButton {...props} title='Navigate back' onClick={navigateBack} aria-label='navigate back'>
      <ArrowBackIosOutlinedIcon />
    </IconButton>
  )
})
export default NavigateBack
