import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
/**
 * # Description
 * Generic Loading Indicator component, to use where Http or heavy calculation
 * operation is performing, so that we can hide the button/element on which event is
 * passed and show loading progress in replacement till the process complete.
 *
 * ## Note:
 * As this is generic loading progress component, we need to pass loading flag, as there
 * can be multiple process/API request(s) on going.
 */
const LoadingIndicator = React.memo(({ loading, children }: { loading: Boolean; children: React.ReactElement }) => {
  return <>{loading ? <CircularProgress id='in-process' aria-label='Wait for process to complete.' /> : children}</>
})
export default LoadingIndicator
