import { append } from 'ramda'
import { initialState } from 'constants/initialState'
import {
  INTERVIEW_CREATE_ERROR,
  INTERVIEW_CREATE_SUCCESS,
  INTERVIEW_LOAD_ERROR,
  INTERVIEW_LOAD_SUCCESS,
  INITILIZE,
  GET_SUBMIT_INTERVIEW,
} from '../../constants/types'
// TODO: Review later on.
export const questionsReducer = (state = initialState.questions, action) => {
  const { type, error, response } = action
  switch (type) {
    case INTERVIEW_CREATE_ERROR:
      return {
        ...state,
        isError: true,
        error,
      }
    case INTERVIEW_CREATE_SUCCESS:
      return {
        ...state,
        isError: false,
        loadedInterviews: append(response, state.loadedInterviews),
      }
    case INTERVIEW_LOAD_SUCCESS:
      return {
        ...state,
        isError: false,
        activeInterview: response,
      }
    case INTERVIEW_LOAD_ERROR:
      return {
        ...state,
        isError: true,
        activeInterview: null,
      }
    case INITILIZE:
      return {
        ...state,

        submit: [],
      }

    case GET_SUBMIT_INTERVIEW:
      return {
        ...state,
        submit: response,
      }
    default:
      return { ...state }
  }
}
