const APP_NOTFICATION = '[APP NOTIFICATION]'
/**
 * Action type: Show notification
 */
export const APP_SHOW_NOTIFICATION = `${APP_NOTFICATION} Show`
/**
 * Action type: Hide notification
 */
export const APP_HIDE_NOTIFICATION = `${APP_NOTFICATION} Hide`
/**
 * Call to show hide, it will auto hide based on duration provided.
 * Defaut will be 1sec.
 * @param payload INotification
 */
export const actionShowNotification = (payload: {
  message: string
  severity: 'success' | 'info' | 'warning' | 'error'
  duration?: number
}) => ({
  type: APP_SHOW_NOTIFICATION,
  payload,
})
/**
 * Call to hide manually.
 */
export const actionHideNotification = () => ({
  type: APP_HIDE_NOTIFICATION,
})
