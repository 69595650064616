import { IAppState, IAdminState, ImpressionInterface, ImpressionListItem } from 'models/IAppState'
/**
 * Return Application state
 * @param state IAppState
 */
const getAppState = (state: IAppState): IAppState => state
/**
 * Return IAdminState
 * @param state IAppState
 */
export const getAdminState = (state: IAppState): IAdminState => getAppState(state).admin
/**
 * Return admin is authenticated or not
 * @param state IAppState
 */
export const getIsAdminAuthenticated = (state: IAppState) => getAdminState(state).loggedIn

/**
 * Return role for authenticated admin.
 * @param state IAppState
 */
export const getAdminRole = (state: IAppState) => getAdminState(state).roles
/**
 * Returns displayName/Company Name for admin, at the time of registration we store company name as display name
 * @param state IAppState
 */
export const selectorGetAdminDisplayName = (state: IAppState) => getAdminState(state).data?.companyName
/**
 * Get admin email
 * @param state IAppState
 */
export const selectorGetAdminEmail = (state: IAppState) => getAdminState(state).data?.profile?.email
/**
 * Return admin first name
 * @param state IAppState
 */
export const selectorAdminFirstName = (state: IAppState) => getAdminState(state).data?.fname || 'Default Fist Name'
/**
 * Last Name
 * @param state IAppState
 */
export const selectorAdminLastName = (state: IAppState) => getAdminState(state).data?.lname || 'Default Last Name'
/**
 * Return full name fro Admin
 * @param state IAppState
 */
export const selectorAdminFullName = (state: IAppState) =>
  `${selectorAdminFirstName(state)?.trim()}, ${selectorAdminLastName(state)?.trim()}`
/**
* Return Admin Company full name from Admin
* @param state IAppState
*/
export const selectorAdminCompanyName = (state: IAppState) => getAdminState(state).data?.companyName || 'Default Company Name'
/**
 * Return impression list
 * @param state IAppState
 */
export const selectorAdminImpressionList = (state: IAppState) => getAdminState(state).impressionList
/**
 * Return list of titles from impression list.
 * @param state IAppState
 */
export const selectorAdminImpressionListTitles = (state: IAppState): string[] =>
  getAdminState(state).impressionList.map((item) => item.title)
/**
 * Return filter status
 * @param state IAppState
 */
export const selectorAdminFilterStatusList = (state: IAppState) => getAdminState(state).filterStatus
/**
 * Return uid for logged in Admin.
 * @param state IAppState
 */
export const selectorAdminID = (state: IAppState): string | null => getAdminState(state).uid
/**
 * Return error message for registration failure.
 * @param state IAppState
 */
export const selectorRegistrationErrorMessage = (state: IAppState): string | null =>
  getAdminState(state).registrationErrorMessage
/**
 * Return if there is HTTP request or state updation going in process.
 * @param state : IAppState
 */
export const selectorRequestInProcess = (state: IAppState): boolean => getAdminState(state).loading
/**
 * Return login error message if any or null.
 * @param state IAppState
 */
export const selectorLoginErrorMessage = (state: IAppState): string | null => getAdminState(state).loginErrorMessage

/**
 * ## ShareInterviewDialog
 * Share state for share interview dialog, if open or close.
 * @param state IAppState
 */
export const selectorGetShowInterviewDialogOpenState = (state: IAppState): boolean =>
  getAdminState(state).shareInteviewDialogOpen.isOpen
/**
 * ## ShareInterviewDialog
 * Share state for share interview dialog route, if present then navigate or nothing.
 * @param state IAppState
 */
export const selectorGetShowInterviewDialogRoute = (state: IAppState): string | null =>
  getAdminState(state).shareInteviewDialogOpen.navigateTo
/**
 * ## ShareInterviewDialog
 * Return interview ID when opened or null when closed.
 * @param state IAppState
 */
export const selectorGetShowInterviewDialogInterviewID = (state: IAppState): string | null =>
  getAdminState(state).shareInteviewDialogOpen.interviewID

export const selectorArchiveDialogIsOpen = (state: IAppState): boolean => getAdminState(state).archiveDiaolog.isOpen

export const selectorArchiveDialogJobId = (state: IAppState): string | null => getAdminState(state).archiveDiaolog.jobId

export const selectorViewImpressionDialogIsOpen = (state: IAppState): boolean =>
  getAdminState(state).viewImpressionDialog.isOpen

export const selectorViewImpressionDialogJobId = (state: IAppState): string | null =>
  getAdminState(state).viewImpressionDialog.jobId

export const selectorSelectedInterview = (state: IAppState): ImpressionInterface | null =>
  getAdminState(state).selectedInterview

export const selectorCopiedInterview = (state: IAppState): ImpressionInterface | null =>
  getAdminState(state).copiedInterview

export const selectorInterviewLimit = (state: IAppState): number => getAdminState(state).interviewLimit

export const selectorCreateInterviewMaxLimitReached = (state: IAppState): boolean =>
  selectorAdminImpressionList(state).length >= selectorInterviewLimit(state)

export const selectorGetCandidateData = (state: IAppState) => getAdminState(state).candidateList

export const selectorGetCandidateList = (state: IAppState): ImpressionListItem[] => selectorGetCandidateData(state).list

export const selectorGetCandidateListLoading = (state: IAppState): boolean => selectorGetCandidateData(state).loading

/**
 * True when request in process for interview status and archive update
 * @param state : IAppState
 */
export const selectorInterviewUpdateRequestInProcess = (state: IAppState): boolean =>
  getAdminState(state).updateInterviewInProcess
