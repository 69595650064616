import React from 'react'
import Typography from '@material-ui/core/Typography'
import '../../../node_modules/video-react/dist/video-react.css' // import css
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { interviewActions } from '../../actions'
import ReviewVideo from '../ReviewVideo'
import { StyledReRecButton, RecBtnWrapper, RecBoxWrapper, StyledBoxWrapper } from './style'

import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'

const ReviewAnswers = function ({
  question,
  answers,
  setCurrRecPage,
  openRecordModal,
  _recordAgain,
  currQuestion,
  _startOver,
  submissionInProcess,
  uploadingInProcess,
}) {
  const [expanded, setExpanded] = React.useState(0)
  const handleExpand = panel => {
    setExpanded(panel)
  }

  const ReRecordBtn = ({ children, ...rest }) => <StyledReRecButton {...rest}>{children}</StyledReRecButton>
  return (
    <StyledBoxWrapper xs={12}>
      <Grid container spacing={4} style={{ justifyContent: 'center', paddingBottom: '50px' }}>
        <Grid item xs={12} md={8}>
          <RecBoxWrapper p={1}>
            <Box>
              <Typography variant='h4' component='h1'>
                Review and submit your video(s)
              </Typography>

              {/* <Typography variant="h6" component="p">
                Questions
              </Typography> */}
              {answers.length > 0 ? (
                <div>
                  {answers.map((answer, id) => (
                    <ReviewVideo
                      key={id}
                      answer={answer}
                      id={id}
                      setCurrRecPage={setCurrRecPage}
                      currQuestion={currQuestion}
                      openRecordModal={openRecordModal}
                      _recordAgain={_recordAgain}
                      question={question}
                      expanded={expanded}
                      onPanelChange={handleExpand}
                    />
                  ))}
                </div>
              ) : (
                ''
              )}
            </Box>
            <RecBtnWrapper>
              {uploadingInProcess ? (
                <>
                  <CircularProgress />
                  &nbsp;Processing video(s)
                </>
              ) : submissionInProcess ? (
                <CircularProgress />
              ) : (
                <ReRecordBtn onClick={_startOver}>Submit</ReRecordBtn>
              )}
            </RecBtnWrapper>
          </RecBoxWrapper>
        </Grid>
      </Grid>
    </StyledBoxWrapper>
  )
}

const mapStateToProps = ({ questions, interviews }) => ({
  questions: questions.activeInterview,
  userQuestions: questions,
  user: questions.user,
  interviews,
})
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(interviewActions, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(ReviewAnswers)
