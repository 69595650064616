import { firebaseAppInstance } from 'data/firebaseConfig'
import firebase from 'firebase/app'
import 'firebase/auth'
import axios from 'axios'
import { ICompanyUser, IHrProfileCreation } from 'models/ICompanyUser'
import { ApiEndpoints } from 'utils'
import { Dispatch } from 'redux'
import { IUpdateAdminRequest } from 'models/API'
import { actionAppLoginInit, actionAppLoginSuccess, actionAppLoginFail, actionAppLogout } from './app.action'
import {
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGOUT,
  ADMIN_LOGIN_INIT,
  ADMIN_CREATE_INIT,
  ADMIN_CREATION_SUCCESS,
  ADMIN_CREATION_FAIL,
  ADMIN_SHARE_INTERVIEW_DIALOG_OPEN,
  ADMIN_SHARE_INTERVIEW_DIALOG_CLOSE,
  ADMIN_ACTION_SHOW_ARCHIVE_DIALOG,
  ADMIN_ACTION_HIDE_ARCHIVE_DIALOG,
  ADMIN_ACTION_HIDE_VIEW_IMPRESSION_DIALOG,
  ADMIN_ACTION_SHOW_VIEW_IMPRESSION_DIALOG,
  ADMIN_ACTION_SELECT_INTERVIEW,
  ADMIN_ACTION_UNSELECT_INTERVIEW,
  ADMIN_ACTION_COPY_SELECTED_INTERVIEW,
  ADMIN_ACTION_REMOVE_COPY_SELECTED_INTERVIEW,
  ADMIN_ACTION_UPDATE_FNAME_LNAME_INIT,
  ADMIN_ACTION_UPDATE_FNAME_LNAME_SUCCESS,
  ADMIN_ACTION_UPDATE_FNAME_LNAME_FAIL,
  ADMIN_ACTION_ADMIN_PASSWORD_UPDATE_INIT,
  ADMIN_ACTION_ADMIN_PASSWORD_UPDATE_SUCCESS,
  ADMIN_ACTION_ADMIN_PASSWORD_UPDATE_FAIL,
} from '../types'
import { actionShowNotification } from './notification.action'

export const auth = firebase.auth(firebaseAppInstance())

export async function isAdmin(user: firebase.User) {
  if (!user) throw new Error('No such account found')
  const idTokenResult = await user.getIdTokenResult()
  // Confirm the user is an not admin.
  if (!!idTokenResult.claims.admin) {
    // Show admin UI.
    return true
  } else {
    // Show regular user UI.
    throw new Error('No such user found')
  }
}

/**
 * Admin login action
 * @param email String
 * @param password String
 */
export const actionSignInWithEmailAndPassword =
  (email: string, password: string, error: Function) => async (dispatch: any) => {
    try {
      dispatch({
        type: ADMIN_LOGIN_INIT,
      })
      dispatch(actionAppLoginInit('admin'))
      // login in
      const res = await auth.signInWithEmailAndPassword(email, password)
      const user = auth.currentUser
      if (!user) throw new Error('No user found')
      await isAdmin(user)

      storeLocally(user)

      const profile = JSON.parse(JSON.stringify(user))

      dispatch({
        type: ADMIN_LOGIN_SUCCESS,
        payload: profile,
      })
      dispatch(actionAppLoginSuccess({ ...res }))
    } catch (err) {
      error()
      dispatch(
        actionShowNotification({
          message: err.message,
          severity: 'warning',
          duration: 4000,
        }),
      )
      dispatch({
        type: ADMIN_LOGIN_FAIL,
        payload: err,
      })
      dispatch(actionAppLoginFail())
    }
  }
/**
 * Action to create new admin user
 * @param email {String} Useremail
 * @param password {String} User password
 */
export const actionSignUpWithEmailAndPassword = (admin: ICompanyUser) => async (dispatch: any) => {
  try {
    dispatch({
      type: ADMIN_CREATE_INIT,
    })
    dispatch(actionAppLoginInit('admin'))
    // sign up
    await auth.createUserWithEmailAndPassword(admin.email, admin.password)
    const user = auth.currentUser
    if (!user) return

    const profile = JSON.parse(JSON.stringify(user))

    const data: IHrProfileCreation = {
      companyName: admin.companyName,
      email: admin.email,
      firstName: admin.firstName,
      lastName: admin.lastName,
      uid: profile.uid,
    }

    const profileCreated = await createHrProfile(data)

    // admin hr profile created succesfully
    // update the display name for admin
    user.updateProfile({
      displayName: admin.companyName,
    })

    storeLocally(user)
    // Manually delay, as intial API call to get  admin details fail first time we we create admin.
    setTimeout(() => {
      dispatch({
        type: ADMIN_CREATION_SUCCESS,
        payload: { ...data, profile },
      })
      dispatch(actionAppLoginSuccess({ ...profileCreated }))
    }, 2000)
  } catch (err) {
    dispatch(
      actionShowNotification({
        message: err.message,
        severity: 'warning',
        duration: 4000,
      }),
    )
    dispatch({
      type: ADMIN_CREATION_FAIL,
      payload: err,
    })
    dispatch(actionAppLoginFail())
  }
}
/**
 * Action to logout admin by calling firebase logout api
 * and clear data from localStorage
 * i.e.localStorage.remove('admin);
 */
export const actionAdminLogout = () => (dispatch: any) => {
  auth.signOut().then(() => {
    // localStorage.removeItem('admin')
    // localStorage.removeItem('isAdminLoggedIn')
    localStorage.clear()
    dispatch({
      type: ADMIN_LOGOUT,
    })

    dispatch(actionAppLogout())
    dispatch(
      actionShowNotification({
        message: 'You successfully logged out',
        severity: 'success',
        duration: 2200,
      }),
    )
  })
}

/**
 * Create a HR profile after succesful registration of admin.
 * @param data {IHrProfileCreation}
 * @returns {Promise} Promise
 */
const createHrProfile = (data: IHrProfileCreation): Promise<any> => axios.post(ApiEndpoints.hrprofile(), data)
/**
 * Store data to local storage on succesful login
 * @param user {IFirebaseObject}
 */
const storeLocally = (user: firebase.User) => {
  localStorage.setItem('admin', JSON.stringify(user))
  localStorage.setItem('isAdminLoggedIn', 'true')
}

/**
 * Share interview actions
 */

/**
 * ## Description
 * Open share interview dialog.
 * @param interviewId : {string} Interview ID
 * @param route : {string|null} Route navigate to after share dialog closes
 */
export const adminActionShareInterviewDialogOpen = (interviewId: string, route: string | null = null) => ({
  type: ADMIN_SHARE_INTERVIEW_DIALOG_OPEN,
  payload: { id: interviewId, route },
})

/**
 * ## Description
 * Close share interview dialog.
 */
export const adminActionShareInterviewDialogClose = () => ({
  type: ADMIN_SHARE_INTERVIEW_DIALOG_CLOSE,
})

export const adminActionShowArchiveDialog = (id: string) => ({
  type: ADMIN_ACTION_SHOW_ARCHIVE_DIALOG,
  payload: id,
})
/**
 */
export const adminActionHideArchiveDialog = () => ({
  type: ADMIN_ACTION_HIDE_ARCHIVE_DIALOG,
})

export const adminActionShowViewImpressionDialog = (id: string) => ({
  type: ADMIN_ACTION_SHOW_VIEW_IMPRESSION_DIALOG,
  payload: id,
})
/**
 */
export const adminActionHideViewImpressionDialog = () => ({
  type: ADMIN_ACTION_HIDE_VIEW_IMPRESSION_DIALOG,
})

export const adminActionSelectInterview = (id: string) => ({
  type: ADMIN_ACTION_SELECT_INTERVIEW,
  payload: id,
})

export const adminActionUnselectInterview = () => ({
  type: ADMIN_ACTION_UNSELECT_INTERVIEW,
})

export const adminActionSelectedInterviewAfterImpressionListAction =
  (id: string, success: Function = () => { }) =>
    (dispatch: Dispatch) => {
      dispatch(adminActionSelectInterview(id))
      success()
    }

// CLONE EXISTING INTERVIEW

export const adminActionCopySelectedInterview = (id: string) => ({
  type: ADMIN_ACTION_COPY_SELECTED_INTERVIEW,
  payload: id,
})

export const adminActionRemoveCopiedSelectedInterview = () => ({
  type: ADMIN_ACTION_REMOVE_COPY_SELECTED_INTERVIEW,
})

// UPDATE ADMIN PROFILE

export const adminActionUpdateProfileInit = (data: IUpdateAdminRequest) => ({
  type: ADMIN_ACTION_UPDATE_FNAME_LNAME_INIT,
  payload: data,
})

export const adminActionUpdateProfileSuccess = (data: IUpdateAdminRequest) => ({
  type: ADMIN_ACTION_UPDATE_FNAME_LNAME_SUCCESS,
  payload: data,
})

export const adminActionUpdateProfileFail = () => ({
  type: ADMIN_ACTION_UPDATE_FNAME_LNAME_FAIL,
})

export const adminActionUpdateProfile =
  (_email: any, payload: IUpdateAdminRequest, success: Function, error: Function) => async (dispatch: Dispatch) => {
    try {
      let response: any = []
      // @dev: update admin profile with axio update-hrprofile
      dispatch(adminActionUpdateProfileInit(payload))
      await axios.post(ApiEndpoints.updateAdminProfile(), payload)
        .then((res) => {
          response = res
        }).catch((err) => {
          throw err
        })

      // @dev: update admin profile with axio hrprofile
      await axios
        .post(ApiEndpoints.hrprofile(),
          {
            'companyName': payload['companyName'],
            'email': _email,
            'firstName': payload['fname'],
            'lastName': payload['lname'],
            'uid': payload['uid']
          }
        )
      const data = await response
      if (!data) {
        return dispatch(adminActionUpdateProfileFail())
      }
      // Success
      const { id } = data
      dispatch(adminActionUpdateProfileSuccess(payload))
      success(id)
    } catch (ex) {
      // Request Fail
      dispatch(adminActionUpdateProfileFail())
      error()
    }
  }

// Update admin account password

export const actionAdminUpdatePasswordInit = () => ({
  type: ADMIN_ACTION_ADMIN_PASSWORD_UPDATE_INIT,
})

export const actionAdminUpdatePasswordSuccess = (ok: any) => ({
  type: ADMIN_ACTION_ADMIN_PASSWORD_UPDATE_SUCCESS,
  payload: ok,
})

export const actionAdminUpdatePasswordFail = (error: any) => ({
  type: ADMIN_ACTION_ADMIN_PASSWORD_UPDATE_FAIL,
  payload: error,
})

export const adminActionUpdatePassword =
  (
    { email, currentPassword, newPassword }: { email: string; currentPassword: string; newPassword: string },
    success: Function,
    error: Function,
  ) =>
    (dispatch: Dispatch) => {
      dispatch(actionAdminUpdatePasswordInit())
      const user = auth.currentUser
      try {
        // https://firebase.google.com/docs/reference/js/firebase.User#reauthenticatewithcredential
        const credentials = firebase.auth.EmailAuthProvider.credential(email, currentPassword)

        const promise = user?.reauthenticateWithCredential(credentials)
        if (!promise) return
        promise
          .then((ok) => {
            const updatePassword = user?.updatePassword(newPassword)
            if (!updatePassword) return
            updatePassword
              .then(() => {
                // Update successful.
                dispatch(actionAdminUpdatePasswordSuccess(ok))
                success(ok)
              })
              .catch((error) => {
                // An error happened.
                dispatch(actionAdminUpdatePasswordFail(error))
                error(error)
              })
          })
          .catch((err) => {
            dispatch(actionAdminUpdatePasswordFail(err))
            error(err)
          })
      } catch (ex) {
        dispatch(actionAdminUpdatePasswordFail(ex))
        error(ex)
      }
    }
