import React from 'react'
import LazyLoadingComponent from 'shared/LazyLoadingComponent'

const InterviewBuilder = React.lazy(() => import('components/Admin/stateless/InterviewBuilder'))
/**
 * Admin home i.e. Dashboard page
 */
export default function AdminHome() {
  return (
    <>
      <LazyLoadingComponent>
        <InterviewBuilder />
      </LazyLoadingComponent>
    </>
  )
}
