import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Tabs, Tab, Chip } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { filterMenu } from 'store/slices/menuSlice'
import { selectorAdminImpressionList } from 'store/selectors'

export default function TabsAdminDashboard({ screen }) {
  const useStyles = makeStyles((theme) => ({
    tabs: {
      display: screen === 'mobile' ? 'none' : 'flex',
      flexGrow: 3,
      paddingTop: 15,
      height: 75,
      [theme.breakpoints.down(1120)]: {
        marginLeft: -80,
      },
      [theme.breakpoints.down(1040)]: {
        display: screen === 'desktop' ? 'none' : 'flex',
        marginLeft: 0,
        height: 65,
        marginTop: -20,
      },
    },
    chipContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down(600)]: {
        margin: '0px 10px',
      },
    },
    chip: {
      [theme.breakpoints.down(650)]: {
        display: 'none',
      },
    },
  }))

  const impressionList = useSelector((state) => selectorAdminImpressionList(state))

  const getImpressionsLength = (text) => {
    return impressionList.filter((impression) => impression.status === text).length
  }

  const options = [
    { text: 'All', label: impressionList.length, key: 0 },
    { text: 'Active', label: getImpressionsLength('active'), key: 1 },
    { text: 'Closed', label: getImpressionsLength('closed'), key: 2 },
    { text: 'New Applicants', label: getImpressionsLength('new applicants'), key: 3 },
  ]

  const { menu } = useSelector((state) => state)

  const dispatch = useDispatch()

  const classes = useStyles()

  const handleChange = (event, newValue) => {
    const { text } = options[newValue]
    dispatch(filterMenu({ option: text.toLowerCase(), key: newValue }))
  }

  return (
    <Tabs
      className={classes.tabs}
      value={menu.key}
      onChange={handleChange}
      indicatorColor='primary'
      textColor='primary'
      centered
    >
      {options.map((option) => {
        return (
          <Tab
            style={{ padding: 0 }}
            key={option.key}
            icon={
              <div className={classes.chipContainer}>
                {option.text}
                <Chip
                  className={classes.chip}
                  color={menu.key === option.key ? 'primary' : 'default'}
                  size='small'
                  style={{ marginLeft: 5, cursor: 'pointer' }}
                  label={option.label}
                />{' '}
              </div>
            }
          />
        )
      })}
    </Tabs>
  )
}
