import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { interviewActions } from '../../actions'

const Interview = ({ actions, interviews }) => {
  const [interview, setInterview] = useState(null)
  const [text, setText] = useState('')

  useEffect(() => {
    if (interview === null) {
      setInterview(interviews.interviews)
    }
  }, [interviews.interviews, interview])

  const getInterview = () => {
    if (text !== '') {
      actions.getInterview(text)
    }
    setText('')
    setInterview(interviews.interviews)
  }

  if (interview !== null && interview.job !== undefined && interview.job.candidates) {
    var result = Object.keys(interview.job.candidates).map((key) => interview.job.candidates[key])

    // interViewArray(result)
  }

  return (
    <div className='container' style={{ textAlign: 'center' }}>
      <div className='container'>
        {/* <h3 className=" title is-5 has-text-centered -m-t-30 has-text-weight-normal has-text-grey">
          Company:
          <span className="has-text-weight-semibold">PlumTreeGroup</span>
        </h3> */}
        <div className='-m-t-60 has-text-centered'>
          <h4 className='title is-4  has-text-black'>Review An Interview</h4>
          <div className='columns is-centered'>
            <div className='column is-half'>
              <p className='has-text-weight-semibold has-text-grey is-size-5'>
                To review an interview, please enter the 7 characters reference number
              </p>
              <div className='field'>
                <div className='control'>
                  <input
                    className='input is-large if-info has-text-centered -m-t-30'
                    value={text}
                    placeholder='Enter HRecordID'
                    type='text'
                    onChange={(t) => setText(t.target.value)}
                  />
                </div>
              </div>
              <button
                style={{ backgroundColor: 'rgb(0, 126, 254)', color: 'white' }}
                className='button is-black is-large -m-t-30 is-fullwidth'
                onClick={() => getInterview()}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>

      {interview !== null && interview.passive === false ? (
        <div className='-m-t-60 has-text-centered'>
          <h4 className='title is-4  has-text-black'>Name : {interview.user.name}</h4>
          <h4 className='title is-4  has-text-black'>Position : {interview.user.jobPosition}</h4>
          <div className='columns is-centered'>
            <div className='column is-full'>
              {interview.interView !== undefined ? (
                <div>
                  {interview.interView.map((val, ind) => (
                    <div id={ind + 1}>
                      <h4 className='is-4  has-text-black'>
                        Question {ind + 1} - {val.questionText}
                      </h4>
                      <h4 className='is-4  has-text-black'>
                        Video Response:
                        {val.video ? (
                          <a target='_blank' rel='noopener noreferrer' href={val.video}>
                            {val.video.slice(0, 55)}
                            ...
                          </a>
                        ) : (
                          ''
                        )}
                      </h4>
                      <br />
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}

      {interview !== null && interview.job ? (
        <div className='-m-t-60 has-text-centered'>
          <h2 className='title is-4  has-text-black'>
            Company : {interview.job.companyName} - Position :{interview.job.position}
          </h2>
          {/* <h4 className="title is-4  has-text-black"></h4>  */}
          <div className='columns is-centered'>
            <div className='column is-full'>
              {interview.job !== undefined && interview.job.candidates ? (
                <div>
                  <h2 className='title is-4  has-text-black'>List of Candidates</h2>

                  {result.map((val, ind) => (
                    <div id={ind + 1}>
                      <h4 className='is-4  has-text-black'>Name: {val.name}</h4>
                      <h4 className='is-4  has-text-black'>Email : {val.email}</h4>
                      <br />

                      <div className='column is-full'>
                        {val.interView !== undefined ? (
                          <div>
                            {val.interView.map((val, ind) => (
                              <div id={ind + 1}>
                                <h4 className='is-4  has-text-black'>
                                  Question {ind + 1} - {val.questionText}
                                </h4>
                                <h4 className='is-4  has-text-black'>
                                  Video Response:
                                  {val.video ? (
                                    <a rel='noopener noreferrer' target='_blank' href={val.video}>
                                      {val.video.slice(0, 55)}
                                      ...
                                    </a>
                                  ) : (
                                    ''
                                  )}
                                </h4>
                                <br />
                              </div>
                            ))}
                          </div>
                        ) : null}
                        <hr />
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

const mapStateToProps = ({ ...state }) => ({
  interviews: state.interviews,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(interviewActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Interview)
