import { IAppState } from 'models/IAppState'
import { IAuthState } from 'models/IAuthState'
/**
 * Return Application state
 * @param state IAppState
 */
const getAppState = (state: IAppState): IAppState => state

const getUserState = (state: IAppState): IAuthState => getAppState(state).app

export const getUserEmail = (state: IAppState): string => getUserState(state).data?.user.email || ''

export const selectorUserID = (state: IAppState): string => getUserState(state).data?.user.uid || ''

// User State
export const selectorUserState = (state: IAppState) => state.user

export const selectorUserProfile = (state: IAppState) => selectorUserState(state).profile

export const selectorStartImpression = (state: IAppState) => selectorUserState(state).startImpression

export const selectorStartImpressionJobId = (state: IAppState) =>
  selectorUserState(state).startImpression.payload.data?.jobId

export const selectorUserFirstName = (state: IAppState) => selectorUserProfile(state).data.fname

export const selectorUserLastName = (state: IAppState) => selectorUserProfile(state).data.lname

export const selectorUserEmail = (state: IAppState) => selectorUserProfile(state).data.email

export const selectorUserImpressionList = (state: IAppState) => selectorUserProfile(state).data.impressionList

export const selectorUserImpressionListSortedBy = (state: IAppState, sortFunction: Function) =>
  sortFunction(selectorUserImpressionList(state))

export const selectorProfileApiInProcess = (state: IAppState) => selectorUserProfile(state).loading

// * Start Impression
export const selectorStartImpressionValidateCodeInProcess = (state: IAppState) => selectorStartImpression(state).loading

export const selectorStartImpressionImpressionCode = (state: IAppState) => selectorStartImpression(state).payload.id

export const selectorStartImpressionPayload = (state: IAppState) => selectorStartImpression(state).payload.data

export const selectorStartImpressionQuestions = (state: IAppState) =>
  selectorStartImpression(state).payload.data?.questions || []

export const selectorStartImpressionPositionName = (state: IAppState) =>
  selectorStartImpression(state).payload.data?.title

export const selectorStartImpressionCompanyName = (state: IAppState) =>
  selectorStartImpression(state).payload.data?.companyName

//* Submit impression

export const selectorSubmitImpressionInProcess = (state: IAppState) => selectorUserState(state).submitImpression.loading

// * Uploading video to firebase storage in process

export const selectorUploadingVideoInProcess = (state: IAppState) => selectorUserState(state).uploadToFirebase.loading

//* Updating First & Last Name

export const selectorUpdateNameInProcess = (state: IAppState) => selectorUserState(state).updatingProfile.loading

export const selectorUpdatePasswordInProcess = (state: IAppState) => selectorUserState(state).updatingPassword

/**
 * Return if there is HTTP request or state updation going in process.
 * @param state : IAppState
 */
export const selectorRequestUserInProcess = (state: IAppState): boolean => selectorUserProfile(state).loading
