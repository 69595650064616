export const INTERVIEW_CREATE_PENDING = 'INTERVIEW_CREATE_PENDING'
export const INTERVIEW_CREATE_SUCCESS = 'INTERVIEW_CREATE_SUCCESS'
export const INTERVIEW_CREATE_ERROR = 'INTERVIEW_CREATE_ERROR'
export const INTERVIEW_LOAD_PENDING = 'INTERVIEW_LOAD_PENDING'
export const INTERVIEW_LOAD_SUCCESS = 'INTERVIEW_LOAD_SUCCESS'
export const INTERVIEW_LOAD_ERROR = 'INTERVIEW_LOAD_ERROR'
export const USER_SUCCESS = 'USER_SUCCESS'
export const GET_INTERVIEW = 'GET_INTERVIEW'
export const INITILIZE = 'INITILIZE'
export const INITILIZE_INTERVIEWS = 'INITILIZE_INTERVIEWS'
export const GET_SUBMIT_INTERVIEW = 'GET_SUBMIT_INTERVIEW'
export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',
}
