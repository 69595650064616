import { initialState } from '../../constants/initialState'
import { GET_INTERVIEW, INITILIZE_INTERVIEWS } from '../../constants/types'
// TODO: Review later on.
export const getInterviewReducer = (state = initialState, action) => {
  const { type, response } = action
  switch (type) {
    case GET_INTERVIEW:
      return {
        ...state,
        interviews: response,
      }
    case INITILIZE_INTERVIEWS:
      return {
        ...state,
        interviews: initialState.interviews,
      }
    default:
      return state
  }
}
