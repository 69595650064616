import { screenWidth } from 'common.style'
import styled from 'styled-components'

export const MobileDrawer = styled.section`
  height: 100%;
  width: 90vw;
  padding: 30px;
  display: flex;
  flex-direction: column;
`

export const MobileDrawerTop = styled.section`
  display: flex;
  align-items: center;
  height: 100px;

  section.image {
    margin: 5px;
  }
  section.user-greet {
    flex: 1;
    display: flex;
    flex-direction: column;
    section.name {
      font-size: 18px;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 190px;
    }
    section.greet {
      font-size: 12px;
      color: #7c7c7c;
    }
  }
`

export const MobileDrawerConent = styled.section`
  flex: 8;
  color: #7c7c7c;
`
export const MobileDrawerBottom = styled.section`
  text-align: center;
  .logout-text {
    color: red;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const MobileDrawerFooter = styled.section`
  font-size: 12px;
  text-align: center;
  p {
    margin: 3px auto;
  }
`

export const HeaderContainer = styled.header`
  width: 100vw;
  section {
    width: ${screenWidth};
    display: flex;
    margin: 0 auto;
    height: 75px;
  }
`

export const DesktopMenuRightSide = styled.section`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`

export const HeaderLogo = styled.section`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: ${(props: any) => (props.mobile ? 'flex-end' : '')};
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      @media (min-width: 768px) {
        width: 124px;
        height: 32px;
      }
      @media (max-width: 768px) {
        width: 124px;
        height: 32px;
      }
    }
  }
`
