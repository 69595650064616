import { ImpressionInterface } from 'models/IAppState'
import { CreateBoardInterview } from 'models/CreateBoard'
import { Dispatch } from 'redux'
import Axios from 'axios'
import { ApiEndpoints } from 'utils'
import { IImpressionListAPIResponse, UpdateImpressionRequest } from 'models/API'
import { ACTION_UPDATE_ADMIN_DETAILS } from 'store/types'
import { ShareInterview } from 'models/ReponseInterface'
import { firebaseAppInstance } from 'data/firebaseConfig'
import firebase from 'firebase/app'
import 'firebase/firestore'

const ACTION_TYPE = '[IMPRESSION]'

// Initialize Firebase
const db = firebase.firestore()
/**
 * Action Type: Get impression list init
 */
export const ACTION_GET_IMPRESSION_LIST_INIT = `${ACTION_TYPE} Get impression list - INIT`
/**
 * Action Type: Get impresssion list success
 */
export const ACTION_GET_IMPRESSION_LIST_SUCCESS = `${ACTION_TYPE} Get impression list - SUCCESS`
/**
 * Action Type: Get impression list fail
 */
export const ACTION_GET_IMPRESSION_LIST_FAIL = `${ACTION_TYPE} Get impression list - FAIL`

/**
 * Action: Get impression list init
 * @param id: Admin unique ID
 */
const actionGetImpressionListInit = (id: string) => ({
  type: ACTION_GET_IMPRESSION_LIST_INIT,
  payload: id,
})
/**
 * Action: Get impression list success
 * @param list ImpressionInterface[]
 * @param interviewLimit number, allow admin to create upto given number of interview limit.
 */
const actionGetImpressionListSuccess = (list: ImpressionInterface[], interviewLimit: number) => ({
  type: ACTION_GET_IMPRESSION_LIST_SUCCESS,
  payload: list,
  interviewLimit,
})
/**
 * Action: Get impression list fail
 * @param error Error
 */
const actionGetImpressionListFail = (error?: any) => ({
  type: ACTION_GET_IMPRESSION_LIST_FAIL,
  payload: error,
})

export const ACTION_CREATE_INTERVIEW_INIT = `${ACTION_TYPE} Create Interview Init`
const actionCreateInterviewInit = (payload: any) => ({
  type: ACTION_CREATE_INTERVIEW_INIT,
  payload,
})

export const ACTION_CREATE_INTERVIEW_SUCCESS = `${ACTION_TYPE} Create Interview Success`
/**
 *
 * @param payload
 */
const actionCreateInterviewSuccess = (payload: any) => ({
  type: ACTION_CREATE_INTERVIEW_SUCCESS,
  payload,
})

export const ACTION_CREATE_INTERVIEW_FAIL = `${ACTION_TYPE} Create Interview Fail`
const actionCreateInterviewFail = (payload: any) => ({
  type: ACTION_CREATE_INTERVIEW_FAIL,
  payload,
})

/**
 * Update admin detail returned from /gethrdetails API
 * @param payload
 */
const actionSetAdminDetail = (payload: IImpressionListAPIResponse) => ({
  type: ACTION_UPDATE_ADMIN_DETAILS,
  payload,
})

/**
 * Action to get impression list, this in result is combination of
 * multiple action from init to success and fail if error.
 * @param id Admin ID
 */
export const actionGetImpressionList =
  (id: string, success: Function = () => { }, error: Function = () => { }) =>
    async (dispatch: any) => {
      dispatch(actionGetImpressionListInit(id))
      try {
        const response = await httpGetImpressionList(id)
        dispatch(actionGetImpressionListSuccess(response.impressionList, response.interviewLimit))
        dispatch(actionSetAdminDetail(response))
        success()
      } catch (ex) {
        dispatch(actionGetImpressionListFail(ex))
        error()
      }
    }
/**
 * Http call to get impression list
 * @param id Admin ID
 * @param success callack Function on success
 * @param error callback Function on error
 */
const httpGetImpressionList = async (id: string): Promise<IImpressionListAPIResponse> => {
  let temp: IImpressionListAPIResponse = {
    companyName: '',
    email: '',
    fname: '',
    impressionList: [],
    lname: '',
    interviewLimit: 0,
  }
  try {
    // TODO: Call with api

    const response = await Axios.get(ApiEndpoints.getInterviewList(id))
    const { data } = response.data

    if (data) temp = { ...data }
  } catch (e) {
    throw new Error(e.error)
  }
  return temp
}

/**
 * Create action to create new interview(s)
 * @param data {CreateBoardInterview}
 * @param success : Function, callback on success
 * @param error : Function, callback on error
 */
export const actionCreateImpression =
  (data: CreateBoardInterview, success: (ok: any) => void, error: () => void) => (dispatch: Dispatch) => {
    dispatch(actionCreateInterviewInit(data))
    Axios.post(ApiEndpoints.createInterview(), data)
      .catch((err) => {
        dispatch(actionCreateInterviewFail(err))
        error()
      })
      .then((ok: any) => {
        dispatch(actionCreateInterviewSuccess(ok))
        const res = ok?.data?.data || {}
        success({ jobId: res })

        db.collection('interviewDesc')
          .add(
            {
              'interviewId': res,
              'description': data.description,
            }
          )
          .then((docRef: any) => {
            const res = docRef?.data?.data || {}
          })
          .catch((error: any) => {
            console.error('Error adding document: ', error)
            dispatch(actionCreateInterviewFail(error))
            error()
          })
      })
  }

export const ACTION_SHARE_INTERVIEW_INIT = `${ACTION_TYPE} SHARE INTERVIEW - INIT`

export const ACTION_SHARE_INTERVIEW_SUCCESS = `${ACTION_TYPE} SHARE INTERVIEW - SUCCESS`

export const ACTION_SHARE_INTERVIEW_FAIL = `${ACTION_TYPE} SHARE INTERVIEW - FAIL`

// Share Interview ##########
/**
 * Action to call to share interview init request.
 * @param data :{ShareInterview}
 */
const actionShareInterviewInit = (data: ShareInterview) => ({
  type: ACTION_SHARE_INTERVIEW_INIT,
  payload: data,
})
/**
 * Action to call when share interview run successfully
 * @param data : any
 */
const actionShareInterviewSuccess = (data: any) => ({
  type: ACTION_SHARE_INTERVIEW_SUCCESS,
  payload: data,
})
/**
 * Action to call when share interview fails.
 * @param data : any
 */
const actionShareInterviewFail = (data: any) => ({
  type: ACTION_SHARE_INTERVIEW_FAIL,
  payload: data,
})
/**
 * Action to share interview ( Init, Success, Fail )
 * @param data : {ShareInterview}
 * @param success : Function
 * @param error : Function
 */
export const actionShareInterview =
  (data: ShareInterview, success: Function, error: Function) => (dispatch: Dispatch) => {
    dispatch(actionShareInterviewInit(data))
    Axios.post(ApiEndpoints.shareInterview(), data)
      .catch((err) => {
        dispatch(actionShareInterviewFail(err))
        error(err)
      })
      .then((ok) => {
        dispatch(actionShareInterviewSuccess(ok))
        success(ok)
      })
  }

export const ACTION_UPDATE_INTERVIEW_STATUS_INIT = `${ACTION_TYPE} Update Status Init`

export const ACTION_UPDATE_INTERVIEW_STATUS_SUCCESS = `${ACTION_TYPE} Update Status Success`

export const ACTION_UPDATE_INTERVIEW_STATUS_FAIL = `${ACTION_TYPE} Update Status Fail`

export const actionUpdateInterviewStatusInit = (payload: UpdateImpressionRequest) => ({
  type: ACTION_UPDATE_INTERVIEW_STATUS_INIT,
  payload,
})

export const actionUpdateInterviewStatusSuccess = (id: string) => ({
  type: ACTION_UPDATE_INTERVIEW_STATUS_SUCCESS,
  payload: id,
})

export const actionUpdateInterviewStatusFail = () => ({
  type: ACTION_UPDATE_INTERVIEW_STATUS_FAIL,
})

export const actionUpadteInterviewStatus =
  (payload: UpdateImpressionRequest, success: Function, error: Function) => async (dispatch: Dispatch) => {
    dispatch(actionUpdateInterviewStatusInit(payload))
    try {
      const response = await fetch(
        payload.archive ? ApiEndpoints.archiveImpression() : ApiEndpoints.closeImpression(),
        {
          body: JSON.stringify(payload),
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )

      const data = await response.json()
      const id = data?.id || ''
      dispatch(actionUpdateInterviewStatusSuccess(id))
      success()
    } catch (ex) {
      dispatch(actionUpdateInterviewStatusFail())
      error()
    }
  }
