import React from 'react'
import { Route } from 'react-router-dom'
import LazyLoadingComponent from 'shared/LazyLoadingComponent'
/**
 * ## Description
 * HOC component return passed component as using suspense API.
 * For splitting code in chunk using lazy loading routes .i.e on demand
 */
export default function LazyRoute({ path, children }: { path: string; children: React.ReactChild }) {
  return (
    <Route path={path} exact>
      <LazyLoadingComponent>{children}</LazyLoadingComponent>
    </Route>
  )
}
