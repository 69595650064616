import { createSlice } from '@reduxjs/toolkit'

const menuSlice = createSlice({
  name: 'menu',
  initialState: { option: 'all', key: 0, sortBy: '' },
  reducers: {
    filterMenu: (state, action) => {
      state.option = action.payload.option
      state.key = action.payload.key
    },
    sortBy: (state, action) => {
      state.sortBy = action.payload
    },
  },
})

export const { filterMenu, sortBy } = menuSlice.actions

export default menuSlice.reducer
