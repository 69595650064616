import React from 'react'
import Typography from '@material-ui/core/Typography'
import '../../../../node_modules/video-react/dist/video-react.css' // import css
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { interviewActions } from '../../../actions'
import { VideoWrapper, StyledBorder, StyledReRecButton, StyledBtnWrapper } from './style'

const ReviewVideo = function ({
  question,
  answer,
  id,
  setCurrRecPage,
  openRecordModal,
  currQuestion,
  expanded,
  onPanelChange,
}) {
  const handleExpand = panel => (event, isExpanded) => {
    onPanelChange(isExpanded ? panel : false)
  }
  const ReRecordBtn = ({ children, ...rest }) => <StyledReRecButton {...rest}>{children}</StyledReRecButton>
  const StyledVideo = ({ children, ...rest }) => <VideoWrapper {...rest}>{children}</VideoWrapper>
  const reRecVideo = () => {
    setCurrRecPage(id)
    console.log('rereactvideo', currQuestion)
    // _recordAgain();
    openRecordModal()
  }
  return (
    <StyledBorder>
      <Accordion expanded={expanded === id} onChange={handleExpand(id)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          style={{ boxShadow: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <span style={{ fontSize: 16, fontWeight: 600, letterSpacing: 1 }}>Show Video</span>
        </AccordionSummary>
        <AccordionDetails style={{ display: 'block' }}>
          <div>
            <div className='column -p-b-15'>
              <StyledVideo>
                <source src={answer.blobUrl} />
              </StyledVideo>
            </div>
            <StyledBtnWrapper>
              <ReRecordBtn onClick={reRecVideo}>Re-record the video</ReRecordBtn>
            </StyledBtnWrapper>
          </div>
        </AccordionDetails>
      </Accordion>
    </StyledBorder>
  )
}

const mapStateToProps = ({ questions, interviews }) => ({
  questions: questions.activeInterview,
  userQuestions: questions,
  user: questions.user,
  interviews,
})
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(interviewActions, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(ReviewVideo)
