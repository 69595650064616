import React from 'react'
import { Grid, Box, Typography, Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { KeyboardArrowDown } from '@material-ui/icons'
import capitalize from 'capitalize'
import { useSelector } from 'react-redux'
import { selectorStartImpressionPayload } from 'store/selectors'

const useStyles = makeStyles(theme => ({
  startImpressionButton: {
    width: 391,
    height: 65,
    borderRadius: 10,
    backgroundColor: '#5DCCBF',
    '&:hover': {
      backgroundColor: '#5DCCBF',
    },
    [theme.breakpoints.down('sm')]: {
      width: 300,
    },
  },
  boxInfoQuestions: {
    [theme.breakpoints.down('xs')]: {
      width: '95vw',
    },
  },
  CompanyName: {
    textAlign: 'center',
  }
}))

export default function Step2({ handleNext }) {
  const classes = useStyles()

  const startImpressionPayload = useSelector(state => selectorStartImpressionPayload(state))

  return (
    <>
      <Box display='flex' justifyContent='center' mx={1} mt={5} mb={3}>
        <Typography variant='h3' align='center'>
          Tell Us Your Story!{' '}
        </Typography>
      </Box>
      <Grid container>
        <Grid item sm={3}></Grid>
        <Grid item sm={6} style={{ fontFamily: 'Lato' }}>
          <Typography variant='body2' align='center' style={{ color: '#6D7278' }}>
            Hi there! Thank you for your interest in working at {startImpressionPayload?.companyName}. <br />
            We are very excited to learn more about you.{' '}
          </Typography>
          <Typography variant='h5' align='center' style={{ margin: '20px 0px 30px 0px' }}>
            Position{' '}
            <span style={{ color: '#5DCCBF', marginLeft: 25 }}>{capitalize.words(startImpressionPayload?.title)}</span>
          </Typography>
          <div style={{ border: '1px solid #E0E7EE', width: '90%', margin: '0px 30px 30px 30px' }} />
          <Typography variant='h4' align='center'>
            What We Are Interested In
          </Typography>

          <Grid container>
            <Grid item lg={2}></Grid>
            <Grid item sm={12} lg={8} style={{ fontFamily: 'Lato' }}>
              <Box className={classes.boxInfoQuestions} display='flex' justifyContent='space-around'>
                <span>
                  Total Questions:{' '}
                  <span style={{ color: '#5DCCBF', fontWeight: 600 }}>{startImpressionPayload?.questions.length}</span>{' '}
                </span>
                <span>
                  Estimated Time:{' '}
                  <span style={{ color: '#5DCCBF', fontWeight: 600 }}>
                    {startImpressionPayload?.questions.map(quest => quest.timeLimit).reduce((acc, cur) => acc + cur) /
                      60000}{' '}
                    min
                  </span>
                </span>
              </Box>
            </Grid>
            <Grid item lg={2}></Grid>
          </Grid>

          <br />

          <div className={classes.CompanyName}>
            <span style={{ fontSize: 18, fontWeight: 600 }}>{startImpressionPayload?.companyName}</span>
          </div>

          <br />

          {startImpressionPayload?.questions.map((question, index) => {
            return (
              <Box key={index} display='flex' justifyContent='space-between' mb={2}>
                <Grid container>
                  <Grid item xs={3} sm={2}>
                    <i className='far fa-clock' style={{ marginRight: 5, fontSize: 15, color: '#8B8F94' }}></i>
                    <span style={{ color: '#8B8F94' }}>{(question.timeLimit / 60000).toFixed(1)} m</span>
                  </Grid>
                  <Grid item xs={8} sm={9}>
                    {question.additionalInfo ? (
                      <Accordion
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                      >
                        <AccordionSummary><span style={{ fontSize: 18, fontWeight: 600 }}>{question.questionText}</span></AccordionSummary>
                        <AccordionDetails>{question.additionalInfo}</AccordionDetails>
                      </Accordion>
                    ) : (
                      <span style={{ fontSize: 18, fontWeight: 600 }}>{question.questionText}</span>
                    )}
                  </Grid>
                 {/*  <Grid item xs={1}>
                    <i className='fas fa-chevron-down'></i>
                  </Grid> */}
                </Grid>
              </Box>
            )
          })}

          <div style={{ border: '1px solid #E0E7EE', width: '90%', margin: '0px 30px 30px 30px', marginTop: 30 }} />

          <Typography variant='body1' align='left'>
            <i className='fas fa-check-square' style={{ color: '#B9BFC6', marginRight: 10 }}></i>
            <span style={{ color: '#6D7278' }}>
              After starting, you will have time to read the question and record when you’re comfortable.
            </span>
          </Typography>
          <Typography variant='body1' align='left'>
            <i className='fas fa-check-square' style={{ color: '#B9BFC6', marginRight: 10 }}></i>
            <span style={{ color: '#6D7278' }}>
              Your will be able to preview the video you filmed and restart as you wish.
            </span>
          </Typography>

          <Box display='flex' justifyContent='center' my={4} mt={5}>
            <Button onClick={handleNext} color='primary' variant='contained' className={classes.startImpressionButton}>
              I’M READY, LET’S START
            </Button>
          </Box>
        </Grid>
        <Grid item sm={3}></Grid>
      </Grid>
    </>
  )
}
