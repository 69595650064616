import { IAdminProfile } from './IUser'
import { IAuthState } from './IAuthState'
import { CreateBoardInterviewQuestions } from './CreateBoard'
import { IUserState } from './USER'

export interface INotification {
  isOpen: boolean
  severity: 'success' | 'info' | 'warning' | 'error'
  duration: number
  message: string
}

/**
 * Impression status
 */
export enum ImpressionStatus {
  active = 'active',
  closed = 'closed',
  archive = 'archive',
  none = '',
}

export enum CandidateReviewStatus {
  new = 'new',
  reviewed = 'reviewed',
  accept = 'accepted',
  reject = 'rejected',
}

/**
 * Interface for impression
 */
export interface ImpressionInterface {
  /**
   * Unique ID
   */
  jobId: string
  /**
   * created date
   */
  datetime: string
  /**
   * Number of candidates
   */
  totalImpressionCount: number
  /**
   * New candidates
   */
  newImpressionCount: number
  /**
   * Status of impression either is closed and open
   */
  status: ImpressionStatus
  /**
   * Title for impression
   */
  title: string
  /**
   * Description for impression
   * @type {string}
   * @memberof ImpressionInterface
   * @description description for impression
   * @example description for impression
   * @default description for impression
   * @readonly
   * @required
   **/
  description: string
  /**
   * Time stamp for created interview
   */
  createdDate: {
    _seconds: number
    _nanoseconds: number
  }
  /** */
  lastModifeddate: {
    _seconds: number
    _nanoseconds: number
  }

  /**
   * Status of interview
   */
  archive: boolean

  questions?: CreateBoardInterviewQuestions[]
}

/**
 * Route url(s) interface
 */
export interface IRouteUrls {
  href: string
  title: string
  icon: string
}

// Impression List API response
export interface ModifiedDate {
  _seconds: number
  _nanoseconds: number
}

export interface CreatedDate {
  _seconds: number
  _nanoseconds: number
}

export interface QuestionResponse {
  timeLimit: number
  questionId: string
  additionalInfo: string
  questionText: string
  blobUrl: string
  video: string
}

export interface ImpressionListItem {
  userID: string
  modifiedDate: ModifiedDate
  childId: string
  starred: boolean
  createdDate: CreatedDate
  candidateEmail: string
  fname: string
  tags: string
  jobId: string
  questionResponse: QuestionResponse[]
  reviewStatus: CandidateReviewStatus
  comments: string
  lname: string
}

export interface ImpressionListResponse {
  id: string
  data: ImpressionListItem[]
}

// Candidate Favorite Request Interface
export interface CandidateFavoriteRequest {
  jobId: string
  childId: string
  reviewStatus?: CandidateReviewStatus
  starred?: boolean
}

export interface DownloadAllImpression {
  jobId: string
}

// @dev interview status update request
export interface InterviewStatusRequest {
  jobId: string
  status: string
}

export interface IAdminState {
  uid: string | null
  registrationErrorMessage: string | null
  loginErrorMessage: string | null
  /**
   * Useful to show indicator based on computation or api call
   */
  loading: boolean
  loggedIn: boolean
  roles?: 'admin' | 'super-admin' | 'manager' | 'none'
  data?: IAdminProfile
  /**
   * Access level for admin
   */
  accessLevel?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
  /**
   * Impression list
   */
  impressionList: ImpressionInterface[]

  /**
   * filer status list i.e. active, closed, archive
   */
  filterStatus: ImpressionStatus[]

  /**
   * Will be to use share state for share interview dialog
   */
  shareInteviewDialogOpen: {
    isOpen: boolean
    interviewID: null | string
    navigateTo: string | null
  }
  archiveDiaolog: {
    isOpen: boolean
    jobId: string | null
  }
  viewImpressionDialog: {
    isOpen: boolean
    jobId: string | null
  }
  selectedInterview: null | ImpressionInterface
  copiedInterview: null | ImpressionInterface
  interviewLimit: number
  candidateList: { loading: boolean; list: ImpressionListItem[] }
  // Use this flag to update interview status and archive
  updateInterviewInProcess: boolean
}
export interface IAppState {
  menu: any
  admin: IAdminState
  user: IUserState
  interviews: any
  session: any
  questions: any
  auth: any
  app: IAuthState
  notification: INotification
}
