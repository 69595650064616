import React, { useState, useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { authActions } from 'actions'

import LoadingIndicator from 'shared/LoadingIndicator'
import { selectorLoginInProcess } from 'store/selectors'

// New imports
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import InputBase from '@material-ui/core/InputBase'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import { Email, LockOutlined, Visibility, VisibilityOff, PersonOutline } from '@material-ui/icons'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles(() =>
  createStyles({
    main: {
      fontSize: '13px',
    },
    title: {
      '& h6': {
        fontSize: '1em',
        textAlign: 'center',
        margin: 1,
        color: '#5DCCBF',
      },
      '& h4': {
        fontSize: '3em',
        textAlign: 'center',
        font: 'normal normal medium 60px/72px Fira Sans',
        letterSpacing: '0px',
        color: '#000000',
        textTransform: 'capitalize',
        opacity: 1,
        margin: 0,
      },
      '& p': {
        margin: 2,
        fontSize: '1em',
        textAlign: 'center',
        letterSpacing: 0,
        textTransform: 'capitalize',
        opacity: 1,
      },
    },
    fields: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    iconInputField: {
      display: 'flex',
      alignItems: 'center',
      minWidth: 230,
      margin: 10,
      backgroundColor: '#F6F6F6',
      width: '100%',
      height: 50,
      background: '#F6F6F6 0% 0% no-repeat padding-box',
      borderRadius: 8,
      opacity: 1,
      boxShadow: 'none',
    },
    textLink: {
      color: '#5DCCBF !important',
      textDecoration: 'none !important',
      fontSize: '1em',
      cursor: 'pointer',
    },
    input: {
      margin: 0,
      fontSize: '1em',
      flex: 1,
    },
    loginButtonSection: {
      display: 'flex',
      '& .loginButton': {
        display: 'flex',
        fontSize: '1em',
        flex: 1,
        backgroundColor: '#5DCCBF',
        color: '#ffffff',
        borderRadius: '8px',
        height: 50,
        textTransform: 'uppercase',
        fontWeight: 500,
      },
    },
    socialLoginButtons: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& .button': {
        display: 'flex',
        borderRadius: 8,
        border: '1px solid #999',
        fontSize: '0.65em',
        height: 50,
        margin: 'auto 5px',
        width: 200,
      },
      '@media(min-width: 450px)': {
        '& .button': { margin: 5 },
      },
    },
    createAccountLInk: {
      fontSize: '1em',
      textAlign: 'center',
      margin: '10px auto',
    },
    forgotPasswordLinkSection: {
      '& a': {
        color: '#5DCCBF !important',
        textDecoration: 'none !important',
        fontSize: '0.7em',
        cursor: 'pointer',
      },

      margin: '10px auto',
    },
  }),
)

const Signup = function ({ history, actions, error }) {
  const [email, setEmail] = useState('')

  const classes = useStyles()

  const isLoginInProcess = useSelector(state => selectorLoginInProcess(state))

  const [password, setPassword] = useState('')

  const [firstName, setFirstName] = useState('')

  const [lastName, setLastName] = useState('')

  const onChangeHandler = event => {
    const { name, value } = event.currentTarget
    if (name === 'firstName') {
      setFirstName(value)
    } else if (name === 'lastName') {
      setLastName(value)
    } else if (name === 'userEmail') {
      setEmail(value)
    } else if (name === 'userPassword') {
      setPassword(value)
    }
  }

  const createUserWithEmailAndPasswordHandler = event => {
    event.preventDefault()
    actions.createUserWithEmailAndPasswordHandler(firstName, lastName, email, password)
  }

  const [showPassword, setShowPassword] = useState(false)

  const disableButton = useMemo(
    () => email.length === 0 || password.length === 0 || firstName.length === 0 || lastName.length === 0,
    [email, password, firstName, lastName],
  )

  return (
    <section className={classes.main}>
      <section className={classes.title}>
        <h6>Primo Applicant</h6>
        <h4>Sign Up</h4>
        <p>
          Not an Applicant?{' '}
          <a href='/admin' className={classes.textLink}>
            Click here
          </a>{' '}
          to access the Recruiter platform
        </p>
      </section>
      <section className={classes.fields}>
        {/* First Name */}
        <Paper className={classes.iconInputField}>
          <IconButton aria-label='user icon' disabled>
            <PersonOutline fontSize='small' />
          </IconButton>
          <InputBase
            className={classes.input}
            type='text'
            name='firstName'
            value={firstName}
            placeholder='First Name'
            id='firstName'
            onChange={event => onChangeHandler(event)}
          />
        </Paper>

        {/* Last Name */}
        <Paper className={classes.iconInputField}>
          <IconButton aria-label='user icon' disabled>
            <PersonOutline fontSize='small' />
          </IconButton>
          <InputBase
            className={classes.input}
            type='text'
            name='lastName'
            value={lastName}
            placeholder='Last Name'
            id='lastName'
            onChange={event => onChangeHandler(event)}
          />
        </Paper>

        {/* EMail */}
        <Paper className={classes.iconInputField}>
          <IconButton className={classes.iconButton} aria-label='user icon' disabled>
            <Email fontSize='small' />
          </IconButton>
          <InputBase
            className={classes.input}
            type='email'
            name='userEmail'
            value={email}
            placeholder='Email'
            id='userEmail'
            onChange={event => onChangeHandler(event)}
            inputProps={{ 'aria-label': 'username' }}
          />
        </Paper>

        {/* Password */}
        <Paper className={classes.iconInputField}>
          <IconButton className={classes.iconButton} aria-label='password icon' disabled>
            <LockOutlined fontSize='small' />
          </IconButton>
          <InputBase
            className={classes.input}
            inputProps={{ 'aria-label': 'search google maps' }}
            type={showPassword ? 'text' : 'password'}
            name='userPassword'
            value={password}
            placeholder='Password'
            id='userPassword'
            onChange={event => onChangeHandler(event)}
          />
          <Divider className={classes.divider} orientation='vertical' />
          <IconButton
            className={classes.iconButton}
            aria-label='directions'
            onClick={() => setShowPassword(state => !state)}
          >
            {showPassword ? <Visibility fontSize='small' /> : <VisibilityOff fontSize='small' />}
          </IconButton>
        </Paper>
      </section>
      <section className={classes.loginButtonSection}>
        <LoadingIndicator
          loading={isLoginInProcess}
          children={
            <Button className='loginButton' disabled={disableButton} onClick={createUserWithEmailAndPasswordHandler}>
              Create account
            </Button>
          }
        />
      </section>
      <section className={classes.createAccountLInk}>
        Already Have An Account&nbsp;?&nbsp;
        <Link
          className={classes.textLink}
          onClick={() => {
            history.push('/auth/login')
          }}
        >
          Login
        </Link>
      </section>
    </section>
  )
}
const mapStateToProps = ({ auth }) => ({
  auth,
  error: auth.error,
})
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(authActions, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(Signup)
