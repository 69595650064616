import React, { useState, useEffect } from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import SearchIcon from '@material-ui/icons/Search'
import { InputBase } from '@material-ui/core'

import LazyLoadingComponent from 'shared/LazyLoadingComponent'
import CircularProgress from '@material-ui/core/CircularProgress'

import { ContentContainer } from 'components/Admin/admin-common.style'

import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import SelectOrderBy from './SelectOrderBy'
import { sortTypes } from './SortTypes'
import { screenCandidateBackgroundColor } from 'common.style'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import json2mq from 'json2mq'
import { paginateFromArray } from 'utils'
import { useHistory } from 'react-router-dom'

// Redux
import { connect, useSelector, useDispatch } from 'react-redux'
import {
  actionGetUserProfile,
} from 'store/actions'
import {
  selectorRequestUserInProcess,
  selectorLoggedInID,
} from 'store/selectors'

// Models Typescript
import { UserImpressionList } from 'models/USER'
import { IAppState } from 'models/IAppState'

// Css
import useStyles from './useStyles'

const BoardUI = React.lazy(() => import('components/BoardUICandidate'))

/**
 * Impression List Component
 */
const InterviewBuilder = React.memo(({ auth }: { auth: any }) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const history = useHistory()

  const sortByDateFn = (list: UserImpressionList[]) =>
    list.slice(0).sort((a, b) => {
      if (a.createdDate._seconds > b.createdDate._seconds) return -1
      if (a.createdDate._seconds < b.createdDate._seconds) return 1
      return 0
    })
  console.log('Res es esto: ', sortByDateFn)
  const [listToDisplay, setListToDisplay] = useState<UserImpressionList[]>([])

  const userID = useSelector((state: IAppState) => selectorLoggedInID(state))
  console.log('userID: ', userID)

  useEffect(() => {
    if (auth && auth.user) {
      dispatch(
        actionGetUserProfile(auth.user.uid, list => {
          if (list && auth.user.uid) {
            setListToDisplay(paginateFromArray(sortByDateFn(list), 1, 10))
          }
        }),
      )
    }
  }, [auth, dispatch, userID])

  // Interview Builder original
  const isMobile = useMediaQuery(
    json2mq({
      maxWidth: 680,
    }),
  )

  const isTablet = useMediaQuery(
    json2mq({
      maxWidth: 960,
    }),
  )

  const [error, setError] = useState(false)

  const isAPICallRunning = useSelector((state: IAppState) => selectorRequestUserInProcess(state))

  // Selectors with slice: redux-toolkit
  const { menu } = useSelector((state: IAppState) => state)

  const [searchValue, setSearchValue] = useState('')

  const handleSearchImpressions = (event: any) => {
    const { target } = event
    setSearchValue(target.value)
  }

  return (
    <ContentContainer style={{ backgroundColor: isMobile ? screenCandidateBackgroundColor : 'white' }}>
      {error ? (
        <>
          <Typography className={classes.serverError} variant='h3' align='center'>
            Something went wrong, please reload the page.
          </Typography>
          {/* <img width='80%' alt='server-error' src={ServerErrorImage} /> */}
        </>
      ) : isAPICallRunning ? (
        <CircularProgress />
      ) : (
        <>
          <section>
            <Fab
              className={classes.fab}
              // onClick={startImpression}
              onClick={() => history.push('/interview')}
              aria-label='add'
            >
              <AddIcon className={classes.addIcon} fontSize={isTablet ? 'default' : 'large'} />
            </Fab>

            <>
              {' '}
              <section>
                {isAPICallRunning ? (
                  <Grid container spacing={2}>
                    <CircularProgress />
                  </Grid>
                ) : (
                  <>
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                      <div style={{ minWidth: 280 }} className={classes.divBeforeSearch}></div>
                      <div className={classes.search}>
                        <div className={classes.searchIcon}>
                          <SearchIcon style={{ fontSize: 24 }} />
                        </div>
                        <InputBase
                          placeholder='Search an impression'
                          classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                          }}
                          inputProps={{ 'aria-label': 'search' }}
                          onChange={handleSearchImpressions}
                          value={searchValue}
                        />
                      </div>

                      <SelectOrderBy />
                    </div>

                    <Grid container spacing={4}>
                      {listToDisplay ? (
                        listToDisplay
                          .filter((item: any) => {
                            return item.title.toLowerCase().includes(searchValue.toLowerCase())
                          })
                          .sort(sortTypes[menu.sortBy || 'default'])
                          .map((item: any, index: number) => {
                            return (
                              <Grid
                                key={index}
                                // onClick={handleClick}
                                data-id={item.jobId}
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                              >
                                <LazyLoadingComponent>
                                  <BoardUI
                                    title={item.title}
                                    description={item.description}
                                    createdDate={item?.createdDate?._seconds}
                                    candidateCount={item.totalImpressionCount}
                                    status={item.status}
                                    newCandidateCount={item.newImpressionCount}
                                    jobId={item.jobId}
                                  />
                                </LazyLoadingComponent>
                              </Grid>
                            )
                          })
                      ) : (
                        <h1>No results</h1>
                      )
                      }
                    </Grid>
                  </>
                )}
              </section>
            </>
          </section>
        </>
      )}
    </ContentContainer>
  )
})

const mapStateToProps = ({ auth }: { auth: any }) => ({
  auth,
})

export default connect(mapStateToProps, null)(InterviewBuilder)
