import { IAdminState, ImpressionStatus, ImpressionInterface } from 'models/IAppState'
import { IFirebaseObject } from 'models/IUser'
import {
  ACTION_GET_IMPRESSION_LIST_INIT,
  ACTION_GET_IMPRESSION_LIST_SUCCESS,
  ACTION_GET_IMPRESSION_LIST_FAIL,
  ACTION_CREATE_INTERVIEW_INIT,
  ACTION_CREATE_INTERVIEW_SUCCESS,
  ACTION_CREATE_INTERVIEW_FAIL,
  ACTION_SHARE_INTERVIEW_INIT,
  ACTION_SHARE_INTERVIEW_SUCCESS,
  ACTION_SHARE_INTERVIEW_FAIL,
  ACTION_GET_CANDIDATE_LIST_INIT,
  ACTION_GET_CANDIDATE_LIST_FAIL,
  ACTION_GET_CANDIDATE_LIST_SUCCESS,
  ACTION_UPDATE_INTERVIEW_STATUS_INIT,
  ACTION_UPDATE_INTERVIEW_STATUS_SUCCESS,
  ACTION_UPDATE_INTERVIEW_STATUS_FAIL,
} from 'store/actions'
import { IImpressionListAPIResponse, IUpdateAdminRequest } from 'models/API'
import {
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGOUT,
  ADMIN_LOGIN_INIT,
  ADMIN_CREATION_SUCCESS,
  ADMIN_CREATION_FAIL,
  ADMIN_CREATE_INIT,
  ACTION_UPDATE_ADMIN_DETAILS,
  ADMIN_SHARE_INTERVIEW_DIALOG_OPEN,
  ADMIN_SHARE_INTERVIEW_DIALOG_CLOSE,
  ADMIN_ACTION_SHOW_ARCHIVE_DIALOG,
  ADMIN_ACTION_HIDE_ARCHIVE_DIALOG,
  ADMIN_ACTION_SHOW_VIEW_IMPRESSION_DIALOG,
  ADMIN_ACTION_HIDE_VIEW_IMPRESSION_DIALOG,
  ADMIN_ACTION_UNSELECT_INTERVIEW,
  ADMIN_ACTION_SELECT_INTERVIEW,
  ADMIN_ACTION_COPY_SELECTED_INTERVIEW,
  ADMIN_ACTION_REMOVE_COPY_SELECTED_INTERVIEW,
  ADMIN_ACTION_UPDATE_FNAME_LNAME_INIT,
  ADMIN_ACTION_UPDATE_FNAME_LNAME_SUCCESS,
  ADMIN_ACTION_UPDATE_FNAME_LNAME_FAIL,
  ADMIN_ACTION_ADMIN_PASSWORD_UPDATE_INIT,
  ADMIN_ACTION_ADMIN_PASSWORD_UPDATE_SUCCESS,
  ADMIN_ACTION_ADMIN_PASSWORD_UPDATE_FAIL,
} from '../types'

/**
 * JSON object stored in localStorage on successful admin login
 */
const admin = JSON.parse(localStorage.getItem('admin') || '{}')
/**
 * Initial state of admin
 */
const initialState: IAdminState = {
  loginErrorMessage: '',
  uid: null,
  accessLevel: 0,
  roles: 'none',
  loggedIn: false,
  loading: false,
  registrationErrorMessage: null,
  data: {
    fname: '',
    companyName: '',
    email: '',
    lname: '',
    createdDate: undefined,
    modifiedDate: undefined,
    profile: undefined,
  },
  impressionList: [],
  filterStatus: [ImpressionStatus.active, ImpressionStatus.closed],
  shareInteviewDialogOpen: {
    isOpen: false,
    interviewID: null,
    navigateTo: null,
  },
  archiveDiaolog: {
    isOpen: false,
    jobId: null,
  },
  viewImpressionDialog: {
    isOpen: false,
    jobId: null,
  },
  selectedInterview: null,
  copiedInterview: null,
  interviewLimit: 0,
  candidateList: {
    list: [],
    loading: false,
  },
  updateInterviewInProcess: false,
}

/**
 *
 * @param state `IAdminState`
 * @param action `{ type: admin-action, payload: any }`
 */
const adminReducer = (
  state: IAdminState = {
    ...JSON.parse(JSON.stringify(initialState)),
    ...admin,
  },
  action: any,
): IAdminState => {
  switch (action.type) {
    case ADMIN_LOGIN_INIT: {
      return {
        ...state,
        loading: true,
        loginErrorMessage: null,
        registrationErrorMessage: null,
      }
    }
    case ADMIN_CREATE_INIT: {
      return {
        ...state,
        loading: true,
        loginErrorMessage: null,
        registrationErrorMessage: null,
      }
    }
    case ADMIN_CREATION_SUCCESS: {
      const {
        profile,
        companyName,
        firstName,
        lastName,
      }: {
        profile: IFirebaseObject
        firstName: string
        lastName: string
        companyName: string
      } = action.payload
      const updateState: IAdminState = {
        ...state,
        uid: profile.uid,
        data: {
          ...state.data,
          profile: { ...profile },
          email: profile.email,
          createdDate: profile.createdAt,
          companyName,
          fname: firstName,
          lname: lastName,
        },
        loggedIn: true,
        loading: false,
      }
      localStorage.setItem('admin', JSON.stringify(updateState))
      return { ...updateState }
    }
    case ADMIN_LOGIN_SUCCESS: {
      const profile = action.payload as IFirebaseObject
      const updateState: IAdminState = {
        ...state,
        uid: profile.uid,
        data: {
          ...state.data,
          profile,
          email: profile.email,
          createdDate: profile.createdAt,
        },
        loggedIn: true,
        loading: false,
      }
      localStorage.setItem('admin', JSON.stringify(updateState))
      return { ...updateState }
    }
    case ADMIN_CREATION_FAIL: {
      let { message } = action.payload
      if (!message) message = 'Admin registration failed'
      return {
        ...state,
        registrationErrorMessage: message,
        loading: false,
      }
    }
    case ADMIN_LOGIN_FAIL: {
      let { message } = action.payload
      if (!message) message = 'Admin login failed'
      return {
        ...state,
        loginErrorMessage: message,
        loading: false,
      }
    }
    case ADMIN_LOGOUT: {
      localStorage.removeItem('admin')
      return {
        ...JSON.parse(JSON.stringify(initialState)),
      }
    }
    case ACTION_GET_IMPRESSION_LIST_INIT: {
      return {
        ...state,
        loading: true,
      }
    }
    case ACTION_GET_IMPRESSION_LIST_SUCCESS: {
      const list = action?.payload?.map((item: ImpressionInterface) => item) || []
      const interviewLimit = action?.interviewLimit || 0
      return {
        ...state,
        loading: false,
        impressionList: list.slice(),
        interviewLimit,
      }
    }
    case ACTION_GET_IMPRESSION_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case ACTION_CREATE_INTERVIEW_INIT: {
      return { ...state, loading: true }
    }
    case ACTION_CREATE_INTERVIEW_SUCCESS: {
      return { ...state, loading: false }
    }
    case ACTION_CREATE_INTERVIEW_FAIL: {
      return { ...state, loading: false }
    }
    case ACTION_UPDATE_ADMIN_DETAILS: {
      const data: IImpressionListAPIResponse = action.payload
      return {
        ...state,
        data: {
          ...state.data,
          companyName: data.companyName,
          email: data.email,
          fname: data.fname,
          lname: data.lname,
        },
      }
    }
    // Share interview
    case ADMIN_SHARE_INTERVIEW_DIALOG_OPEN: {
      const ID = action.payload.id
      const { route } = action.payload
      return {
        ...state,
        shareInteviewDialogOpen: {
          isOpen: true,
          interviewID: ID,
          navigateTo: route,
        },
      }
    }
    case ADMIN_SHARE_INTERVIEW_DIALOG_CLOSE: {
      return {
        ...state,
        shareInteviewDialogOpen: {
          isOpen: false,
          interviewID: null,
          navigateTo: null,
        },
      }
    }

    case ACTION_SHARE_INTERVIEW_INIT: {
      return {
        ...state,
        loading: true,
      }
    }
    case ACTION_SHARE_INTERVIEW_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case ACTION_SHARE_INTERVIEW_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    // Archive Dialog
    case ADMIN_ACTION_SHOW_ARCHIVE_DIALOG: {
      const jobId = action.payload
      return {
        ...state,
        archiveDiaolog: {
          isOpen: true,
          jobId,
        },
      }
    }
    case ADMIN_ACTION_HIDE_ARCHIVE_DIALOG: {
      return {
        ...state,
        archiveDiaolog: { isOpen: false, jobId: null },
      }
    }
    // View Impression Dialog
    case ADMIN_ACTION_SHOW_VIEW_IMPRESSION_DIALOG: {
      const jobId = action.payload
      const selectedInterview = state.impressionList.find((item) => item.jobId === jobId)
      return {
        ...state,
        viewImpressionDialog: {
          jobId,
          isOpen: true,
        },
        selectedInterview: selectedInterview || null,
      }
    }
    case ADMIN_ACTION_HIDE_VIEW_IMPRESSION_DIALOG: {
      const jobId = null

      return {
        ...state,
        viewImpressionDialog: {
          jobId,
          isOpen: false,
        },
      }
    }

    // Select Interview
    case ADMIN_ACTION_SELECT_INTERVIEW: {
      const id = action.payload
      const selectedInterview = state.impressionList.find((item) => item.jobId === id)
      return {
        ...state,
        selectedInterview: selectedInterview || null,
      }
    }
    /// Unselect Interview
    case ADMIN_ACTION_UNSELECT_INTERVIEW: {
      const selectedInterview = null
      return { ...state, selectedInterview }
    }

    // Copy  Interview
    case ADMIN_ACTION_COPY_SELECTED_INTERVIEW: {
      const id = action.payload
      const copiedInterview = state.impressionList.find((item) => item.jobId === id)
      return {
        ...state,
        copiedInterview: copiedInterview || null,
      }
    }
    /// Remove Copied Interview
    case ADMIN_ACTION_REMOVE_COPY_SELECTED_INTERVIEW: {
      const copiedInterview = null
      return { ...state, copiedInterview }
    }

    // Candidate Listing

    case ACTION_GET_CANDIDATE_LIST_INIT: {
      return {
        ...state,
        candidateList: {
          ...state.candidateList,
          loading: true,
        },
      }
    }

    case ACTION_GET_CANDIDATE_LIST_SUCCESS: {
      const list = action.payload.data
      return {
        ...state,
        candidateList: {
          loading: false,
          list,
        },
      }
    }

    case ACTION_GET_CANDIDATE_LIST_FAIL: {
      return {
        ...state,
        candidateList: {
          loading: false,
          list: [],
        },
      }
    }

    // Update First & Last Name

    case ADMIN_ACTION_UPDATE_FNAME_LNAME_INIT: {
      return {
        ...state,
        loading: true,
      }
    }

    case ADMIN_ACTION_UPDATE_FNAME_LNAME_SUCCESS: {
      const data: IUpdateAdminRequest = action.payload
      let admin = JSON.parse(JSON.stringify(state.data))
      admin = { ...admin, fname: data.fname, lname: data.lname }
      return { ...state, data: admin, loading: false }
    }

    case ADMIN_ACTION_UPDATE_FNAME_LNAME_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }

    // Update Interview status and archive

    case ACTION_UPDATE_INTERVIEW_STATUS_INIT: {
      return { ...state, updateInterviewInProcess: true }
    }

    case ACTION_UPDATE_INTERVIEW_STATUS_SUCCESS: {
      return { ...state, updateInterviewInProcess: false }
    }
    case ACTION_UPDATE_INTERVIEW_STATUS_FAIL: {
      return { ...state, updateInterviewInProcess: false }
    }

    // ADMIN PASSWORD UPDATE

    case ADMIN_ACTION_ADMIN_PASSWORD_UPDATE_INIT: {
      return { ...state, loading: true }
    }

    case ADMIN_ACTION_ADMIN_PASSWORD_UPDATE_SUCCESS: {
      return { ...state, loading: false }
    }

    case ADMIN_ACTION_ADMIN_PASSWORD_UPDATE_FAIL: {
      return { ...state, loading: false }
    }

    default: {
      return { ...state }
    }
  }
}

export default adminReducer
