import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import rootReducer from './reducers'

const middleware = applyMiddleware(thunkMiddleware)

const enhancers = [middleware]
const composedEnhancers: any =
  process.env.NODE_ENV !== 'production' ? composeWithDevTools(...enhancers) : compose(...enhancers)

const store = createStore(rootReducer, composedEnhancers)

export default store
