import styled from 'styled-components'

export const AdminSignInPageHeading = styled.h1`
  font-weight: bold;
  font-size: 2.3em;
  text-transform: capitalize;
  margin:0 auto 10px;
`
export const AdminSignInPageHeadingParagraph = styled.p`
  font-weight: 400;
  margin: 5px auto;
  font-size: 0.82em;
  color:white;
`
