import { css } from 'styled-components'

export const PrimaryColor = css`
  color: #007efd;
`
export const LinkColor = css`
  a {
    ${PrimaryColor};
    cursor: pointer;
  }
`

export const TextCenter = css`
  text-align: center;
`

export const TextRight = css`
  text-align: right;
`

export const SmallFontSize = css`
  font-size: 0.62em;
`

export const TextCapital = css`
  text-transform: capitalize;
`
