import React, { useState } from 'react'
import { Grid, Box, Typography, Button, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PaperQuestion from './PaperQuestion'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectorStartImpressionJobId,
  selectorStartImpressionQuestions,
  selectorSubmitImpressionInProcess,
  selectorUploadingVideoInProcess,
  selectorUserEmail,
  selectorUserFirstName,
  selectorUserID,
  selectorUserLastName,
  selectorStartImpressionPayload,
} from 'store/selectors'
import RecordModal from 'components/RecordModal'
import { getOptionFromValueQuestionTimeLimit } from 'utils'
import ReviewAnswers from '../ReviewAnswers'
import ReviewVideo from './ReviewVideo'
import { actionSubmitImpression, actionUploadToFirebase } from 'store/actions'

const useStyles = makeStyles(theme => ({
  boxCamera: {
    backgroundColor: '#DEF5F2',
    width: '100%',
    height: 464,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      height: 260,
    },
  },
  boxContainerQuestions: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    justifyContent: 'center',
    marginLeft: 40,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0,
      padding: 0,
      width: 'calc(100vw - 21px)',
    },
  },
}))

export default function Step3({ handleNext }) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const startImpressionPayload = useSelector(state => selectorStartImpressionPayload(state))

  const questions = useSelector(state => selectorStartImpressionQuestions(state))
  const submissionInProcess = useSelector(state => selectorSubmitImpressionInProcess(state))
  const uploadInProcess = useSelector(state => selectorUploadingVideoInProcess(state))

  const userId = useSelector(state => selectorUserID(state))
  const fname = useSelector(state => selectorUserFirstName(state))
  const lname = useSelector(state => selectorUserLastName(state))
  const email = useSelector(state => selectorUserEmail(state))
  const jobId = useSelector(state => selectorStartImpressionJobId(state))

  const [page, setPage] = useState(0)

  const [currQuestion, setCurrQuestion] = useState(0)
  const [answers, setAnswers] = useState([])
  const [recordModal, setRecordModal] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const openRecordModal = () => {
    setRecordModal(true)
  }

  const closeRecordModal = () => {
    if (answers.length > currQuestion && answers.length !== currQuestion + 1) {
      setCurrQuestion(currQuestion + 1)
    }
    setRecordModal(false)
  }

  const _addAnswer = ev => {
    const currQuest = questions[currQuestion]
    const blobUrl = URL.createObjectURL(ev)
    if (answers[currQuestion]) {
      const newAnswers = [...answers]
      newAnswers[currQuestion].video = ev
      newAnswers[currQuestion].blobUrl = blobUrl
      setAnswers(newAnswers)
    } else {
      setAnswers(
        answers.concat({
          ...currQuest,
          video: ev,
          blobUrl,
        }),
      )
    }
  }

  const _nextQuestion = () => {
    closeRecordModal()
    if (page < answers.length) {
      setPage(p => p + 1)
    }

    if (answers.length < questions.length) {
      setCurrQuestion(currQuestion + 1)
    }
  }

  const setCurrRecPage = id => {
    setCurrQuestion(id)
  }

  const _recordAgain = () => {
    if (answers.length === questions.length) {
      const newAnswers = answers.filter((_, id) => id !== currQuestion)
      setAnswers(newAnswers)
    } else {
      answers.pop()
    }
  }

  const submitImpression = async () => {
    if (!jobId) return
    setSubmitLoading(true)

    try {
      dispatch(
        actionUploadToFirebase(
          {
            answers,
            jobId,
            userId,
          },
          data => {
            dispatch(
              actionSubmitImpression(
                {
                  candidateEmail: email,
                  fname,
                  lname,
                  jobId,
                  questionResponse: data,
                  userId,
                },
                () => {
                  // history.replace('/done')
                  console.log('videos saved!')
                  setSubmitLoading(false)
                  handleNext()
                },
                () => {
                  setSubmitLoading(false)
                  history.replace('/home')
                  console.log('error')
                },
              ),
            )
          },
          () => {},
        ),
      )
    } catch (ex) {
      console.log(ex)
    }
  }

  const timeLimit = questions.length > 0 ? getOptionFromValueQuestionTimeLimit(questions[currQuestion].timeLimit) : 0

  const [expanded, setExpanded] = React.useState(0)
  const handleExpand = panel => {
    setExpanded(panel)
  }
  return (
    <>
      <Box mt={4} display='flex' justifyContent='flex-start' alignItems='center'>
        <Typography style={{ fontSize: 22, fontWeight: 600 }}>Start Your Impression</Typography>
        <Typography style={{ color: '#6D7278', marginLeft: 35, fontSize: 14 }}>
          The practice room is designed for you to get comfortable with the camera and the questions
        </Typography>
      </Box>
      <br />
      <Grid container>
        <Grid item xs={12} sm={7} style={{ marginBottom: 15 }}>
          <Box className={classes.boxCamera}>
            <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
              <Button
                onClick={openRecordModal}
                variant='contained'
                style={{ backgroundColor: '#5DCCBF', borderRadius: 50, height: 90, width: 90 }}
              >
                <i className='fas fa-video' style={{ fontSize: 30, color: 'white' }}></i>
              </Button>
              <Typography
                onClick={openRecordModal}
                style={{ color: '#5DCCBF', marginTop: 10, fontSize: 13, fontWeight: 600 }}
              >
                START WITH CAMERA
              </Typography>
              <Typography
                onClick={openRecordModal}
                style={{
                  color: '#5DCCBF',
                  marginTop: 10,
                  fontSize: 18,
                  fontWeight: 600,
                }}
              >
                {questions[currQuestion].questionText}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={5}>
          <Box className={classes.boxContainerQuestions}>
            {questions.map((question, index) => {
              return (
                <Box key={index} mb={2} minWidth={300}>
                  <PaperQuestion>
                    <Box style={{ display: 'flex', justifyContent: 'space-between' }} py={2} px={2}>
                      <div style={{ display: 'flex' }}>
                        <div
                          style={{
                            height: 25,
                            width: 25,
                            borderRadius: 50,
                            backgroundColor: currQuestion === index ? '#5DCCBF' : '#B9BFC6',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'white',
                          }}
                        >
                          {index + 1}
                        </div>
                        <span
                          style={{
                            marginLeft: 10,
                          }}
                        >
                          {question.questionText}
                        </span>
                      </div>
                      <div style={{ minWidth: 65 }}>
                        <i className='far fa-clock' style={{ marginRight: 5, fontSize: 15, color: '#8B8F94' }}></i>
                        <span style={{ color: '#8B8F94' }}>{(question.timeLimit / 100000).toFixed(1)} m</span>
                      </div>
                    </Box>
                    {answers.length > index && (
                      <ReviewVideo
                        key={index}
                        answer={answers[index]}
                        id={index}
                        setCurrRecPage={setCurrRecPage}
                        currQuestion={currQuestion}
                        openRecordModal={openRecordModal}
                        _recordAgain={_recordAgain}
                        question={questions}
                        expanded={expanded}
                        onPanelChange={handleExpand}
                      />
                    )}
                  </PaperQuestion>
                </Box>
              )
            })}
          </Box>

          {submitLoading && (
            <Box display='flex' justifyContent='center' mt={2}>
              <CircularProgress style={{ color: '#5DCCBF' }} />
            </Box>
          )}

          {answers.length === questions.length && !submitLoading && (
            <Box display='flex' justifyContent='center' mt={2}>
              <Button
                variant='contained'
                style={{ backgroundColor: '#5DCCBF', color: 'white' }}
                onClick={submitImpression}
              >
                Submit
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
      <RecordModal
        open={recordModal}
        closeModal={closeRecordModal}
        questions={questions}
        _addAnswer={_addAnswer}
        currQuestion={currQuestion}
        _nextQuestion={_nextQuestion}
      />
    </>
  )
}
