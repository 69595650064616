import styled from 'styled-components'

export const StyledContainer = styled.section`
  margin: 0 auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const StyledLayout = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`
