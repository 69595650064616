import React from 'react'
import { useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import json2mq from 'json2mq'
import { AdminCompanyLogosContainer } from 'shared/AuthStyle/style'
import LazyLoadingComponent from 'shared/LazyLoadingComponent'
import p3 from '../../resource/phase-3-logo.png'
import ptg from '../../resource/ptg.png'
import fusedigital from '../../resource/fuse-digital.png'

import primoLogo from '../../resource/primo-logo-second.png'
import { createStyles, makeStyles } from '@material-ui/core'
import { AdminSignInPageHeading, AdminSignInPageHeadingParagraph } from 'styles/admin-sign-inpage'
import { PrimoHeading } from 'styles/admin-index-page'

/**
 * Always import components at end, which need to be lazy loaded.
 */

const Auth = React.lazy(() => import('./stateless/Auth/Auth'))

export interface IStyledBtn {
  children: any

  [Key: string]: any
}

const useStyles = makeStyles(() =>
  createStyles({
    companyLogo: {
      margin: '30px 40px 0',
      '& img': {
        height: 50,
      },
    },
    container: {
      height: 'calc(100vh - 80px)',
      paddingBottom: 80,
      display: 'flex',
      margin: '20px auto',
      flexDirection: (props: any) => (props.isMobile ? 'column' : 'row'),
    },
    gridContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      fontSize: '1em',
    },
    content: {
      color: 'white',
      margin: 'auto',
      maxWidth: '403px',
      minHeight: '410px',
    },
    loginContainer: {
      backgroundColor: '#fefefe',
      borderRadius: '10px',
      padding: '10px',
      maxWidth: '403px',
      // minHeight: '400px', Removed because it doesnt allow expand the container
      margin: 'auto',
    },
  }),
)

const CompanyLogo = function () {
  const classes = useStyles({})
  return (
    <section className={classes.companyLogo}>
      <img src={primoLogo} alt='Primo Logo' />
    </section>
  )
}

const SignUpContainer = function (props: any) {
  return (
    <section className={props.classes.loginContainer}>
      <PrimoHeading>Primo Admin</PrimoHeading>
      <LazyLoadingComponent>
        <Auth path={props.param} />
      </LazyLoadingComponent>
    </section>
  )
}

export default function Admin(props: any) {
  const { path } = useParams<{ path: string }>()
  const paths = {
    register: 'register',
    login: 'login',
  }

  const param = path && path.toLowerCase() === paths.register ? paths.register : paths.login

  const isMobile = useMediaQuery(
    json2mq({
      maxWidth: 768,
    }),
  )

  const classes = useStyles({ isMobile })

  const LovedByAndLogo = () => (
    <>
      <AdminSignInPageHeadingParagraph>Loved by Hiring Managers & Candidates from</AdminSignInPageHeadingParagraph>

      <AdminCompanyLogosContainer>
        <img alt='PTG' src={ptg} />
        <img alt='P3' src={p3} />
        <img alt='Fuse Digital' src={fusedigital} />
      </AdminCompanyLogosContainer>
    </>
  )

  const Introduction = () => (
    <section className={classes.content}>
      <AdminSignInPageHeading>Find the perfect match for your vacant position.</AdminSignInPageHeading>
      <AdminSignInPageHeadingParagraph>
        Primo Impression is helping recruiters to cut through the clutter of resumes by providing an easy to use, video
        interview platform. Create your custom impression and share the link with your applicants directly on your job
        posting.
      </AdminSignInPageHeadingParagraph>
      <AdminSignInPageHeadingParagraph>Loved by Hiring Managers & Candidates from</AdminSignInPageHeadingParagraph>

      <AdminCompanyLogosContainer>
        <img alt='PTG' src={ptg} />
        <img alt='P3' src={p3} />
        <img alt='Fuse Digital' src={fusedigital} />
      </AdminCompanyLogosContainer>
    </section>
  )

  return (
    <>
      <CompanyLogo />
      {isMobile ? (
        <section className={classes.container}>
          <SignUpContainer classes={classes} param={param} />
          <LovedByAndLogo />
        </section>
      ) : (
        <Grid container className={classes.container} alignItems={'center'} justify={'center'}>
          <Grid item xs={12} md={6}>
            <Introduction />
          </Grid>
          <Grid item xs={12} md={6}>
            <SignUpContainer classes={classes} param={param} />
          </Grid>
        </Grid>
      )}
    </>
  )
}
