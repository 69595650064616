import { userConstants } from '../../constants/types'
// TODO: Review later on.
const notFoundJSON = null

const initialState = {
  loggedIn: JSON.parse(localStorage.getItem('loggedIn')!),
  email: JSON.parse(localStorage.getItem('user')!)?.email,
  error: null,
  user: JSON.parse(localStorage.getItem('user')!),
}

export const authentication = (state = { ...initialState }, action: any) => {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
        user: action.user,
      }
    case userConstants.LOGIN_SUCCESS:
      const user = action.payload
      return {
        ...state,
        loggedIn: true,
        user,
        email: user.email,
      }
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        type: userConstants.LOGIN_FAILURE,
        error: action.response,
      }
    case userConstants.LOGOUT:
      return {
        ...state,
        loggedIn: notFoundJSON,
        user: notFoundJSON,
      }

    case userConstants.REGISTER_SUCCESS:
      return {
        ...state,
        loggedIn: JSON.parse(localStorage.getItem('loggedIn')!),
        user: JSON.parse(localStorage.getItem('user')!),
      }
    case userConstants.REGISTER_FAILURE:
      console.log('suerconst', action.response)
      return {
        ...state,
        error: action.response,
      }
    case userConstants.REGISTER_REQUEST:
      return {
        ...state,
        loggedIn: true,
        user: action.response,
        email: action.response,
      }

    default:
      return state
  }
}

export function registration(state = initialState, action: any) {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return { registering: true }
    case userConstants.REGISTER_SUCCESS:
      return {
        loggedIn: true,
        auth: action.response,
      }
    case userConstants.REGISTER_FAILURE:
      return {
        error: action.response,
      }
    default:
      return state
  }
}
