const adminLogin = '[ADMIN LOGIN]'
const adminCreate = '[ADMIN REGISTRATION]'
const adminLogout = '[ADMIN LOGOUT]'
const admin = '[ADMIN]'

// Admin login
/**
 * Action type: Admin login init
 */
export const ADMIN_LOGIN_INIT = `${adminLogin} Init`
/**
 * Action type: Admin login success
 */
export const ADMIN_LOGIN_SUCCESS = `${adminLogin} Success`
/**
 * Action type: Admin login fail
 */
export const ADMIN_LOGIN_FAIL = `${adminLogin} Fail`

// Admin creation
/**
 * Action type: Admin create init
 */
export const ADMIN_CREATE_INIT = `${adminCreate} Init`
/**
 * Action type: Admin create success
 */
export const ADMIN_CREATION_SUCCESS = `${adminCreate} Success`
/**
 * Action type: Admin creation fail
 */
export const ADMIN_CREATION_FAIL = `${adminCreate} Fail`

// Admin logout
/**
 * Action type: Admin logged out
 */
export const ADMIN_LOGOUT = `${adminLogout}`

/**
 * Sharing interview invites
 */

/** Action type, share interview dialog open */
export const ADMIN_SHARE_INTERVIEW_DIALOG_OPEN = `${admin} Share Interview Dialog Open`

/** Action type, share interview dialog close */
export const ADMIN_SHARE_INTERVIEW_DIALOG_CLOSE = `${admin} Share Interview Dialog Close`

/** Action type, interview invite request init */
export const ADMIN_SHARE_INTERVIEW_SEND_INIVITE_INIT = `${admin} Interview Invite Initiate Request`

/** Action type, interview invite sent successfully */
export const ADMIN_SHARE_INTERVIEW_SEND_INIVITE_SUCCESS = `${admin} Interview Invitation Sent Successfully`

/** Action type, interview invite failed */
export const ADMIN_SHARE_INTERVIEW_SEND_INIVITE_FAIL = `${admin} Interview Invitation Fail`

/**
 * Not to use outside this file.
 */
const ADMIN = '[ADMIN]'

/**
 * Action type for get impression list fetch init
 */
export const ACTION_TYPE_ADMIN_GET_IMPRESSION_LIST_INIT = `${ADMIN} Get Impression List Fetch Init`

/**
 * Action type for get impression list fetch success
 */
export const ACTION_TYPE_ADMIN_GET_IMPRESSION_LIST_SUCCESS = `${ADMIN} Get Impression List Fetch Success`
/**
 * Action type for get impression list fetch fail
 */
export const ACTION_TYPE_ADMIN_GET_IMPRESSION_LIST_FAIL = `${ADMIN} Get Impression List Fetch Fail`
/**
 * Action to update admin details in application state, which is return from /getrhrdetails API call
 */
export const ACTION_UPDATE_ADMIN_DETAILS = `${ADMIN} Update application state with admin details`

export const ADMIN_ACTION_SHOW_ARCHIVE_DIALOG = `${ADMIN} Show Archive Dialog`

export const ADMIN_ACTION_HIDE_ARCHIVE_DIALOG = `${ADMIN} Hide Archive Dialog`

export const ADMIN_ACTION_SHOW_VIEW_IMPRESSION_DIALOG = `${ADMIN} Show View Impression Dialog`

export const ADMIN_ACTION_HIDE_VIEW_IMPRESSION_DIALOG = `${ADMIN} Hide View Impression Dialog`

export const ADMIN_ACTION_SELECT_INTERVIEW = `${ADMIN} Select Interview`

export const ADMIN_ACTION_UNSELECT_INTERVIEW = `${ADMIN} UnSelect Interview`

export const ADMIN_ACTION_COPY_SELECTED_INTERVIEW = `${ADMIN} Copy Selected Interview`

export const ADMIN_ACTION_REMOVE_COPY_SELECTED_INTERVIEW = `${ADMIN} Remove Copied Interview`

export const ADMIN_ACTION_UPDATE_FNAME_LNAME_INIT = `${ADMIN} UPDATE ADMIN PROFILE INIT`

export const ADMIN_ACTION_UPDATE_FNAME_LNAME_SUCCESS = `${ADMIN} UPDATE ADMIN PROFILE SUCCESS`

export const ADMIN_ACTION_UPDATE_FNAME_LNAME_FAIL = `${ADMIN} UPDATE ADMIN PROFILE FAIL`

// UPDATE ADMIN PASSWORD

export const ADMIN_ACTION_ADMIN_PASSWORD_UPDATE_INIT = `${ADMIN} ACCOUNT PASSWORD UPDATE INIT`

export const ADMIN_ACTION_ADMIN_PASSWORD_UPDATE_SUCCESS = `${ADMIN} ACCOUNT PASSWORD UPDATE SUCCESS`

export const ADMIN_ACTION_ADMIN_PASSWORD_UPDATE_FAIL = `${ADMIN} ACCOUNT PASSWORD UPDATE FAIL`
