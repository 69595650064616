import { useLocation } from 'react-router-dom'
import { ITimerSelectDropDown } from '../models/CreateBoard'
import { CandidateReviewStatus } from '../models/IAppState'

/**
 * Enum for API endpoint and routes details
 * @readonly
 * @enum {string}
 */
export enum DatabaseUrls {
  baseUrl = 'https://us-central1-primo-impression-staging.cloudfunctions.net/api/v1/',
  hrProfile = 'hrprofile',
  getInterviewList = 'gethrdetails',
  createInterview = 'interview',
  shareInterview = 'sendInterview1',
  getImpressionList = 'getImpressionList',
  updateImpressionDetails = 'updateImpressionDetails',
  updateInterviewStatus = 'updateinterviewstatus',
  updateAdminProfile = 'updatehrprofile',
  userCreateProfile = 'profile',
  getUserDetails = 'getuserdetails',
  startImpression = 'startImpression',
  saveImpression = 'impression',
  updateUserProfile = 'updateuserProfile',
  archiveImpression = 'archiveinterview',
  closeImpression = 'close',
}
/**
 * Api endpoints
 * @readonly
 */
export const ApiEndpoints = {
  /**
   *@description Create HR profile after successful firebase sign up
   */
  hrprofile: (): string => DatabaseUrls.baseUrl.concat(DatabaseUrls.hrProfile),
  /**
   * @description Return list of interview(s) created by respective logged in HR.
   */
  getInterviewList: (id: string): string => DatabaseUrls.baseUrl.concat(DatabaseUrls.getInterviewList).concat(`/${id}`),
  /** @description Create new interview.
   * @params id: HR id
   */
  createInterview: (): string => DatabaseUrls.baseUrl.concat(DatabaseUrls.createInterview),
  /**
   * @description Share interview i.e. POST request
   */
  shareInterview: (): string => DatabaseUrls.baseUrl.concat(DatabaseUrls.shareInterview),

  /**
   * Get impression list
   */
  getImpressionList: (id: string): string =>
    DatabaseUrls.baseUrl.concat(DatabaseUrls.getImpressionList).concat(`/${id}`),

  /**
   * Update Impression Details
   */
  updateImpressionDetails: () => DatabaseUrls.baseUrl.concat(DatabaseUrls.updateImpressionDetails),

  /**
   * Update Interview Status
   */
  updateInterviewStatus: () => DatabaseUrls.baseUrl.concat(DatabaseUrls.updateInterviewStatus),

  /**
   * Update Admin profile, first and last name
   */
  updateAdminProfile: () => DatabaseUrls.baseUrl.concat(DatabaseUrls.updateAdminProfile),
  /**
   * Create user profile
   */
  createUserProfile: () => DatabaseUrls.baseUrl.concat(DatabaseUrls.userCreateProfile),
  /**
   * Get user profile
   */
  getUserDetails: () => DatabaseUrls.baseUrl.concat(DatabaseUrls.getUserDetails),

  startImpression: () => DatabaseUrls.baseUrl.concat(DatabaseUrls.startImpression),

  saveImpression: () => DatabaseUrls.baseUrl.concat(DatabaseUrls.saveImpression),

  updateUserProfile: () => DatabaseUrls.baseUrl.concat(DatabaseUrls.updateUserProfile),

  archiveImpression: () => DatabaseUrls.baseUrl.concat(DatabaseUrls.archiveImpression),

  closeImpression: () => DatabaseUrls.baseUrl.concat(DatabaseUrls.closeImpression),
}

export const questionTimeLimit: ITimerSelectDropDown[] = [
  // {
  //   option: "10sec",
  //   value: 10000,
  // },
  // {
  //   option: "20sec",
  //   value: 20000,
  // },
  {
    option: '30 s',
    value: 30000,
  },
  {
    option: '1.0 m',
    value: 60000,
  },
  {
    option: '1.30 m',
    value: 90000,
  },
  {
    option: '2.0 m',
    value: 120000,
  },
  // {
  //   option: "3min",
  //   value: 180000,
  // },
]
/**
 * ### Question time limit, dropdown values.
 * Return option based on value
 * @param time number
 */
export const getOptionFromValueQuestionTimeLimit = (time: number) => {
  const value = questionTimeLimit.find((item) => item.value === time)
  return value ? value?.option : ''
}

export const CandidateReviewList = [
  {
    label: 'Reviewed',
    option: CandidateReviewStatus.reviewed,
  },
  {
    label: 'Accepted',
    option: CandidateReviewStatus.accept,
  },
  {
    label: 'Rejected',
    option: CandidateReviewStatus.reject,
  },
]

export const getCandidateInterviewStatusLabel = (status: CandidateReviewStatus): string => {
  if (status === CandidateReviewStatus.new) {
    return 'New'
  }
  return CandidateReviewList.find((item) => item.option === status)?.label || ''
}

export const removeQuestionMarkFromSentenceIfAny = (question: string) => {
  const data = question.split('')
  const lastIndex = data.length - 1
  if (data[lastIndex] === '?') {
    data[lastIndex] = ''
    return data.join('')
  }
  return data.join('')
}

export const paginateFromArray = <T>(array: T[], pageNumber: number, pageSize: number): T[] =>
  array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)

export function useQuery() {
  return new URLSearchParams(useLocation().search)
}
