import styled from 'styled-components'

export const StyledFooter = styled.div`
    display: flex;
    justify-content: space-between;
    background: #F5F5F5;
    padding: 24px 16px;
`

export const StyledFooterText = styled.div`
  color: #2E333B;
  font-size: 12px;
  color: rgba(0,0,0,0.50);
`

export const StyledFooterContainer = styled.div`
    max-width: 1048px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
`

export const StyledFooterLink = styled.a`
  color: rgba(0,0,0,0.50);
  text-decoration: underline;
`
