import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Submit from 'components/Submit'
// import InterviewBuilderPassive from "components/InterviewBuilderPassive";
// import InterviewBuilderActive from "components/InterviewBuilderActive";
import NotFound from 'components/NotFound'
import GetInterview from 'components/GetInterview'
import ReviewDone from 'components/ReviewDone'
import Auth from 'components/Auth'
import Home from 'components/Home'
import InterviewBuilderCandidate from 'components/InterviewBuilderCandidate'
import BoardPage from 'components/BoardPage'
// import Interview from './components/Interview'
// import { authActions } from "store";
import { connect } from 'react-redux'
// import { bindActionCreators } from "redux";
import Board from 'components/Board'
import StartInterview from 'components/StartInterview'

import SettingsPage from 'components/Settings'

import Admin from 'components/Admin'
import AdminHome from 'components/Admin/stateless/Home'
// import { LinkedInPopUp } from './components/Auth/LinkedIn';
import LazyLoadingComponent from 'shared/LazyLoadingComponent'
import LazyRoute from 'shared/LazyRoute/LazyRoute'

// Lazy loading routes import
const CreateBoard = React.lazy(() => import('components/Admin/stateless/CreateBoard'))
const ImpressionListing = React.lazy(() => import('components/Admin/stateless/ImpressionListing/ImpressionListing'))
const ShareInterview = React.lazy(() => import('components/Admin/stateful/ShareInterview/ShareInterview'))
const ArchiveImpression = React.lazy(() => import('components/Admin/stateless/ArchiveImpression/ArchiveImpression'))
const ViewImpressionModal = React.lazy(
  () => import('components/Admin/stateless/ViewImpressionModal/ViewImpressionModal'),
)
const AdminAccount = React.lazy(() => import('components/Admin/stateful/Account/Account'))

const ForgotPassword = React.lazy(() => import('shared/ForgotPassword/ForgotPassword'))

const Questions = React.lazy(() => import('components/Questions'))
/**
 * Admin Routes
 * @param {IAdminState} param | admin state
 */
export function AdminRoutes({ app }: any) {
  return app.loggedIn && app.role === 'admin' ? (
    <>
      <Switch>
        <Route path='/admin' exact component={AdminHome} />
        <LazyRoute path='/admin/create'>
          <CreateBoard />
        </LazyRoute>
        <LazyRoute path='/admin/impression/:id'>
          <ImpressionListing />
        </LazyRoute>
        <LazyRoute path='/admin/account'>
          <AdminAccount />
        </LazyRoute>
        <Redirect to='/admin' />
      </Switch>
      {/* Dialogs ########################## */}
      <LazyLoadingComponent>
        <ViewImpressionModal />
      </LazyLoadingComponent>
      <LazyLoadingComponent>
        <ArchiveImpression />
      </LazyLoadingComponent>
      <LazyLoadingComponent>
        <ShareInterview />
      </LazyLoadingComponent>
    </>
  ) : (
    <Switch>
      <LazyRoute path='/forgot-password'>
        <ForgotPassword />
      </LazyRoute>
      <Route path='/admin/auth/:path' exact component={Admin} />
      <Redirect to='/admin/auth/login' />
    </Switch>
  )
}
/**
 * Routes for user
 * @param {auth} | user state
 */
export function UserRoutes({ app, location }: any) {
  const homePathName = 'home'
  const { pathname, search } = (location.state &&
    location.state.from &&
    location.state.from.pathname.includes(homePathName) &&
    location.state.from) || {
    pathname: `/${homePathName}`,
  }

  return app.loggedIn && app.role === 'user' ? (
    <Switch>
      <Route path='/review' exact component={GetInterview} />
      <LazyRoute path='/interview/:code/questions'>
        <Questions />
      </LazyRoute>
      {/* <Route path='/interview/:code?' exact component={Board} /> */}
      <Route path='/interview' exact component={StartInterview} />
      <Route path='/submit' exact component={Submit} />
      <Route path='/404' exact component={NotFound} />
      <Route path='/done' exact component={ReviewDone} />
      {/* <Route path='/home/:id?' exact component={Home} /> */}
      <Route path='/home/:id?' exact component={InterviewBuilderCandidate} />
      {/* The below is just for testing the UI later it can be with ID */}
      <Route path='/boardPage' exact component={BoardPage} />
      <Route path='/settings/:sub_page' exact component={SettingsPage} />
      <Redirect to={{ pathname, search }} />
    </Switch>
  ) : (
    <Switch>
      <LazyRoute path='/forgot-password'>
        <ForgotPassword />
      </LazyRoute>
      <Route path='/auth' render={() => <Auth />} />
      <Redirect
        to={{
          pathname: '/auth/login',
          state: { from: location },
        }}
      />
    </Switch>
  )
}
/**
 * Routing Paths
 */
export function Routes({ auth, admin, app }: any) {
  return (
    <Switch>
      <Route path='/admin' render={renderProps => <AdminRoutes auth={admin} app={app} {...renderProps} />} />
      <Route path='/' render={renderProps => <UserRoutes auth={auth} app={app} {...renderProps} />} />
    </Switch>
  )
}

// export default Routes;
const mapStateToProps = ({ auth, admin, app }: any) => ({
  auth,
  admin,
  app,
})
// const mapDispatchToProps = (dispatch) => ({
//   actions: bindActionCreators(authActions, dispatch),
// });
export default connect(mapStateToProps, null)(Routes)
