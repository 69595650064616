import React, { createRef, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import DialogContent from '@material-ui/core/DialogContent'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import VideoRecorder from 'react-video-recorder'
import { CloseBtn, CloseBtnSection, RecSection, StyledNextBtn, StyledVideoWrapper, StyleRecordPage } from './style'

const useStyles = makeStyles(() => ({
  root: {
    background: '#000',

    '& .MuiDialogContent-root': {
      padding: 0,
    },

    video: {
      height: '100vh',
    },
  },
  container: {
    background: '#000',
    color: 'white',
    padding: '0',
    // #FIXME: this is a dirty hack to remove the first child paddingTop issue
  },
  recBtn: {
    display: 'flex',
    height: '90px',
    width: '90px',
    borderRadius: '50%',
    margin: '10px',
  },
  recColor: {
    '& p': {
      padding: '10px',
    },
    '& span': {
      color: 'red',
      fontWeight: 'bold',
    },
  },
  timer: {
    position: 'absolute',
    top: '5px',
    zIndex: 9,
    backgroundColor: '#000000',
    padding: 5,
    color: 'white',
  },
}))

const Transition = React.forwardRef((props, ref) => <Slide direction='up' ref={ref} {...props} />)

const NextBtn = ({ children, ...rest }) => <StyledNextBtn {...rest}>{children}</StyledNextBtn>

const StyledCloseBtn = ({ children, ...rest }) => <CloseBtn {...rest}>{children}</CloseBtn>
let timer
export default function RecordModal({ closeModal, open, questions, _addAnswer, _nextQuestion, currQuestion }) {
  const classes = useStyles()
  const videoPlayerRef = createRef()
  const [video, setVideo] = useState({})
  const [, setIsCamOn] = useState(false)
  const [isRecording, setIsRecording] = useState(false)
  const [isAnswerRecorded, setIsAnswerRecorded] = useState(false)
  const [, setIsPause] = useState(false)
  const [timerVal, setTimerVal] = useState(0)
  // const [isAudio, setIsAudio] = useState(false)

  useEffect(() => {
    if (timer && questions[currQuestion] && timerVal >= Math.floor(questions[currQuestion].timeLimit / 1000)) {
      clearInterval(timer)
      _stopRecording()
      timer = null
    }
  })

  const _stopRecording = () => {
    clearInterval(timer)
    setTimerVal(0)
    videoPlayerRef.current.handleStopRecording()
    setIsPause(false)
    setIsRecording(false)
  }

  const addVideo = ev => {
    setVideo(ev)
    console.log('video--->', video, ev)
    setIsAnswerRecorded(true)
    _addAnswer(ev)
  }

  const _closeModal = () => {
    setIsCamOn(false)
    setIsRecording(false)
    setIsAnswerRecorded(false)
    closeModal()
  }

  const nextQuestion = () => {
    setIsCamOn(false)
    setIsRecording(false)
    setIsAnswerRecorded(false)
    _nextQuestion()
  }

  const time = (questions[currQuestion].timeLimit / 1000)
  console.log('questions--->', time)

  const qtime = questions.length > 0 ? questions[currQuestion].timeLimit / 1000 : 0
  return (
    <StyleRecordPage>
      <Dialog fullScreen open={open} onClose={closeModal} className={classes.root} TransitionComponent={Transition}>
        {isRecording ? (
          <LinearProgress variant='determinate' key={timerVal} value={(timerVal / qtime) * 100} color='secondary' />
        ) : (
          ''
        )}
        {isRecording && <Typography className={classes.timer}>-{qtime - timerVal}seconds</Typography>}
        <DialogContent className={classes.container}>
          <StyledVideoWrapper>
            <VideoRecorder
              ref={videoPlayerRef}
              onRecordingComplete={ev => {
                addVideo(ev)
              }}
            />
          </StyledVideoWrapper>
          {isRecording ? null : (
            <CloseBtnSection>
              <StyledCloseBtn
                variant='contained'
                size='medium'
                startIcon={<i className='far fa-times-circle' />}
                onClick={_closeModal}
              >
                Close
              </StyledCloseBtn>
            </CloseBtnSection>
          )}
          {isAnswerRecorded ? (
            <div>
              <RecSection>
                <NextBtn onClick={nextQuestion}>Continue to Next</NextBtn>
              </RecSection>
            </div>
          ) : null}
        </DialogContent>
      </Dialog>
    </StyleRecordPage>
  )
}
