import { CandidateFavoriteRequest, ImpressionListResponse, InterviewStatusRequest, DownloadAllImpression } from 'models/IAppState'
import { Dispatch } from 'redux'
import { ApiEndpoints } from 'utils'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import axios from 'axios'

// Initialize Cloud Firestore and get a reference to the service
const db = firebase.firestore()
const storage = firebase.storage()

export const TYPE = '[INTERVIEW]'
export const ACTION_GET_CANDIDATE_LIST_INIT = `${TYPE} GET CANDIDATE LIST INIT`
export const ACTION_GET_CANDIDATE_LIST_SUCCESS = `${TYPE} GET CANDIDATE LIST SUCCESS`
export const ACTION_GET_CANDIDATE_LIST_FAIL = `${TYPE} GET CANDIDATE LIST FAIL`

export const actionGetImpressionListInit = (id: string) => ({
  type: ACTION_GET_CANDIDATE_LIST_INIT,
  payload: id,
})

export const actionGetImpressionListSuccess = (data: ImpressionListResponse) => ({
  type: ACTION_GET_CANDIDATE_LIST_SUCCESS,
  payload: data,
})

export const actionGetImpressionListFail = () => ({
  type: ACTION_GET_CANDIDATE_LIST_FAIL,
})

export const getImpressionListAction = (id: string) => async (dispatch: Dispatch) => {
  dispatch(actionGetImpressionListInit(id))

  try {
    const response = await fetch(ApiEndpoints.getImpressionList(id))
    const data: ImpressionListResponse = await response.json()
    dispatch(actionGetImpressionListSuccess(data))
  } catch (ex) {
    dispatch(actionGetImpressionListFail())
  }
}

export const ACTION_UPDATE_CANDIDATE_DETAILS = `${TYPE} UPDATE CANDIDATE DETAILS INIT`
export const ACTION_UPDATE_CANDIDATE_DETAILS_SUCCESS = `${TYPE} UPDATE CANDIDATE DETAILS SUCCESS`
export const ACTION_UPDATE_CANDIDATE_DETAILS_FAIL = `${TYPE} UPDATE CANDIDATE DETAILS FAIL`

export const ACTION_UPDATE_INTERVIEW_STATUS2 = `${TYPE} UPDATE INTERVIEW STATUS INIT`
export const ACTION_UPDATE_INTERVIEW_STATUS2_SUCCESS = `${TYPE} UPDATE INTERVIEW STATUS SUCCESS`
export const ACTION_UPDATE_INTERVIEW_STATUS2_FAIL = `${TYPE} UPDATE INTERVIEW STATUS FAIL`

export const ACTION_DOWNLOAD_IMPRESSIONS_DETAILS = `${TYPE} DOWNLOAD IMPRESSIONS INIT`
export const ACTION_DOWNLOAD_IMPRESSIONS_SUCCESS = `${TYPE} DOWNLOAD IMPRESSIONS SUCCESS`
export const ACTION_DOWNLOAD_IMPRESSIONS_FAIL = `${TYPE} DOWNLOAD IMPRESSIONS FAIL`

export const actionUpdateCandidateDetailsInit = (candidate: CandidateFavoriteRequest) => ({
  type: ACTION_UPDATE_CANDIDATE_DETAILS,
  payload: candidate,
})

export const actionUpdateCandidateDetailsSuccess = (id: string) => ({
  type: ACTION_UPDATE_CANDIDATE_DETAILS_SUCCESS,
  payload: id,
})

export const actionUpdateCandidateDetailsFail = () => ({
  type: ACTION_UPDATE_CANDIDATE_DETAILS_FAIL,
})

export const actionUpdateInterviewStatus2Init = (interview: InterviewStatusRequest) => ({
  type: ACTION_UPDATE_INTERVIEW_STATUS2,
  payload: interview,
})

export const actionUpdateInterviewStatus2Success = (id: string) => ({
  type: ACTION_UPDATE_INTERVIEW_STATUS2_SUCCESS,
  payload: id,
})

export const actionUpdateInterviewStatus2Fail = () => ({
  type: ACTION_UPDATE_INTERVIEW_STATUS2_FAIL,
})

// @dev Download All Impression Actions
export const actionDownloadImpressionsInit = (jobId: DownloadAllImpression) => ({
  type: ACTION_DOWNLOAD_IMPRESSIONS_DETAILS,
  payload: jobId,
})

export const actionDownloadImpressionsSuccess = (jobId: string) => ({
  type: ACTION_DOWNLOAD_IMPRESSIONS_SUCCESS,
  payload: jobId,
})

export const actionDownloadImpressionsFail = () => ({
  type: ACTION_DOWNLOAD_IMPRESSIONS_FAIL,
})

export const actionUpdateCandidateDetails =
  (candidate: CandidateFavoriteRequest, success: Function = () => { }, error: Function = () => { }) =>
    async (dispatch: Dispatch) => {
      dispatch(actionUpdateCandidateDetailsInit(candidate))

      try {
        const response = await fetch(ApiEndpoints.updateImpressionDetails(), {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(candidate),
          method: 'POST',
        })
        const data: { id: string } = await response.json()
        dispatch(actionUpdateCandidateDetailsSuccess(data.id))
        success()
      } catch (ex) {
        dispatch(actionUpdateCandidateDetailsFail())
        error()
      }
    }

// @dev change interview status
export const actionUpdateInterviewStatus =
  (interview: InterviewStatusRequest, success: Function = () => { }, error: Function = () => { }) =>
    async (dispatch: Dispatch) => {
      dispatch(actionUpdateInterviewStatus2Init(interview))

      try {
        /* const response = await fetch(ApiEndpoints.updateImpressionDetails(), {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(interview),
          method: 'POST',
        })
        const data: { id: string } = await response.json()
        dispatch(actionUpdateInterviewStatusSuccess(data.id))
        success() */
        console.log('Entro a post')
        const response = await db.collection('interview').doc(interview.jobId).update({
          status: interview.status
        })
        console.log('Respones is: ', response)
      } catch (ex) {
        dispatch(actionUpdateInterviewStatus2Fail())
        error()
      }
    }

// @dev Download All interview Video - Impression code 7n1bcta
export const actionDownloadAllImpressions =
  (jobId: DownloadAllImpression, success: Function = () => { }, error: Function = () => { }) =>
    async (dispatch: Dispatch) => {
      dispatch(actionDownloadImpressionsInit(jobId))

      try {
        const pathReference = storage.ref(`Interviews/${jobId}/`)
        console.log('Entro a get', pathReference)

        pathReference.listAll()
          .then((res) => {
            res.prefixes.forEach((folderRef) => {
              // All the prefixes under listRef.
              // You may call listAll() recursively on them.
              folderRef.listAll()
            })
            res.items.forEach((itemRef) => {
              // All the items under listRef.
              // You may call listAll() recursively on them.
              console.log('Entro a itemRef', itemRef['fullPath'])
              itemRef.getDownloadURL().then((url) => {
                console.log('URL is: ', url)
                var xhr = new XMLHttpRequest()
                xhr.responseType = 'blob'
                xhr.onload = (event) => {
                  var blob = xhr.response
                }
                xhr.open('GET', url)
                xhr.send()
                const data = axios({
                  url: '' + `${url}` + '', //your url
                  method: 'GET',
                  responseType: 'blob', // important
                }).then((response) => {
                  console.log('Entro a blob get response', response.data)
                  const url = window.URL.createObjectURL(new Blob([response.data]))
                  const link = document.createElement('a')
                  link.href = url
                  link.setAttribute('download', `${itemRef['fullPath']}`) //or any other extension
                  document.body.appendChild(link)
                  link.click()
                })
              })
            })
          })
        dispatch(actionDownloadImpressionsSuccess(jobId.jobId))
        success()
      } catch (ex) {
        dispatch(actionDownloadImpressionsFail())
        error()
      }
    }
