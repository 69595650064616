import React from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { StyledMessage } from './style'
const NotFound = ({ history }) => (
  <>
    <StyledMessage>
      <Typography variant='h6' component='h1'>
        Seems like you are having troubles. Please contact your Recruiter.
      </Typography>
      <div>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            history.push('/home')
          }}
        >
          Home Page
        </Button>
      </div>
    </StyledMessage>
  </>
)

export default NotFound
