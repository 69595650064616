import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Signup from './Signup'
import SigninEmail from './SigninEmail'

/**
 * Routing Paths
 */
const AuthRoute = () => (
  <Switch>
    <Route path='/auth/signup' exact component={Signup} />
    <Route path='/auth/login' exact component={SigninEmail} />
    <Redirect to='/auth/login' />
  </Switch>
)

export default AuthRoute
