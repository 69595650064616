import styled from 'styled-components'
import { PrimaryColor } from './app.style'

export const PrimoHeading = styled.p`
  ${PrimaryColor};
  text-align: center;
  font-size: 0.8em;
  font-weight: 900;
  margin: 5px auto;
`
