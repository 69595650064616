import React, { useState } from 'react'
import { connect } from 'react-redux'

import { bindActionCreators } from 'redux'

import firebase from 'firebase/app'
import { interviewActions } from '../../actions'

// config keys of aws for uploading media files to s3

const Submit = ({ history, ...props }) => {
  const [array, setArray] = useState(history.location.state.videos)

  const _startOver = async () => {
    // iterating each question to get the video and upload in s3 and later generate the link as well
    for (let i = 0; i < array.length; i++) {
      await storage(i)
    }
  }

  const storage = async (i) => {
    if (i < array.length) {
      // console.log("iiii",i)

      // setIndex(i)
      const url = await firebase
        .storage()
        .ref()
        .child(`${props.questions.activeInterview.hRecordID}-${array[i].questionId}`)
        .put(array[i].video)
      const video = await url.ref.getDownloadURL()
      console.log('video', video)
      array[i].video = video
      setArray(array)

      if (i + 1 === array.length) {
        if (history.location.state.userType) {
          //  calling the newly created action to save the data in database
          props.actions.saveInterview(
            props.questions.activeInterview,
            array,
            'passive',
            history.location.state.candiDateId,
            // user
          )
          history.push('/interview')
        } else {
          props.actions.saveInterview(
            props.questions.activeInterview,
            array,
            'active',
            history.location.state.candiDateId,
          )
          history.push('/interview')
        }
      }
    }
  }
  return (
    <>
      <h3 className='title has-text-black -m-t-60 -has-text-weight-bold is-4 has-text-centered'>
        Would you like to save your videos for next interview?
      </h3>
      <div className='columns is-centered no-borders'>
        <button className='-m-t-30 button is-black is-large' onClick={_startOver}>
          Yes, Save my videos
        </button>
      </div>
      <div className='columns is-centered no-borders'>
        <button className='-m-t-30 button is-text is-large' onClick={_startOver}>
          No, I don't want to
        </button>
      </div>
    </>
  )
}

// export default Submit;

const mapStateToProps = ({ questions }, { session }) => ({
  questions,
  session,
})
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(interviewActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Submit)
