import styled from 'styled-components'
import LinearProgress from '@material-ui/core/LinearProgress'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { Player } from 'video-react'
import Typography from '@material-ui/core/Typography'

export const boxWrapperStyle = {
  margin: '0 auto',
  maxWidth: '1048px',
  minWidth: '768px',
}

export const QuestionBoxContainer = styled(Box)``

export const SubmitContainer = styled(Box)`
   {
    padding: 2em 0 0 0;
    max-width: 1048px;

    @media (min-width: 768px) {
      min-height: 400px;
    }
  }
`
export const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 70px;
  p {
    margin: 20px 0 20px 0;
    color: #2e333b;
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0.19px;
    line-height: 15px;
    text-align: center;
  }
`
export const RecBoxWrapper = styled(Box)`
   {
    box-shadow: none;
    padding: 30px;
    margin: auto;
    margin-top: 50px;
    background-color: #fff7ed;
    border-radius: 8px;
  }
`
export const RecBoxTitle = {
  fontSize: '26px',
  fontFamily: 'poppins',
  letterSpacing: '0.5px',
  fontWeight: '600',
  color: '#2e333b',
}

export const RecBoxSub = {
  paddingTop: '10px',
  lineHeight: '30px',
  color: 'black',
  fontSize: '21px',
  letterSpacing: '0.3px',
}
export const btnColor = {
  background: '#2c4aff',
  width: '50%',
  height: '45px',
  fontSize: '16px',
  textTransform: 'none',
  fontFamily: 'lato',
  fontWeight: 'bold',
  lineHeight: '20px',
  letterSpacing: '1px',
}

export const VideoWrapper = styled(Player)`
  border-radius: 10px;
  video {
    border-radius: 10px;
    font-family: "Lato";
  }
  & .video-react-big-play-button {
    font-size: 4em;
    line-height: 2em;
    height: 2em;
    width: 2em;
    border-radius: 50%;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0;
    background-color: #d8d8d8;
    opacity: 0.5;
  }
  // & .video-react-big-play-button:hover {

  // }
`

export const btnBorder = {
  opacity: '50%',
  color: '#2C4AFF',
  border: '4px solid',
  width: '50%',
  height: '45px',
  fontSize: '16px',
  textTransform: 'none',
  fontFamily: 'lato',
  fontWeight: 'bold',
  lineHeight: '20px',
  letterSpacing: '1px',
}

export const ButtonWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: flex-start;
`
export const displayFlex = {
  display: 'flex',
  justifyContent: 'center',
}
export const displayBlock = {
  display: 'block',
}

export const mainContent = {
  fontFamily: 'Lato',
  fontSize: '21px',
  color: '#2e33b',
  padding: '20px 0 20px 0',
  letterSpacing: '0.4px',
  lineHeight: '30px',
}

export const questTitle = {
  fontFamily: 'Lato',
  fontSize: '21px',
  color: '#2e33b',
  padding: '30px 0 10px 0',
  letterSpacing: '0.4px',
  lineHeight: '35px',
}

export const StyledBorder = styled.div`
  margin: 10px;
`
export const StyledQuest = styled.p`
  font-size: 21px;
  padding: 20px 0 20px 0;
  font-family: "Poppins";
  font-weight: bold;
  line-height: 30px;
  letter-spacing: 0.4px;
  color: #2e333b;
`
export const StyledTipsBar = styled.div`
  background: #ecffc0;
  padding: 15px;
  margin: 40px 0 20px 0;

  p {
    font-family: "Poppins";
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0.3px;
    line-height: 25px;
    color: #669200;
  }
`
export const StyledTips = styled.p`
  font-size: 17px;
  padding: 10px 0 10px 0;
  font-family: "lato";
  line-height: 10px;
  letter-spacing: 0.3px;
  color: #2e333b;
`
export const questSubTitle = {
  fontFamily: 'Poppins',
  fontSize: '20px',
  color: '#2e33b',
  paddingTop: '20px',
  letterSpacing: '0.3px',
  lineHeight: '35px',
}
export const itemAlignCenter = {
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
}

export const itemAlign = {
  display: 'block',
}
export const subBoxPadding = {
  padding: '20px 0 50px 0',
}
export const textAlignCenter = {
  textAlign: 'center',
}
export const boxHeight = {
  minHeight: '750px',
}
export const mobileBoxHeight = {
  minHeight: 'auto',
}
export const mobileBoxWapper = {
  paddingTop: '0px',
}

export const SubmitMsg = styled(Box)`
  background: #e2e2e2;
  width: 30%;
  border-radius: 2px;
  p {
    font-family: "lato";
    color: rgba(0, 0, 0, 0.85);
    font-size: 12px;
    letter-spacing: 0.2px;
    text-align: center;
  }
`
export const SubmitBox = styled(Box)`
   {
    box-shadow: 0 4px 8px #e2e2e2;
    padding: 10px 20px;
    background: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      min-height: 235px;
    }
  }
`
export const QuestionBoxWrapper = styled(Box)`
   {
    box-shadow: 0 4px 8px #e2e2e2;
    padding: 32px;
    background: white;
    border-radius: 8px;

    @media (max-width: 768px) {
      padding: 16px;
    }

    @media (min-width: 768px) {
      min-height: 235px;
    }
  }
`

export const ProgressBoxWrapper = styled(Box)`
  margin: 0 auto;
  border-radius: 8px;
  padding: 2em 0 2em 0;
  padding-bottom: 1em;
  opacity: 1;
`
export const boxRecordStyle = {
  boxShadow: '0 4px 8px #e2e2e2',
  background: 'white',
  borderRadius: '5px',
}
export const boxStartRecordContainer = {
  maxWidth: '940px',
  margin: '0 auto',
  borderRadius: '8px',
}
export const boxStartRecord = {
  boxShadow: 'none',
  padding: '30px',
  margin: 'auto',
  marginTop: '30px',
  backgroundColor: '#fff7ed',
  borderRadius: '8px',
}
export const questionProgress = {
  paddingTop: '2em',
  paddingBottom: '1em',
  opacity: '1.0',
}
export const mainTitle = {
  fontFamily: 'Poppins',
  fontSize: '38px',
  lineHeight: '50px',
  color: '#2e333b',
  fontWeight: '800',
  letterSpacing: '1px',
  paddingTop: '30px',
}
export const StyledGreeting = styled.p`
  font-family: "poppins";
  font-size: 38px;
  line-height: 50px;
  font-weight: 800;
  color: #2e333b;

  @media (max-width: 768px) {
    font-size: 25px;
    line-height: 30px;
  }
`

export const fontLato = {
  fontFamily: 'Lato',
}
export const fontPoppins = {
  fontFamily: 'Poppins',
}
export const fontSz30 = {
  fontSize: '30px',
}
export const fontSz20 = {
  fontSize: '20px',
}

export const StyledButtonWrapper = styled.div`
  padding-top: 32px;
  display: flex;
  width: 100%;
`
export const StyledProgressWrapper = styled.div`
  position: relative;

  > div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
`

export const styledQuestionList = {
  listStyle: 'initial',
  paddingLeft: '20px',
}
export const StyledVideoPlayer = styled.div`
  video {
    transform: translate(-50%, -50%) scale(-1, 1);
  }
`

export const StyledButtonContainer = styled.div`
  button {
    margin-bottom: 10px;
    border: 1px solid rgba(63, 81, 181, 0.5);
    width: 300px;
    height: 40px;
    min-width: 250px;
    font-size: 14px;
    text-transform: none;
  }
`
// to update
export const StyledTipsVideo = styled.div`
  text-align: center;
  margin: 40px;
  button {
    &.MuiTypography-root {
      color: #2c4aff;
      font-family: "Lato";
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
      font-size: 15px;
      border-bottom: 1px solid;
      text-decoration: none;
    }
  }
`
export const StyledLinearProgress = styled(LinearProgress)`
  &.MuiLinearProgress-colorPrimary {
    background-color: #ebe9e9;
    border-radius: 0;
    + p {
      font-family: "lato";
      letter-spacing: 0.3px;
      line-height: 50px;
      color: rgba(46, 51, 59, 0.57);
    }
  }
  .MuiLinearProgress-bar {
    background-color: #6dd400;
  }
`

export const StyledActionBox = styled(Box)`
  color: #fff;
  min-height: 235px;
  padding: 32px;
  text-align: center;
  background: linear-gradient(90deg, #5781ff, #2c4aff);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const StyledActionBoxTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 24px;
`

export const StyleTextField = styled(TextField)`
   {
    .MuiInputBase-root {
      background: #fff;
      margin-bottom: 0;

      &:hover {
        background: #fff;
      }
    }

    .MuiInputLabel-root {
      // color: #fff;

      &.Mui-focused {
        // color: #fff;
      }
    }

    .MuiInputBase-input {
      // color: #fff;
    }
  }
`

export const StyledButton = styled(Button)`
  &.MuiButtonBase-root {
    background: #fff;
    border-radius: 4px;
    transition: all ease-in-out 0.10s;

    &:hover {
      box-shadow: 0 0 6px 2px #333333;
    }
  }

  .MuiButton-label {
    color: #2c4aff;
    text-transform: capitalize;
    font-family: "Lato";
    font-weight: 600;
    font-size: 16px;
  }
`

export const StyledActionButton = styled.div`
  margin-top: 8px;
  width: 100%;
`

export const StyledBoxTitle = styled(Typography)`
  font-size: 20px;
  font-weight: 800;
`

export const StyledMetaDesc = styled(Typography)`
  font-size: 16px;
  display: flex;
  align-items: baseline;
`

export const StyledMetaDate = styled(Typography)`
  font-size: 12px;
`

export const StyledBoxLabel = styled.div`
  color: rgba(0, 0, 0, 0.85);
  font-size: 12px;
  background: #e2e2e2;
  padding: 4px 8px;
  display: inline-flex;
  border-radius: 2px;
`

export const StyledBoxBody = styled.div`
  flex: 1;
`

export const StyledBoxFooter = styled.div``

export const sectionHeadingStyle = {
  color: 'rgba(0,0,0,0.50)',
  fontWeight: 600,
}
