import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import json2mq from 'json2mq'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { connect, useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { authActions, interviewActions } from 'actions'
import { actionAdminLogout } from 'store/actions'
import Menu from '@material-ui/core/Menu'
import MenuIcon from '@material-ui/icons/Menu'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { HistorySharp, KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons'
import {
  selectorGetAdminEmail,
  selectorAdminFullName,
  selectorAdminCompanyName,
  selectorGetAdminDisplayName,
  selectorUserFirstName,
  selectorUserEmail,
  getIsAdminAuthenticated,
} from 'store/selectors'
import logoPrimoAdmin from '../../../resource/logos/primo-logo-admin.svg'
import logoPrimoCandidate from '../../../resource/logos/primo-logo-candidate.svg'
import logoCandidate from 'resource/primo-logo.png'
import avatar from '../../../resource/none-avatar.png'
import TabsAdminDashboard from './TabsAdminDashboard'

import CallIcon from '@material-ui/icons/Call'
import AddIcon from '@material-ui/icons/Add'

import {
  MobileDrawer,
  MobileDrawerTop,
  MobileDrawerConent,
  MobileDrawerBottom,
  MobileDrawerFooter,
  HeaderContainer,
  HeaderLogo,
  DesktopMenuRightSide,
} from './style.ts'
import { CallOutlined, ExitToApp, ListAlt, Settings } from '@material-ui/icons'
import firebase from 'firebase/app'

const useStyles = makeStyles(theme => ({
  avatarStyle: {
    border: '3px solid #ECECEC',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  medium: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  menuPaperStyle: {
    width: '100%',
    maxWidth: '36ch',
  },
  menuTextStyle: {
    whiteSpace: 'normal',
    display: 'inline',
  },
  menuLinkStyle: {
    display: 'inline !important',
    fontSize: '14px !important',
    color: '#0752FF !important',
    padding: '0 !important',
  },

  iconColor: {
    '& svg': {
      fill: ' #0752FF',
    },
  },
  linkText: {
    '& span': {
      color: '#7c7c7c',
      marginBottom: 0,
      padding: '2px 0',
    },
  },
}))

const Header = React.memo(({ authactions, interviewactions, auth, app }) => {
  const currentUser = firebase.auth().currentUser
  const dispatch = useDispatch()
  const history = useHistory()

  const isAdmin = app.role === 'admin'

  /**
   * Root path here means, for admin it will always start with
   * domain/admin
   * For user, it always start with
   * domain/home OR domain/any-other-url
   */
  const roothPath = history?.location?.pathname?.split('/')[1].toLowerCase() === 'admin' ? 'admin' : 'user'

  /**
   * Current logic shows Header based on localStorage data i.e. app.loggedIn.
   *
   * Bug / Scenario #######:
   *
   * Admin logged into application i.e. /admin for admin and forgot to perform logout action, so in that case it won't update localStorage data i.e. app.loggedIn, it will be true until admin perform it manually.
   * So if the same Admin, open user page it will redirect to user signin page, as both user and admin have their own separate state to verify if they are authenticated to access the pages or not.
   * Header logic doesn't depend on the logic of admin/user, it will show Header with all options which are generally displayed for admin, although we  had earlier logged in as admin and changed url to user even if the user/admin is not logged in.
   *
   * Resolution #######:
   *
   * We will verify if the route i.e. /admin OR / and role app.role === "admin"/"user"
   * doesn't match then don't show Header.
   * For route starts with /admin , the role should be "admin" i.e. app.role stored in localStorage
   * And for user route starts with /, the role should be "user" i.e. app.role stored in localStorage
   */
  const correctPath = roothPath === app.role

  const urls = isAdmin
    ? {
      setting: '/admin/account',
      profile: '/admin',
      logout: '/admin/auth/login',
    }
    : {
      setting: '/settings/account',
      profile: '/settings/account',
      logout: '/auth/signin',
    }

  const adminDisplayName = useSelector(state => selectorAdminFullName(state))
  const adminDisplayCompanyName = useSelector(state => selectorAdminCompanyName(state))
  const adminEmail = useSelector(state => selectorGetAdminEmail(state))

  const userDisplayName = useSelector(state => selectorUserFirstName(state))
  const userEmail = useSelector(state => selectorUserEmail(state))
  const companyName = useSelector(state => selectorGetAdminDisplayName(state))
  //const [photoURL, setPhotoURL] = useState(currentUser ? currentUser.photoURL : avatar)
  const [photoURL, setPhotoURL] = useState(currentUser ? currentUser.photoURL : avatar)
  const isAdminAuthenticated = useSelector(state => getIsAdminAuthenticated(state))

  const userDisplayNameFirstCharacter = () => (userDisplayName && userDisplayName.charAt(0)) || '?'
  const adminDisplayNameFirstCharacter = () => (adminDisplayName ? adminDisplayName.charAt(0) : '?')

  const displayName = isAdmin ? adminDisplayName : userDisplayName
  const displayEmail = isAdmin ? adminEmail : userEmail

  // @dev update automatically Company Name
  const displayCompanyName = isAdmin ? adminDisplayCompanyName : companyName

  const nameFirstCharacter = isAdmin ? adminDisplayNameFirstCharacter() : userDisplayNameFirstCharacter()

  const classes = useStyles()
  const isMobile = useMediaQuery(
    json2mq({
      maxWidth: 768,
    }),
  )

  /**
   * Call logout action for admin/user and
   * redirect to /admin/auth/login for admin
   * and /auth/signin for user
   */
  const logout = () => {
    if (isAdmin) {
      dispatch(actionAdminLogout())
      // history.replace("/admin/auth/login");
    } else {
      // history.replace("/auth/signin");
      interviewactions.initialize()
      authactions.logout()
    }
  }

  const anchor = 'left'

  const [isAnchorOpen, setAnchorState] = useState(false)
  useEffect(() => {

    if (currentUser?.photoURL) {
      setPhotoURL(currentUser.photoURL)
    } else {
      setPhotoURL(avatar)
    }
  }, [currentUser])

  const toggleDrawer = () => {
    setAnchorState(state => !state)
  }

  const HeaderLogoComponent = React.memo(() => (
    <HeaderLogo mobile={isMobile}>
      <Link to={app.homeUrl}>
        {/* <img alt='Primo Impression' src={logoAdmin} /> */}
        <img alt='Primo Impression' src={isAdmin ? logoPrimoAdmin : logoPrimoCandidate} />
      </Link>
    </HeaderLogo>
  ))

  const MobileDrawerContainer = React.memo(() => (
    <section>
      <IconButton aria-controls='simple-menu' aria-haspopup='true' onClick={toggleDrawer}>
        <MenuIcon />
      </IconButton>
      <Drawer anchor={anchor} open={isAnchorOpen} onClose={toggleDrawer}>
        <MobileDrawer>
          <MobileDrawerTop>
            <section className='image'>
              <Avatar alt={displayName} src={photoURL} className={`${classes.avatarStyle} ${classes.large}`}>
              </Avatar>
            </section>
            <section className='user-greet'>
              <section className='name'>{displayName}</section>
              <section className='greet'>Welcome back!</section>
              {isAdmin ? <section className='greet'> {displayCompanyName} </section> : <></>}
            </section>
          </MobileDrawerTop>
          <MobileDrawerConent>
            <List onClick={toggleDrawer}>
              <ListItem component={Link} to={urls.setting}>
                <ListItemIcon className={classes.iconColor}>
                  <Settings />
                </ListItemIcon>
                <ListItemText className={classes.linkText} primary={'Settings'} />
              </ListItem>
              <ListItem component={Link} to={urls.setting}>
                <ListItemIcon className={classes.iconColor}>
                  <CallOutlined />
                </ListItemIcon>
                <ListItemText className={classes.linkText} primary={'Contact Us'} />
              </ListItem>
              <ListItem component={Link} to={urls.setting}>
                <ListItemIcon className={classes.iconColor}>
                  <ListAlt />
                </ListItemIcon>
                <ListItemText className={classes.linkText} primary={'Terms of Service'} />
              </ListItem>
            </List>
          </MobileDrawerConent>
          <MobileDrawerBottom>
            <Typography
              className='logout-text'
              onClick={() => {
                toggleDrawer()
                logout()
              }}
            >
              <ExitToApp />
              &nbsp;Log Out
            </Typography>
          </MobileDrawerBottom>
          <MobileDrawerFooter>
            &copy;&nbsp;Primo Impression.
            <p>All Rights Reserved</p>
          </MobileDrawerFooter>
        </MobileDrawer>
      </Drawer>
    </section>
  ))

  const MobileMenu = React.memo(() => (
    <>
      <MobileDrawerContainer />
      <HeaderLogoComponent />
      <section
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Avatar src={photoURL} alt={displayName} className={`${classes.avatarStyle} ${classes.medium}`} />
      </section>
    </>
  ))

  const [openDesktopMenu, setOpenDesktopMenu] = useState(null)

  const handleDesktopClick = event => {
    setOpenDesktopMenu(event.currentTarget)
  }

  const handleDesktopClose = () => {
    setOpenDesktopMenu(false)
  }

  const DesktopMenu = React.memo(() => (
    <DesktopMenuRightSide>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'grey' }}>
        <CallIcon style={{ fontSize: 22, marginRight: 10 }} />
        <i
          onClick={() => history.push('/settings/account')}
          style={{ fontSize: 19, cursor: 'pointer' }}
          className='fas fa-cog'
        ></i>
      </div>

      <IconButton
        aria-controls='customized-menu'
        aria-haspopup='true'
        onClick={handleDesktopClick}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }}
      >
        <Avatar alt='Remy Sharp' src={photoURL} className={classes.avatarStyle} />
        <Typography style={{ paddingTop: 15, paddingLeft: 5, fontSize: 14 }}>Morning, {displayName}</Typography>
        {openDesktopMenu ? (
          <KeyboardArrowRight style={{ fontSize: 24 }} />
        ) : (
          <KeyboardArrowDown style={{ fontSize: 24 }} />
        )}
      </IconButton>

      <Menu
        id='customized-menu'
        anchorEl={null}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(openDesktopMenu)}
        onClose={handleDesktopClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{ marginTop: 50 }}
      >
        <MenuItem>
          <ListItem alignItems='flex-start' component={Link} to={urls.profile}>
            <ListItemAvatar>
              <Avatar alt='Remy Sharp' src={photoURL}>{nameFirstCharacter}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant='h5' component='p' style={{ marginBottom: 0 }}>
                  {displayName}
                </Typography>
              }
              secondary={<>{displayEmail}</>}
            />
          </ListItem>
        </MenuItem>
        {isAdmin ? <MenuItem>{displayCompanyName}</MenuItem> : <></>}
        <MenuItem component={Link} to={urls.setting}>
          Account Settings
        </MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </DesktopMenuRightSide>
  ))

  const DesktopHeader = React.memo(() => (
    <>
      <HeaderLogoComponent />
      {isAdminAuthenticated && <TabsAdminDashboard screen='desktop' />}
      <DesktopMenu />
    </>
  ))

  return (
    <HeaderContainer>
      <section>{app.loggedIn && correctPath ? isMobile ? <MobileMenu /> : <DesktopHeader /> : null}</section>
      {isAdminAuthenticated && <div>{<TabsAdminDashboard screen='mobile' />}</div>}
    </HeaderContainer>
  )
})

const mapStateToProps = ({ auth, app }) => ({
  auth,
  app,
})
const mapDispatchToProps = dispatch => ({
  interviewactions: bindActionCreators(interviewActions, dispatch),
  authactions: bindActionCreators(authActions, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(Header)
