import { IAppState, INotification } from 'models/IAppState'
/**
 * Return Application state
 * @param state IAppState
 */
const getAppState = (state: IAppState): IAppState => state
/**
 * Return INotification
 * @param state IAppState
 */
export const getNotificationState = (state: IAppState): INotification => getAppState(state).notification

/**
 * Return severity for the message
 * @param state IAppState
 */
export const selectorAppNotificationSeverity = (state: IAppState) => getNotificationState(state).severity
/**
 * Return is snackbar open or not based on state. Although it wil get auto close when given duration completes.
 * @param state IAppState
 */
export const selectorAppNotificationIsOpen = (state: IAppState) => getNotificationState(state).isOpen
/**
 * Return the duration set, which will auto set to 0 when snackbar closes.
 * @param state IAppState
 */
export const selectorAppNotificationDuration = (state: IAppState) => getNotificationState(state).duration
/**
 * Return message passed when set to open, on hide it set to ''
 * @param state IAppState
 */
export const selectorAppNotificationMessage = (state: IAppState) => getNotificationState(state).message
