import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IAppState } from 'models/IAppState'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import {
  selectorAppNotificationSeverity,
  selectorAppNotificationIsOpen,
  selectorAppNotificationDuration,
  selectorAppNotificationMessage,
} from 'store/selectors'
import { actionHideNotification } from 'store/actions/notification.action'

const SnackBarNotification = React.memo(() => {
  const severity = useSelector((state: IAppState) => selectorAppNotificationSeverity(state))
  const open = useSelector((state: IAppState) => selectorAppNotificationIsOpen(state))
  const duration = useSelector((state: IAppState) => selectorAppNotificationDuration(state))
  const message = useSelector((state: IAppState) => selectorAppNotificationMessage(state))

  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(actionHideNotification())
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      color=''
      message={message}
      autoHideDuration={duration}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} variant='filled' severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  )
})

export default SnackBarNotification
