import React, { useEffect, useMemo, useState } from 'react'
import json2mq from 'json2mq'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CssBaseline from '@material-ui/core/CssBaseline'
import WebFont from 'webfontloader'
import SnackBarNotification from 'shared/SnackBarNotification/SnackBarNotification'
import { ThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import Footer from './components/common/Footer'
import Routes from './Routes'
import Header from './components/common/Header/index.jsx'
import { StyledLayout } from './style'
//session timeout

import SessionTimeout from 'shared/SessionTimeout/SessionTimeout'
import userBackgroundImage from './resource/user-login-background.png'
import adminBackgroundImage from './resource/admin-login-background.png'
import { useLocation } from 'react-router-dom'
import { screenContentBackgroundColor, screenCandidateBackgroundColor, screenWidth } from 'common.style'

WebFont.load({
  google: {
    families: ['Poppins:700, 800', 'Lato:300, 400, 600, 700, 800', 'sans-serif'],
  },
})

const App = () => {
  const isMobile = useMediaQuery(
    json2mq({
      maxWidth: 768,
    }),
  )

  const location = useLocation()

  const [isNotLoginPage, setsLoginPage] = useState(false)
  const [isAdminPage, setIsAdminPage] = useState(false)
  const showHeader = (href: string) => {
    const url = href.substr(href.lastIndexOf('/') + 1)

    setIsAdminPage(href.includes('admin') ? true : false)

    const flag = ['login', 'register', 'signup'].find((matchWith) => matchWith === url)
    setsLoginPage(flag ? false : true)
  }

  useEffect(() => {
    showHeader(window.location.href)
  }, [location])

  let theme = createMuiTheme({
    typography: {
      button: {
        textTransform: 'capitalize',
        fontWeight: 600,
      },
      fontSize: 18,

      fontFamily: ['Lato'].join(','),
      h1: {
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '3.052em',
        letterSpacing: '-1.5px',
        marginBottom: '1.05rem',
        lineHeight: '1.15',
      },
      h2: {
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '2.441em',
        letterSpacing: '-0.5px',
        marginBottom: '1.05rem',
        lineHeight: '1.15',
      },
      h3: {
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '1.953em',
        letterSpacing: 0,
        marginBottom: '1.05rem',
        lineHeight: '1.15',
      },
      h4: {
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '1.563em',
        letterSpacing: '0.25px',
        marginBottom: '1.05rem',
        lineHeight: '1.15',
      },
      h5: {
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '1.25em',
        letterSpacing: 0,
        marginBottom: '1.05rem',
        lineHeight: '1.15',
      },
      h6: {
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '1.1875rem',
        letterSpacing: '0.15px',
        marginBottom: '1.05rem',
        lineHeight: '1.15',
      },
      body1: {
        fontFamily: 'Lato',
        fontSize: '1rem',
        letterSpacing: '0.5px',
        marginBottom: '1.05rem',
      },
      subtitle1: {
        fontFamily: 'Lato',
        fontSize: '0.8em',
      },
    },
    palette: {
      primary: {
        main: '#2C4AFF',
      },
      secondary: {
        main: '#2C4AFF',
      },
    },

    overrides: {
      MuiButton: {
        root: {
          borderRadius: 4,
        },
        outlinedSecondary: {
          border: '3px solid',
          '&:hover': {
            border: '3px solid',
          },
        },
      },
      MuiCssBaseline: {
        '@global': {
          html: {
            height: '100%',
          },
          body: {
            height: '100%',
            backgroundColor: '#ffffff',
          },
        },
      },
    },
  })

  theme = responsiveFontSizes(theme)

  const getBackgroundColorOfContent = () => {
    if (isAdminPage) {
      return screenContentBackgroundColor
    } else {
      // Candidate Panel
      if (location?.pathname === '/interview') return 'white'
      return screenCandidateBackgroundColor
    }
  }

  // const backgroundColorOfContent = isMobile ? (isNotLoginPage ? screenContentBackgroundColor : '') : ''

  const backimage = useMemo(() => (isAdminPage ? adminBackgroundImage : userBackgroundImage), [isAdminPage])

  return (
    <>
      <ThemeProvider theme={theme}>
        <SessionTimeout />
        <CssBaseline />
        <StyledLayout
          style={{
            backgroundImage: isNotLoginPage ? '' : `url(${backimage})`,
            backgroundSize: 'cover',
            position: 'fixed',
            overflow: 'auto',
            display: 'flex',
          }}
        >
          {isNotLoginPage ? <Header /> : null}
          <section
            style={{
              flex: 1,
              overflowY: 'auto',
              justifyContent: 'center',
              backgroundColor: isNotLoginPage ? getBackgroundColorOfContent() : '',
            }}
          >
            <section
              style={{
                minHeight: isMobile ? undefined : isAdminPage ? 'calc(100vh - 165px)' : 'calc(100vh - 155px)', // Minus 165px (Header and Footer Height)
                width: `${screenWidth}`,
                margin: '10px auto 5px',
              }}
            >
              <Routes />
            </section>
            {isNotLoginPage ? isMobile ? '' : <Footer /> : null}
          </section>
        </StyledLayout>
      </ThemeProvider>
      <SnackBarNotification />
    </>
  )
}
export default App
