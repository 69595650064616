import React from 'react'
import Typography from '@material-ui/core/Typography'
import '../../../node_modules/video-react/dist/video-react.css' // import css
import { connect, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { interviewActions } from '../../actions'
import { StyledCheckCirle, BoxWrapper, StyledContainer, StyledButton, StyledBoxWrapper } from './style'

import { useSelector } from 'react-redux'
import { selectorStartImpressionCompanyName } from 'store/selectors'
import { actionClearStartImpressionData } from 'store/actions'

const ReviewDone = function ({ history }) {
  const dispatch = useDispatch()

  const companyName = useSelector((state) => selectorStartImpressionCompanyName(state))
  const Home = () => {
    dispatch(actionClearStartImpressionData())
    history.replace('/home')
  }
  const StyledBtn = ({ children, ...rest }) => <StyledButton {...rest}>{children}</StyledButton>
  return (
    <StyledBoxWrapper xs={12}>
      <Grid xs={12} container spacing={1} style={{ justifyContent: 'center' }}>
        <Grid item xs={12}>
          <BoxWrapper p={1}>
            <Box>
              <StyledContainer>
                <StyledCheckCirle />
              </StyledContainer>
              <StyledContainer>
                <Typography variant='h4' component='h1'>
                  Yeah!
                </Typography>
                <Typography variant='body1' component='p'>
                  You have successfully submitted your impression to {companyName}.
                </Typography>
              </StyledContainer>
              <StyledContainer>
                <StyledBtn color='primary' variant='contained' onClick={Home}>
                  Done
                </StyledBtn>
              </StyledContainer>
            </Box>
          </BoxWrapper>
        </Grid>
      </Grid>
    </StyledBoxWrapper>
  )
}

const mapStateToProps = ({ questions, interviews, auth }) => ({
  questions: questions.activeInterview,
  userQuestions: questions,
  user: questions.user,
  interviews,
  auth,
})
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(interviewActions, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(ReviewDone)
