import React, { useCallback, useEffect, useState } from 'react'

import IdleTimer from 'react-idle-timer'
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch, useSelector } from 'react-redux'
import { IAppState } from 'models/IAppState'
import { selectorUserLoggedIn, selectorUserRole } from 'store/selectors'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { actionAdminLogout } from 'store/actions'
import { logout } from 'actions/authActions'

export default function SessionTimeout() {
  const dispatch = useDispatch()

  const role = useSelector((state: IAppState) => selectorUserRole(state))

  const [open, setOpen] = React.useState(false)

  const [timeLeft, setTimeLeft] = useState(0)

  const intervalTime = 1000 // millisec

  const timeOutAfterIdle = 1000 * 60 * 15

  const [timeoutRef, setTimeoutRef] = useState<any>()
  const [intervalRef, setIntervalRef] = useState<any>()

  const showSessionModalAfterIdleTime = 60 * 2 // 2 min in seconds

  const sessionTimeoutAfterWarning = showSessionModalAfterIdleTime * 1000 // millisec

  const sessionOut = timeLeft * 1000 === showSessionModalAfterIdleTime * 1000

  const updateTimer = () => {
    setTimeLeft((t) => ++t)
  }

  const onLogout = () => {
    handleClose()
    if (role === 'admin') {
      dispatch(actionAdminLogout())
    } else {
      dispatch(logout())
    }
  }

  const startTimer = () => {
    setTimeoutRef(
      setTimeout(() => {
        onLogout()
      }, sessionTimeoutAfterWarning),
    )
    setIntervalRef(
      setInterval(() => {
        updateTimer()
      }, intervalTime),
    )
  }

  const stopTimer = useCallback(() => {
    setTimeLeft(0)
    clearTimeout(timeoutRef)
    clearInterval(intervalRef)
    setIntervalRef(null)
    setTimeoutRef(null)
  }, [intervalRef, timeoutRef])

  useEffect(() => {
    if (!sessionOut) return
    return () => {
      stopTimer()
    }
  }, [sessionOut, stopTimer])

  const handleClose = () => {
    setOpen(false)
    stopTimer()
  }

  const isLoggedIn = useSelector((state: IAppState) => selectorUserLoggedIn(state))

  const handleOnIdle = (event: any) => {
    if (!isLoggedIn) return
    setOpen(true)
    startTimer()
  }

  const handleOnAction = (e: any) => {}

  useIdleTimer({
    timeout: timeOutAfterIdle,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 500,
  })

  return (
    <>
      <IdleTimer />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Your session will expire in 2 minutes.</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>Press Ok if you want to continue</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onLogout}>Logout</Button>
          <Button onClick={handleClose} color='primary' autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
