import React from 'react'
import json2mq from 'json2mq'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { Stepper, Step, StepLabel, Box, Button, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 4px 8px #D4D4D480',
    borderTop: '1px solid #D4D4D480',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconExits: {
    marginRight: 10,
    fontSize: 20,
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}))

const muiTheme = createMuiTheme({
  overrides: {
    MuiStepIcon: {
      root: {
        fontSize: 30,
        color: '#B9BFC6',
        borderRadius: 50,

        '&$active': {
          fill: '#5DCCBF',
        },
        '&$completed': {
          color: '#5DCCBF',
          fontSize: 31,
          borderWidth: 0,
        },
      },
    },
    MuiStepLabel: {
      label: {
        fontSize: 17,
        color: '#B9BFC6',

        '&$active': {
          color: 'black',
        },
        '&$completed': {
          color: '#5DCCBF',
        },
      },
    },
  },
})

function getSteps() {
  return ['Welcome', 'Overview', 'Record', 'Submit']
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return 'Welcome'
    case 1:
      return 'What is an ad group anyways?'
    case 2:
      return 'This is the bit I really care about!'
    default:
      return 'Unknown step'
  }
}

export default function HorizontalLinearStepper({ activeStep, setActiveStep }) {
  const isMobile = useMediaQuery(
    json2mq({
      maxWidth: 768,
    }),
  )

  const history = useHistory()
  const classes = useStyles()
  // const [activeStep, setActiveStep] = React.useState(0)
  const steps = getSteps()

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1)
  // }

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error('You can nott skip a step that is not optional.')
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1)
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }

  // const handleReset = () => {
  //   setActiveStep(0)
  // }

  const goToHome = () => {
    history.push('/home')
  }

  return (
    <div className={classes.root}>
      <div>
        {activeStep !== 3 && (
          <i
            className='fas fa-chevron-left'
            onClick={() =>
              setActiveStep(prevActiveStep => {
                if (prevActiveStep === 0) {
                  history.push('/home')
                  return
                }
                return prevActiveStep - 1
              })
            }
            style={{ marginLeft: 10, cursor: 'pointer' }}
          ></i>
        )}
      </div>

      <MuiThemeProvider theme={muiTheme}>
        <Stepper classes={{ root: classes.stepper }} activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {
              connector: <i className='fas fa-circle' style={{ fontSize: 7, paddingTop: 0, margin: '0px 10px' }} />,
            }
            const labelProps = {}
            return (
              <Step
                // onClick={() => setActiveStep(index)}
                key={label}
                {...stepProps}
              >
                <StepLabel {...labelProps}>{isMobile ? '' : label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </MuiThemeProvider>
      <div>
        <i className={`fas fa-times ${classes.iconExits}`} onClick={goToHome}></i>
      </div>
    </div>
  )
}
