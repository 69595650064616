import styled from 'styled-components'

export const ContentContainer = styled.section`
  margin: 20px auto;
  padding: 1.5vw;
  border-radius: 10px;
  width: 100%;
  background-color: #ffffff;
  min-height: calc(100vh - 200px);

  @media (max-width: 1039px) {
    min-height: calc(100vh - 248px);
  }
`
