import firebase from 'firebase/app'
import { Dispatch } from 'redux'
const auth = firebase.auth()

const FORGOT = 'FORGOT PASSWORD'

export const ACTION_FORGOT_PASSWORD_INIT = `${FORGOT} INIT`
export const ACTION_FORGOT_PASSWORD_SUCCESS = `${FORGOT} SUCCESS`
export const ACTION_FORGOT_PASSWORD_FAIL = `${FORGOT} FAIL`

export const actionForgotPasswordInit = (email: string) => ({
  type: ACTION_FORGOT_PASSWORD_INIT,
  payload: email,
})

export const actionForgotPasswordSuccess = (email: string) => ({
  type: ACTION_FORGOT_PASSWORD_SUCCESS,
  payload: email,
})

export const actionForgotPasswordFail = (error: any) => ({
  type: ACTION_FORGOT_PASSWORD_FAIL,
  payload: error,
})

export const actionForgotPassword = (email: string, success: Function = () => {}, error: Function = () => {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(actionForgotPasswordInit(email))
    try {
      await auth.sendPasswordResetEmail(email).then(function () {
        dispatch(actionForgotPasswordSuccess(email))
        success()
      })
    } catch (ex) {
      dispatch(actionForgotPasswordFail(ex))
      error(ex)
    }
  }
}
