import styled from 'styled-components'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import PauseCircleFilledRoundedIcon from '@material-ui/icons/PauseCircleFilledRounded'

export const StyledDialogContent = styled(DialogContent)`
  background: #000;
  color: white;
`
export const StyledRecBtn = styled(Button)`
  width: 300px;
  border: 1px solid rgba(63, 81, 181, 0.5);
  &.MuiButtonBase-root {
    bottom: 100px;
    position: absolute;
    font-weight: bold;
    border-radius: 4px;
    width: 220px;
    height: 45px;
    font-family: Lato;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 20px;
    background-color: #2c4aff;
    text-transform: capitalize;
    color: white;
  }
  &.MuiButtonBase-root:hover {
    background-color: #2c4aff;
  }
`
export const StyledNextBtn = styled(Button)`
  width: 192px;
  border: 1px solid rgba(63, 81, 181, 0.5);

  &.MuiButtonBase-root {
    position: absolute;
    font-weight: bold;
    border-radius: 4px;
    width: 192px;
    height: 45px;
    font-family: Lato;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 20px;
    background-color: #2c4aff;
    text-transform: capitalize;
    color: white;
  }
  &.MuiButtonBase-root:hover {
    background-color: #2c4aff;
  }
`
export const StyledReRecButton = styled(Button)`
  width: 200px;
  &.MuiButtonBase-root {
    bottom: 40px;
    position: absolute;
    border: 4px solid #2c4aff;
    font-weight: bold;
    border-radius: 4px;
    width: 220px;
    height: 45px;
    font-family: Lato;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 20px;
    background-color: white;
    text-transform: capitalize;
    color: #2c4aff;
  }
  &.MuiButtonBase-root:hover {
    background-color: white;
  }
`
// export const ReRecButton = styled(Button)`
//     alignItems: center;
//     left: 50%;
//     bottom: 300px;
//     position: absolute;
//     transform: translateX(-50%);
// `
export const PauseButton = styled(PauseCircleFilledRoundedIcon)`
  display: flex;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  margin: 10px;
`

export const CloseBtn = styled.div`
  font-size: 20px;
  background-color: #000000;
  color: white;
  padding: 4px 10px;
  border-radius: 3px;
  cursor: pointer;
  border: 2px solid black;
  :hover {
    border: 2px solid #dedede;
  }
`
export const CloseBtnSection = styled.div`
  top: 10px;
  right: 10px;
  position: absolute;
  padding: 5px;
  color: black;
`

export const ReRecButton = styled(Button)`
  display: block;
  margin-bottom: 10px;
  border: 1px solid rgba(63, 81, 181, 0.5);
  width: 300px;
  min-width: 250px;
  fontsize: 15px;
`
export const MsgSection = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  alignitems: center;
  fontsize: 14px;
  bottom: 200px;
  position: absolute;
  margin: 10px auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 230px;
  width: 100%;

  p {
    background: black;
  }
`
export const ReRecSection = styled.div`
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  bottom: 100px;
  position: absolute;
  width: 100%;
`
export const RecSection = styled.div`
  bottom: 180px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: absolute;

  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
`

export const RecStartButton = styled.button`
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;

  > div {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: red;
  }
`

export const RecStopButton = styled.button`
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  margin: 15px;
  padding: 12px;

  > div {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background-color: red;
  }
`
export const StyledButtonWrapper = styled.div`
  padding-top: 32px;
  display: flex;
  width: 100%;
`

export const StyledProgressWrapper = styled.div`
  position: relative;

  > div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
`
export const questionBoxWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
}

export const styledQuestionList = {
  listStyle: 'initial',
  paddingLeft: '20px',
}

export const StyledVideoPlayer = styled.div`
  video {
  }
`

export const StyledButtonContainer = styled.div`
  button {
    margin-bottom: 10px;
    border: 1px solid rgba(63, 81, 181, 0.5);
    width: 300px;
    min-width: 250px;
    font-size: 15px;
  }
`

export const StyledVideoWrapper = styled.div`
  height: 100%;
`

export const StyleRecordPage = styled.div`
  background: red;
`
