import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

export const StyledBoxWrapper = styled(Box)`
   {
    margin: 0 auto;
    max-width: 1048px;
    // min-width:768px
  }
`

export const StyledRecordContent = styled.p`
  @media (min-width: 768px) {
    font-size: 21px;
  }
  padding-top: 10px;
  line-height: 30px;
  color: black;
  letter-spacing: 0.3px;
  font-size: 20px;
`
export const StyledRecordTitle = styled.h1`
  @media (min-width: 768px) {
    font-size: 26px;
    letter-spacing: 0.5px;
  }

  letter-spacing: 0.3px;
  font-weight: 600;
  color: #2e333b;
  font-family: "poppins";
  font-size: 25px;
`
export const StyledReRecButton = styled(Button)`
  width: 200px;
  &.MuiButtonBase-root {
    font-weight: bold;
    border-radius: 4px;
    width: 220px;
    height: 45px;
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 20px;
    background-color: #2c4aff;
    text-transform: capitalize;
    color: white;
  }
  &.MuiButtonBase-root:hover {
    background-color: #2c4aff;
  }
  &.MuiButtonBase-root:disabled {
    background-color: #dedede;
  }
`
export const RecBtnWrapper = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 768px) {
    justify-content: center;
  }
`
export const RecBoxWrapper = styled(Box)`
   {
    @media (max-width: 768px) {
      box-shadow: 0 4px 8px #e2e2e2;
    }

    background: white;
    border-radius: 5px;
  }
`
export const reviewQuestion = {
  fontSize: '20px',
  fontFamily: 'Poppins',
}
export const questTitle = {
  fontFamily: 'Poppins',
  fontSize: '38px',
  lineHeight: '50px',
  color: '#2e333b',
  fontWeight: '800',
  letterSpacing: '1px',
}

export const questSubTitle = {
  margin: '60px 0 30px 0',
  fontFamily: 'Lato',
  fontSize: '23px',
  color: '#2e33b',
  paddingTop: '10px',
  letterSpacing: '0.5px',
  lineHeight: '35px',
}
export const reRecBtnWrapper = {
  marginTop: '30px',
  display: ' flex',
  justifyContent: 'flex-end',
}
export const reviewRerecordBtn = {
  border: '4px solid',
  background: 'white',
}
export const boxWrapperStyle = {
  margin: '0 auto',
  maxWidth: '1048px',
  minWidth: '768px',
}

export const boxRecordStyle = {
  boxShadow: '0 4px 8px #e2e2e2',
  background: 'white',
  borderRadius: '5px',
}

export const boxStartRecordContainer = {
  maxWidth: '940px',
  margin: '0 auto',
  borderRadius: '8px',
}
export const boxStartRecord = {
  boxShadow: 'none',
  padding: '1em',
  margin: 'auto',
  marginTop: '1em',
  backgroundColor: '#fff7ed',
  borderRadius: '8px',
}
export const questionProgress = {
  paddingTop: '2em',
  paddingBottom: '1em',
  opacity: '1.0',
}

export const StyledButtonWrapper = styled.div`
  padding-top: 32px;
  display: flex;
  width: 100%;
`

export const StyledProgressWrapper = styled.div`
  position: relative;

  > div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
`

export const fontLato = {
  fontFamily: 'Lato',
}
export const fontPoppins = {
  fontFamily: 'Poppins',
}

export const StyledVideoPlayer = styled.div`
  video {
    transform: translate(-50%, -50%) scale(-1, 1);
  }
`

// upuper case
export const StyledButtonContainer = styled.div`
  button {
    margin-bottom: 10px;
    border: 1px solid rgba(63, 81, 181, 0.5);
    width: 300px;
    height: 40px;
    min-width: 250px;
    font-size: 14px;
    text-transform: "capitalize";
  }
`

// to update
export const StyledTipsVideo = styled.div`
  text-align: center;
  button {
    text-align: center;
    font-size: 20px;
  }
`
