import Axios from 'axios'
import { IUpdateUserProfileRequest, UserImpressionList, UserProfile } from 'models/USER'
import { AnswerResponse, UserQuestionResponseRequest, UserStartImpression } from 'models/UserImpression'
import { Dispatch } from 'redux'
import { ApiEndpoints } from 'utils'
import { actionShowNotification } from './notification.action'
import firebase from 'firebase/app'

const USER = 'USER'
export const USER_GET_PROFILE_DETAILS_INIT = `${USER}: GET PROFILE DETAILS INIT`
export const USER_GET_PROFILE_DETAILS_SUCCESS = `${USER}: GET PROFILE DETAILS SUCCESS`
export const USER_GET_PROFILE_DETAILS_FAIL = `${USER}: GET PROFILE DETAILS FAIL`

export const getUserProfileInit = (userId: string) => ({
  type: USER_GET_PROFILE_DETAILS_INIT,
  payload: userId,
})

export const getUserProfileSuccess = (data: UserProfile) => ({
  type: USER_GET_PROFILE_DETAILS_SUCCESS,
  payload: data.data,
})

export const getUserProfileFail = () => ({
  type: USER_GET_PROFILE_DETAILS_FAIL,
})

export const actionGetUserProfile =
  (userId: string, success: (list: UserImpressionList[]) => void) => async (dispatch: Dispatch) => {
    try {
      console.log('Yonathan Cruz', userId)
      if (!userId) throw new Error('User ID missing')
      dispatch(getUserProfileInit(userId))

      const response = await Axios.get(`${ApiEndpoints.getUserDetails()}/${userId}`)

      const data = await response.data

      dispatch(getUserProfileSuccess(data))
      success(data.data.impressionList)
    } catch (ex) {
      dispatch(
        actionShowNotification({
          message: ex.message,
          severity: 'warning',
          duration: 2500,
        }),
      )
      dispatch(getUserProfileFail())
    }
  }

//* START IMPRESSION
export const USER_START_IMPRESSION_INIT = `${USER}: START_IMPRESSION INIT`
export const USER_START_IMPRESSION_SUCCESS = `${USER}: START_IMPRESSION SUCCESS`
export const USER_START_IMPRESSION_FAIL = `${USER}: START_IMPRESSION FAIL`

export const actionStartImpressionInit = (impressionId: string) => ({
  type: USER_START_IMPRESSION_INIT,
  payload: impressionId,
})

export const actionStartImpressionSuccess = (data: UserStartImpression) => ({
  type: USER_START_IMPRESSION_SUCCESS,
  payload: data,
})

export const actionStartImpressionFail = () => ({
  type: USER_START_IMPRESSION_FAIL,
})

export const actionStartImpression = (id: string, success: (data: UserStartImpression) => void) => {
  return async (dispatch: Dispatch) => {
    dispatch(actionStartImpressionInit(id))
    try {
      const response = await Axios.get(`${ApiEndpoints.startImpression()}/${id}`)
      const data: UserStartImpression = await response.data
      dispatch(actionStartImpressionSuccess(data))
      success(data)
    } catch (ex) {
      const message = (ex && ex.response && ex.response.data && ex.response.data.message) || 'Internal server error.'
      dispatch(actionStartImpressionFail())
      dispatch(
        actionShowNotification({
          message,
          severity: 'error',
          duration: 2500,
        }),
      )
    }
  }
}

// * Upload Videos To Firebase

export const USER_UPLOAD_TO_FIREBASE_INIT = `${USER}: UPLOAD_TO_FIREBASE INIT`
export const USER_UPLOAD_TO_FIREBASE_SUCCESS = `${USER}: UPLOAD_TO_FIREBASE SUCCESS`
export const USER_UPLOAD_TO_FIREBASE_FAIL = `${USER}: UPLOAD_TO_FIREBASE FAIL`

export const actionUploadToFirebaseInit = () => ({
  type: USER_UPLOAD_TO_FIREBASE_INIT,
})

export const actionUploadToFirebaseSuccess = () => ({
  type: USER_UPLOAD_TO_FIREBASE_SUCCESS,
})

export const actionUploadToFirebaseFail = () => ({
  type: USER_UPLOAD_TO_FIREBASE_FAIL,
})

export const actionUploadToFirebase = (
  { answers, jobId, userId }: { answers: AnswerResponse[]; jobId: string; userId: string },
  success: (data: AnswerResponse[]) => void,
  error: () => void,
) => {
  return async (dispatch: Dispatch) => {
    dispatch(actionUploadToFirebaseInit())
    try {
      console.log('Update videos to firebase', answers)
      for (let i = 0; i < answers.length; i++) {
        const url = await firebase
          .storage()
          .ref(`Interviews/${jobId}/`)
          .child(`${jobId}-${userId}-${answers[i].questionId}.mp4`)
          .put(answers[i].video)
        const video = await url.ref.getDownloadURL()
        answers[i].video = video
      }
      dispatch(actionUploadToFirebaseSuccess())
      success(answers)
    } catch (ex) {
      dispatch(actionUploadToFirebaseFail())
      dispatch(
        actionShowNotification({
          message: 'Error in processing video',
          severity: 'error',
          duration: 2500,
        }),
      )
      error()
    }
  }
}

// * Submit Impression
export const USER_SUBMIT_IMPRESSION_INIT = `${USER}: SUBMIT_IMPRESSION INIT`
export const USER_SUBMIT_IMPRESSION_SUCCESS = `${USER}: SUBMIT_IMPRESSION SUCCESS`
export const USER_SUBMIT_IMPRESSION_FAIL = `${USER}: SUBMIT_IMPRESSION FAIL`

export const actionSubmitImpressionInit = () => ({
  type: USER_SUBMIT_IMPRESSION_INIT,
})

export const actionSubmitImpressionSuccess = () => ({
  type: USER_SUBMIT_IMPRESSION_SUCCESS,
})

export const actionSubmitImpressionFail = () => ({
  type: USER_SUBMIT_IMPRESSION_FAIL,
})

export const actionSubmitImpression = (
  payload: UserQuestionResponseRequest,
  success: (id: string) => void,
  error: () => void,
) => {
  return async (dispatch: Dispatch) => {
    dispatch(actionSubmitImpressionInit())
    try {
      console.log('Submit impression', payload)
      const response = await Axios.post(ApiEndpoints.saveImpression(), payload)
      console.log('response', response)
      const data: string = await response.data
      dispatch(actionSubmitImpressionSuccess())
      success(data)
    } catch (ex) {
      const message = (ex && ex.response && ex.response.data && ex.response.data.message) || 'Internal server error.'
      dispatch(actionSubmitImpressionFail())
      error()
      dispatch(
        actionShowNotification({
          message,
          severity: 'error',
          duration: 2500,
        }),
      )
    }
  }
}
// * Update user profile

export const USER_ACTION_UPDATE_FNAME_LNAME_INIT = `${USER} UPDATE FNAME AND LNAME INIT`
export const USER_ACTION_UPDATE_FNAME_LNAME_SUCCESS = `${USER} UPDATE FNAME AND LNAME SUCCESS`
export const USER_ACTION_UPDATE_FNAME_LNAME_FAIL = `${USER} UPDATE FNAME AND LNAME FAIL`

export const userActionUpdateProfileInit = (data: IUpdateUserProfileRequest) => ({
  type: USER_ACTION_UPDATE_FNAME_LNAME_INIT,
  payload: data,
})

export const userActionUpdateProfileSuccess = (data: IUpdateUserProfileRequest) => ({
  type: USER_ACTION_UPDATE_FNAME_LNAME_SUCCESS,
  payload: data,
})

export const userActionUpdateProfileFail = () => ({
  type: USER_ACTION_UPDATE_FNAME_LNAME_FAIL,
})

export const userActionUpdateProfile =
  (payload: IUpdateUserProfileRequest, success: Function, error: Function) => async (dispatch: Dispatch) => {
    try {
      dispatch(userActionUpdateProfileInit(payload))
      const response = await fetch(ApiEndpoints.updateUserProfile(), {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      const data = await response.json()
      if (!data) {
        return dispatch(userActionUpdateProfileFail())
      }
      // Success
      const { id } = data
      dispatch(userActionUpdateProfileSuccess(payload))
      success(id)
    } catch (ex) {
      // Request Fail
      dispatch(userActionUpdateProfileFail())
      error()
    }
  }

// @dev Update Profile Picture Events
export const USER_ACTION_ADMIN_PROFILE_PICTURE_UPDATE_INIT = `${USER} USER ACTION PROFILE PICTURE UPDATE INIT`
export const USER_ACTION_ADMIN_PROFILE_PICTURE_UPDATE_SUCCESS = `${USER} USER ACTION PROFILE PICTURE UPDATE SUCCESS`
export const USER_ACTION_ADMIN_PROFILE_PICTURE_UPDATE_FAIL = `${USER} USER ACTION PROFILE PICTURE UPDATE FAIL`

export const userActionUpdateProfilePictureInit = () => ({
  type: USER_ACTION_ADMIN_PROFILE_PICTURE_UPDATE_INIT,
})

export const userActionUpdateProfilePictureSuccess = (ok: any) => ({
  type: USER_ACTION_ADMIN_PROFILE_PICTURE_UPDATE_SUCCESS,
  payload: ok,
})

export const userActionUpdateProfilePictureFail = (error: any) => ({
  type: USER_ACTION_ADMIN_PROFILE_PICTURE_UPDATE_FAIL,
  payload: error,
})

// @dev Update Profile Picture
export const userActionUpdateProfilePicture =
  (
    { photo, currentUser }: { photo: any, currentUser: any },
    setPhotoURL: Function,
    setLoading: Function,
    success: Function,
    error: Function,
  ) => {
    return async (dispatch: Dispatch) => {
      console.log(photo[0])
      dispatch(userActionUpdateProfilePictureInit())
      const user = firebase.auth().currentUser
      console.log('Usuario: ', user)
      try {
        setLoading(true)
        const promise = await firebase
          .storage()
          .ref('ProfilePictures')
          .child(`${currentUser.uid}.png`)
          .put(photo[0])
        const _photoUrl = await promise.ref.getDownloadURL()
        setPhotoURL(_photoUrl)
        if (user !== null) {

          // @dev Update Profile photoURL from firebase
          const updateProfilePicture = user.updateProfile({
            photoURL: _photoUrl
          }).then((data) => {
            console.log('Update profile success: ', data)
          }).catch((error) => {
            console.log('Error', error)
          })
          console.log('Update profile picture: ', updateProfilePicture)
          if (!updateProfilePicture) return
          updateProfilePicture
            .then((photoUrl) => {
              // Update successful.
              dispatch(userActionUpdateProfilePictureSuccess(photoUrl))
              success(photoUrl)
            })
            .catch(error => {
              // An error happened.
              dispatch(userActionUpdateProfilePictureFail(error))
              error(error)
            })

        }
        setLoading(false)

      } catch (ex) {
        dispatch(userActionUpdateProfilePictureFail(ex))
        dispatch(
          actionShowNotification({
            message: 'Error in processing photo',
            severity: 'error',
            duration: 2500,
          }),
        )
        error()
      }
    }
  }

//* Update user account password
export const USER_ACTION_ADMIN_PASSWORD_UPDATE_INIT = `${USER} USER ACTION PASSWORD UPDATE INIT`
export const USER_ACTION_ADMIN_PASSWORD_UPDATE_SUCCESS = `${USER} USER ACTION PASSWORD UPDATE SUCCESS`
export const USER_ACTION_ADMIN_PASSWORD_UPDATE_FAIL = `${USER} USER ACTION PASSWORD UPDATE FAIL`

export const userActionUpdatePasswordInit = () => ({
  type: USER_ACTION_ADMIN_PASSWORD_UPDATE_INIT,
})

export const userActionUpdatePasswordSuccess = (ok: any) => ({
  type: USER_ACTION_ADMIN_PASSWORD_UPDATE_SUCCESS,
  payload: ok,
})

export const userActionUpdatePasswordFail = (error: any) => ({
  type: USER_ACTION_ADMIN_PASSWORD_UPDATE_FAIL,
  payload: error,
})

export const userActionnUpdatePassword =
  (
    { email, currentPassword, newPassword }: { email: string; currentPassword: string; newPassword: string },
    success: Function,
    error: Function,
  ) =>
    (dispatch: Dispatch) => {
      dispatch(userActionUpdatePasswordInit())
      const user = firebase.auth().currentUser
      try {
        // https://firebase.google.com/docs/reference/js/firebase.User#reauthenticatewithcredential
        const credentials = firebase.auth.EmailAuthProvider.credential(email, currentPassword)

        const promise = user?.reauthenticateWithCredential(credentials)
        if (!promise) return
        promise
          .then(ok => {
            const updatePassword = user?.updatePassword(newPassword)
            if (!updatePassword) return
            updatePassword
              .then(() => {
                // Update successful.
                dispatch(userActionUpdatePasswordSuccess(ok))
                success(ok)
              })
              .catch(error => {
                // An error happened.
                dispatch(userActionUpdatePasswordFail(error))
                error(error)
              })
          })
          .catch(err => {
            dispatch(userActionUpdatePasswordFail(err))
            error(err)
          })
      } catch (ex) {
        dispatch(userActionUpdatePasswordFail(ex))
        error(ex)
      }
    }

export const USER_CLEAR_START_IMMPRESSION_DATA = `${USER} CLEAR START IMPRESSION DATA`

export const actionClearStartImpressionData = () => ({
  type: USER_CLEAR_START_IMMPRESSION_DATA,
})
