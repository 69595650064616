import { IUserState } from 'models/USER'
import { UserStartImpression } from 'models/UserImpression'
import {
  USER_ACTION_ADMIN_PASSWORD_UPDATE_FAIL,
  USER_ACTION_ADMIN_PASSWORD_UPDATE_INIT,
  USER_ACTION_ADMIN_PASSWORD_UPDATE_SUCCESS,
  USER_ACTION_UPDATE_FNAME_LNAME_FAIL,
  USER_ACTION_UPDATE_FNAME_LNAME_INIT,
  USER_ACTION_UPDATE_FNAME_LNAME_SUCCESS,
  USER_CLEAR_START_IMMPRESSION_DATA,
  USER_GET_PROFILE_DETAILS_FAIL,
  USER_GET_PROFILE_DETAILS_INIT,
  USER_GET_PROFILE_DETAILS_SUCCESS,
  USER_START_IMPRESSION_FAIL,
  USER_START_IMPRESSION_INIT,
  USER_START_IMPRESSION_SUCCESS,
  USER_SUBMIT_IMPRESSION_FAIL,
  USER_SUBMIT_IMPRESSION_INIT,
  USER_SUBMIT_IMPRESSION_SUCCESS,
  USER_UPLOAD_TO_FIREBASE_FAIL,
  USER_UPLOAD_TO_FIREBASE_INIT,
  USER_UPLOAD_TO_FIREBASE_SUCCESS,
} from 'store/actions/user.action'

const initialState: IUserState = {
  profile: {
    data: {
      email: '',
      fname: '',
      impressionList: [],
      lname: '',
    },
    loading: false,
  },
  startImpression: {
    loading: false,
    payload: {
      data: null,
      id: null,
    },
  },
  submitImpression: {
    loading: false,
  },
  uploadToFirebase: {
    loading: false,
  },
  updatingProfile: {
    loading: false,
    payload: null,
  },
  updatingPassword: false,
}

/**
 *
 * @param state `IUserState`
 * @param action `{ type: admin-action, payload: any }`
 */
const userReducer = (state: IUserState = initialState, action: any): IUserState => {
  switch (action.type) {
    case USER_GET_PROFILE_DETAILS_INIT: {
      return {
        ...state,
        profile: {
          ...state.profile,
          loading: true,
        },
      }
    }
    case USER_GET_PROFILE_DETAILS_SUCCESS: {
      const data = action.payload
      const profile = {
        loading: false,
        data,
      }
      return { ...state, profile }
    }
    case USER_GET_PROFILE_DETAILS_FAIL: {
      return {
        ...state,
        profile: { ...state.profile, loading: false },
      }
    }
    // Start Impression
    case USER_START_IMPRESSION_INIT: {
      return {
        ...state,
        startImpression: {
          loading: true,
          payload: {
            data: null,
            id: null,
          },
        },
      }
    }

    case USER_START_IMPRESSION_SUCCESS: {
      const payload: UserStartImpression = action.payload

      return {
        ...state,
        startImpression: {
          loading: false,
          payload,
        },
      }
    }

    case USER_START_IMPRESSION_FAIL: {
      return {
        ...state,
        startImpression: {
          loading: false,
          payload: {
            data: null,
            id: null,
          },
        },
      }
    }

    // * Upload to firebase

    case USER_UPLOAD_TO_FIREBASE_INIT: {
      return { ...state, uploadToFirebase: { loading: true } }
    }
    case USER_UPLOAD_TO_FIREBASE_SUCCESS:
    case USER_UPLOAD_TO_FIREBASE_FAIL: {
      return { ...state, uploadToFirebase: { loading: false } }
    }

    //* Submit Impression
    case USER_SUBMIT_IMPRESSION_INIT: {
      return { ...state, submitImpression: { loading: true } }
    }

    case USER_SUBMIT_IMPRESSION_SUCCESS:
    case USER_SUBMIT_IMPRESSION_FAIL: {
      return {
        ...state,
        submitImpression: { loading: false },
      }
    }

    case USER_CLEAR_START_IMMPRESSION_DATA: {
      return {
        ...state,
        startImpression: { loading: false, payload: { data: null, id: null } },
      }
    }

    //* Updating Profile First & Last Name
    case USER_ACTION_UPDATE_FNAME_LNAME_INIT: {
      const { payload } = action
      return {
        ...state,
        updatingProfile: {
          payload,
          loading: true,
        },
      }
    }

    case USER_ACTION_UPDATE_FNAME_LNAME_SUCCESS: {
      return {
        ...state,
        updatingProfile: {
          payload: null,
          loading: false,
        },
      }
    }
    case USER_ACTION_UPDATE_FNAME_LNAME_FAIL: {
      return {
        ...state,
        updatingProfile: {
          payload: null,
          loading: false,
        },
      }
    }
    //* Updating password
    case USER_ACTION_ADMIN_PASSWORD_UPDATE_INIT: {
      return { ...state, updatingPassword: true }
    }

    case USER_ACTION_ADMIN_PASSWORD_UPDATE_SUCCESS:
    case USER_ACTION_ADMIN_PASSWORD_UPDATE_FAIL: {
      return { ...state, updatingPassword: false }
    }

    default: {
      return { ...state }
    }
  }
}

export default userReducer
