import React, { useMemo } from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import json2mq from 'json2mq'
import {
  AdminCompanyLogosContainer,
  StyledService,
  LoginBoxContainer,
  StyledWrapper,
  elementPushBottom,
} from 'shared/AuthStyle/style.js'
import AuthRoute from './AuthRoute'
import TermsService from '../../resource/Primo Impressions -Terms and Condition.pdf'
import PrivacyPolicy from '../../resource/Primo Impressions - Privacy Policy.pdf'
import p3 from '../../resource/phase-3-logo.png'
import fusedigital from '../../resource/fuse-digital.png'
import ptg from '../../resource/ptg.png'

// New imports

import { makeStyles, createStyles } from '@material-ui/core/styles'
import primoLogo from '../../resource/primo-logo.png'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles(() =>
  createStyles({
    main: {
      fontSize: '13px',
    },
    title: {
      '& h6': {
        fontSize: '1em',
        textAlign: 'center',
        margin: 1,
        color: '#5DCCBF',
      },
      '& h4': {
        fontSize: '2em',
        textAlign: 'center',
        font: 'normal normal medium 60px/72px Fira Sans',
        letterSpacing: '0px',
        color: '#000000',
        textTransform: 'capitalize',
        opacity: 1,
        margin: 0,
      },
      '& p': {
        margin: 2,
        fontSize: '1em',
        textAlign: 'center',
        font: 'normal normal normal 17px/21px Lato',
        letterSpacing: 0,
        textTransform: 'capitalize',
        opacity: 1,
      },
    },
    fields: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    iconInputField: {
      display: 'flex',
      alignItems: 'center',
      minWidth: 230,
      margin: 10,
      backgroundColor: '#F6F6F6',
      width: '100%',
      height: 50,
      background: '#F6F6F6 0% 0% no-repeat padding-box',
      borderRadius: 8,
      opacity: 1,
      boxShadow: 'none',
    },
    textLink: {
      color: '#5DCCBF !important',
      textDecoration: 'none !important',
      fontSize: '1em',
    },
    input: {
      margin: 0,
      fontSize: '1em',
      flex: 1,
    },
    loginButtonSection: {
      display: 'flex',
      '& .loginButton': {
        display: 'flex',
        flex: 1,
        backgroundColor: '#5DCCBF',
        color: '#ffffff',
        borderRadius: '8px',
      },
    },
    socialLoginButtons: {
      display: 'flex',
      justifyContent: 'center',
      '& .button': {
        display: 'flex',
        flex: 1,
        borderRadius: 8,
        border: '1px solid #999',
        fontSize: '1em',
        height: 50,
        margin: 5,
      },
    },
    loginBoxContainer: {
      display: 'flex',
      height: 'calc(100vh - 80px)',
      paddingBottom: 80,
    },
    loginBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    maxWidth: {
      width: 500,
    },
    sectionHeight: {
      height: '80%',
      display: 'flex',
      flexDirection: 'column',
      margin: '20px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    descriptionBox: {
      color: '#ffffff',
    },
    primoLogo: {
      margin: '30px 40px 0',
      '& img': {
        height: 50,
      },
    },
    splashScreen: {
      width: '50vw',
      padding: 10,
      margin: 'auto',
      borderRadius: '10px',
      maxWidth: '350px',
      color: '#ffffff',
    },
  }),
)

const Auth = () => {
  const classes = useStyles()
  const isMobile = useMediaQuery(
    json2mq({
      maxWidth: 768,
    }),
  )

  const location = useLocation()

  const showSignUpTerms = useMemo(() => location.pathname.includes('signup'), [location])

  const LovedByHiringAndLogo = () => (
    <>
      <Typography variant='body1' component='p' style={{ color: 'white', fontSize: '13px', marginTop: '20px' }}>
        Loved by Hiring Managers & Candidates from
      </Typography>
      <AdminCompanyLogosContainer>
        <img src={ptg} alt='PTG' />
        <img src={p3} alt='P3' />
        <img src={fusedigital} alt='FUSE DIGITAL' />
      </AdminCompanyLogosContainer>
    </>
  )

  const welcomeNote = () => (
    <Grid item sm={12} md={6} className={`${classes.loginBox} ${classes.descriptionBox}`}>
      <section className={`${classes.sectionHeight} ${classes.maxWidth}`}>
        <section style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Typography variant='h1' component='h1'>
            Stand out from the crowd!
          </Typography>
          <Typography component='p' style={elementPushBottom}>
            Primo Impression is designed to help candidates like you make a great video-based first impression to your
            future employer.
          </Typography>
          <LovedByHiringAndLogo />
        </section>
      </section>
    </Grid>
  )

  const loginScreen = () => (
    <>
      <Grid container className={classes.loginBoxContainer}>
        <Grid item xs={12} md={6} className={classes.loginBox}>
          <section className={`${classes.maxWidth} ${classes.sectionHeight}`}>
            <LoginBoxContainer>
              <AuthRoute />
              {showSignUpTerms ? (
                <StyledWrapper>
                  <StyledService>
                    By signing up, I agree to the{' '}
                    <a href={TermsService} target='_blank' rel='noopener noreferrer'>
                      terms and condition{' '}
                    </a>
                    <br />
                    and the{' '}
                    <a href={PrivacyPolicy} target='_blank' rel='noopener noreferrer'>
                      privacy policy
                    </a>
                  </StyledService>
                </StyledWrapper>
              ) : null}
            </LoginBoxContainer>
            {isMobile ? <LovedByHiringAndLogo /> : null}
          </section>
        </Grid>
        {isMobile ? null : welcomeNote()}
      </Grid>
    </>
  )

  return (
    <>
      <section className={classes.primoLogo}>
        <img alt='Primo Impression' src={primoLogo} />
      </section>
      {loginScreen()}
    </>
  )
}
export default Auth
