import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import { Dispatch } from 'redux'
import {
  actionAppLoginInit,
  actionAppLoginSuccess,
  actionAppLoginFail,
  actionAppLogout,
  actionShowNotification,
} from 'store/actions'
import { ApiEndpoints } from 'utils'
import { userConstants } from '../constants/types'

export const auth = firebase.auth()
export const firestore = firebase.firestore()
const google_provider = new firebase.auth.GoogleAuthProvider()

export async function isUser(user: firebase.User) {
  if (!user) throw new Error('No such account found')
  const idTokenResult = await user.getIdTokenResult()
  // Confirm the user is not an Admin.
  if (!idTokenResult.claims.admin) {
    // Show admin UI.
    return true
  } else {
    // Show regular user UI.
    throw new Error('No such user found')
  }
}

export const createUserWithEmailAndPasswordHandler =
  (firstName: string, lastName: string, email: string, password: string) => async (dispatch: Dispatch) => {
    dispatch(actionAppLoginInit('user'))
    try {
      const res = await auth.createUserWithEmailAndPassword(email, password)

      if (!res.user) return

      const { uid } = res.user

      const user = await createUserProfile(uid, firstName, lastName, email)

      const { data } = user

      await res.user.updateProfile({
        displayName: `${data.firstName}`,
      })

      localStorage.setItem('user', JSON.stringify(res.user))
      localStorage.setItem('loggedIn', JSON.stringify(true))

      dispatch({
        type: userConstants.REGISTER_REQUEST,
        response: res.user,
      })

      dispatch(actionAppLoginSuccess({ ...res }))
    } catch (err) {
      dispatch(
        actionShowNotification({
          message: err.message,
          severity: 'info',
          duration: 2500,
        }),
      )
      dispatch({
        type: userConstants.REGISTER_FAILURE,
        response: err,
      })
      dispatch(actionAppLoginFail())
    }
  }

export const signInWithEmailAndPasswordHandler =
  (email: string, password: string, error: Function) => async (dispatch: Dispatch) => {
    try {
      dispatch(actionAppLoginInit('user'))
      const res = await auth.signInWithEmailAndPassword(email, password)
      if (!res.user) return
      await isUser(res.user)
      localStorage.setItem('user', JSON.stringify(res.user))
      localStorage.setItem('loggedIn', JSON.stringify(true))
      dispatch({
        type: userConstants.LOGIN_SUCCESS,
        payload: res.user,
      })
      dispatch(actionAppLoginSuccess({ ...res }))
    } catch (err) {
      error()
      dispatch(
        actionShowNotification({
          message: err.message,
          severity: 'info',
          duration: 2500,
        }),
      )
      dispatch({
        type: userConstants.LOGIN_FAILURE,
        response: err,
      })
      dispatch(actionAppLoginFail())
    }
  }

export const signInWithGoogle = () => async (dispatch: Dispatch) => {
  try {
    dispatch(actionAppLoginInit('user'))

    const res = await auth.signInWithPopup(google_provider)

    const data = JSON.parse(JSON.stringify(res.user))

    const uid = res.user?.uid
    const email = res.user?.email
    const firstName = res.user?.displayName
    const lastName = firstName ? firstName : firstName?.split(' ')[0]

    if (!uid || !firstName || !lastName || !email) {
      throw new Error('Please setup your firstname and last name in your Google account')
    }

    await createUserProfile(uid, firstName, lastName, email)

    if (!res.user) return
    await isUser(res.user)
    await res.user.updateProfile({
      displayName: data.displayName,
    })
    localStorage.setItem('user', JSON.stringify(data))
    localStorage.setItem('loggedIn', JSON.stringify(true))
    setTimeout(() => {
      dispatch(actionAppLoginSuccess({ ...res }))
      dispatch({
        type: userConstants.LOGIN_SUCCESS,
        payload: data,
      })
    }, 2000)
  } catch (err) {
    dispatch(
      actionShowNotification({
        message: err.message,
        severity: 'info',
        duration: 2500,
      }),
    )
    dispatch({
      type: userConstants.REGISTER_FAILURE,
      response: err,
    })
    dispatch(actionAppLoginFail())
  }
}

export const logout = () => (dispatch: Dispatch) => {
  auth.signOut().then(() => {
    localStorage.removeItem('user')
    localStorage.removeItem('loggedIn')
    dispatch({
      type: userConstants.LOGOUT,
      response: null,
    })
    dispatch(actionAppLogout())
    dispatch(
      actionShowNotification({
        message: 'You successfully logged out',
        severity: 'success',
        duration: 2200,
      }),
    )
  })
}
async function createUserProfile(uid: string, firstName: string, lastName: string, email: string) {
  const userCreatedResponse = await fetch(ApiEndpoints.createUserProfile(), {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    body: JSON.stringify({
      uid,
      firstName,
      lastName,
      email,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  const user = await userCreatedResponse.json()
  return user
}
