import React from 'react'
import '../../../node_modules/video-react/dist/video-react.css' // import css
import { useQuery } from 'utils'
import Stepper from './Stepper'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'

const StartImpression = () => {
  const code: string | null = useQuery().get('code')

  const [activeStep, setActiveStep] = React.useState(0)

  const handleNext = () => setActiveStep(prevActiveStep => prevActiveStep + 1)

  return (
    <div style={{ backgroundColor: 'white', height: '100%' }}>
      <Stepper activeStep={activeStep} setActiveStep={setActiveStep} />

      {activeStep === 0 && <Step1 codeId={code} handleNext={handleNext} />}
      {activeStep === 1 && <Step2 handleNext={handleNext} />}
      {activeStep === 2 && <Step3 handleNext={handleNext} />}
      {activeStep === 3 && <Step4 handleNext={handleNext} />}
    </div>
  )
}
export default StartImpression
